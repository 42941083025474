import { CalleeEntity, FullCallEntity } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/slices/callSlice';
import { NucleusCommunicationSignaling, CommunicationSignalingEvent } from '@nucleus-care/nucleuscare-connect-signaling';
import { useRef, useEffect, useState } from 'react';
import {
  FiveParticipant,
  FourParticipant,
  OneParticipant,
  SixParticipant,
  ThreeParticipant,
  TwoParticipant,
  SevenParticipant,
  EightParticipant,
  NineParticipant,
  TenParticipant,
  ElevenParticipant,
} from './containers/CallLayout';

export const CallPlayer = ({
  callee,
  participants,
  onCallFailed,
}: {
  callee: CalleeEntity;
  participants: FullCallEntity[];
  onCall: (call: any) => void;
  onCallFailed?: () => void;
}) => {
  const mountedRef = useRef(false);

  const [DOMLoaded, setDOMLoaded] = useState(false);
  const [GotCall, setGotCall] = useState(false);

  const [VideoLayout, setVideoLayout] = useState(null);

  const communication = useRef(NucleusCommunicationSignaling.getInstance());

  const BuildLayout = () => {
    let container = null;
    if (!GotCall) {
      return null;
    }
    switch (participants.length) {
      case 1:
        container = <OneParticipant callee={callee} participants={participants} />;
        break;
      case 2:
        container = <TwoParticipant callee={callee} participants={participants} />;
        break;
      case 3:
        container = <ThreeParticipant callee={callee} participants={participants} />;
        break;
      case 4:
        container = <FourParticipant callee={callee} participants={participants} />;
        break;
      case 5:
        container = <FiveParticipant callee={callee} participants={participants} />;
        break;
      case 6:
        container = <SixParticipant callee={callee} participants={participants} />;
        break;
      case 7:
        container = <SevenParticipant callee={callee} participants={participants} />;
        break;
      case 8:
        container = <EightParticipant callee={callee} participants={participants} />;
        break;
      case 9:
        container = <NineParticipant callee={callee} participants={participants} />;
        break;
      case 10:
        container = <TenParticipant callee={callee} participants={participants} />;
        break;
      case 11:
        container = <ElevenParticipant callee={callee} participants={participants} />;
        break;
      default:
        container = <ElevenParticipant callee={callee} participants={participants} />;
        break;
    }

    return container;
  };

  useEffect(() => {
    mountedRef.current = true;
    const useCommunication = communication.current;

    useCommunication.addListener(CommunicationSignalingEvent.CallReceived, callReceived);
    useCommunication.addListener(CommunicationSignalingEvent.CallFailed, innerCallFailed);
    // setTimeout(() => setGotCall(true), 1000)
    setDOMLoaded(true);

    return () => {
      mountedRef.current = false;
      useCommunication.removeListener(CommunicationSignalingEvent.CallReceived, callReceived);
      useCommunication.removeListener(CommunicationSignalingEvent.CallFailed, innerCallFailed);
      // dispatch(setLoudestPeer(null))
    };
  }, []);

  useEffect(() => {
    if (DOMLoaded) {
      //Wait for dom to be ready
      setGotCall(true);
    }
  }, [DOMLoaded]);

  const callReceived = () => {
    if (!mountedRef.current) {
      console.log('im not mounted stopping');
      return;
    }
    if (communication.current.isCallEnded()) {
      setGotCall(false);
    }
  };

  const innerCallFailed = () => {
    if (!mountedRef.current) {
      console.log('im not mounted stopping');
      return;
    }

    onCallFailed && onCallFailed();
  };

  useEffect(() => {
    setVideoLayout(BuildLayout());
  }, [GotCall]);

  return (
    <div
      id="senessCallView"
      className="gradient-bg-container "
      style={{
        flex: 1,
        display: 'flex',
        width: '100%',
        height: '92vh',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {GotCall ? (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>{VideoLayout}</div>
      ) : (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Disconnecting...</span>
        </div>
      )}

      <style>
        {`
          .video-shadow {
            -webkit-box-shadow: 0px 8px 10px 2px rgba(0,0,0,0.2); 
            box-shadow: 0px 8px 10px 2px rgba(0,0,0,0.2);
          }
          #gallery {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            max-width: calc(var(--width) * var(--cols));
          }
          .gradient-bg-container {
            background: linear-gradient(180deg, rgba(216,240,255,1), rgba(253,250,234,0.0001) 25%),
            linear-gradient(0deg, rgba(216,240,255, 1), rgba(253,250,234,0.01) 25%);
          }
          .active-gradient-border {
            position: relative;
            margin:5px;
            background: linear-gradient(135deg, #82CEFE, #FFCA20); 
            padding:6px 7px;
            border-radius:12px;
            width: var(--height);
            height: var(--width);
            justify-content: center;
            align-items: center;
            display: flex;
            box-shadow: 0 10px 8px -4px lightgrey;
          }
          .online-gradient-border {
            position: relative;
            margin:5px; 
            padding:4px 5px;
            border-radius:12px;
            width: var(--height);
            height: var(--width);
            justify-content: center;
            align-items: center;
            display: flex;
            
          }
          .offline-gradient-border {
            position: relative;
            margin:5px;
            background: linear-gradient(115deg, #979797, #FFFFFF);  
            padding:4px 5px;
            border-radius:12px;
            width: var(--height);
            height: var(--width);
            justify-content: center;
            align-items: center;
            display: flex;
            box-shadow: 0 10px 8px -4px lightgrey;
          }
          .no-gradient-border {
            position: relative;
            margin:10px;
            padding:6px 6px;
            border-radius:12px;
            width: var(--height);
            height: var((--width));
            justify-content: center;
            align-items: center;
            display: flex;
            
          }
          .call_view_overlay {
            position: absolute;
            width: var(--height);
            /*height: var(--width);*/
            height: 95%;
            display: flex;
            border-radius:12px;
            padding-left: 9px;
            padding-right: 28px;

            /*

            background: linear-gradient(to top, rgba(0, 0, 0, 0.25), transparent 25%),
                        linear-gradient(to bottom, rgba(0, 0, 0, 0.25), transparent 25%);
            */
          }
          .gradient-bg {

          }
          .activity-bg {
            background: linear-gradient(135deg, #82CEFE, #FFCA20);
          }
          .unactive-bg {
            background: transparent;  
          }
          video {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
          .video-container-cc {
            
          }
          .videoCallViewPeerName {
            flex-direction: column-reverse;
            padding: 12px;
            /* align-content: flex-end; */
            color: white;
            align-items: flex-end;
            display: flex;
            padding-right: 25px;
          }
          .offlinePeerName{
            flex-direction: column;
            color: white;
            align-items: center;
            display: flex;
            justify-content: center;
            height: 100%;
          }
        `}
      </style>
    </div>
  );
};

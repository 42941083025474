import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import backendClient from 'utils/BackendClient';
import NucleusNetwork from 'utils/NucleusNetwork';
import Config from '../Config';

class AlertsApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<---------------------------------- Get All Alerts Types --------------------------->
  GetAlertsTypes(data, callback) {
    backendClient.get('/alerts/alert-types', callback);
  }

  //<---------------------------- Get Alerts Count by Account ------------------------->
  GetAlertsCountByAccount(data, callback) {
    if (!data.AccountID) {
      console.warn('GetAlertsCountByAccount AccountID is null');
      return;
    }

    let rsp;
    httpNodeApi
      .get('account/' + data.AccountID + '/alerts_counter', {})
      .then(response => {
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on account/:accountId/alerts_counter' };
        console.error('Error account/:accountId/alerts_counter error', e);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        //console.log("account/:accountId/alerts_counter finally");
        //console.log("rsp",rsp);

        callback(rsp);
      });
  }

  GetAlertsForAccountByType(data, callback) {
    console.log('AlertsApi.js>GetAlertsForAccountByType params', data);

    if (!data.AccountID) {
      console.warn('GetAlertsForAccountByType AccountID is null');
      return;
    }
    let rsp;
    httpNodeApi
      .post('account/' + data.AccountID + '/get_alerts_info', {
        FromDateTime: data.FromDateTime,
        ToDateTime: data.ToDateTime,
        TimeZoneOffset: Number(data.TimeZoneOffset),
      })
      .then(response => {
        console.log('account/:accountId/get_alerts_info response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on account/:accountId/get_alerts_info' };
        console.error('Error account/:accountId/get_alerts_info error', e);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        //console.log("account/:accountId/get_alerts_info finally");
        //console.log("rsp",rsp);
        callback(rsp);
      });
  }

  //<----------------------------------Report Alert Handled--------------------------->
  MarkAlertAsHandled(data, callback) {
    backendClient.put('/alerts/' + data.AlertID + '/handled', data, callback);
  }

  MarkAlertsByTypeAsHandled(data, callback) {
    backendClient.put('/alerts/alert-types/mark-all-handled', data, callback);
  }

  GetHandledAlertsForAccountByDateTime(data, callback) {
    backendClient.get(`/alerts/accounts/${data.AccountID}/handled`, callback);
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: 'POST',
    //   url: Config.domain + 'CareAlert.asmx/GetHandledAlertsForAccountByDateTimeRange',
    //   data: data,
    //   callback: callback,
    // });
  }
}

export default AlertsApi;

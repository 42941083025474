import { getClassSessionConfig, getAccountIntegrationConfig, getLoggedInUserConfig } from '@nucleus-care/nucleuscare-backend-client';
import { NucleusSignaling, SignalingEvent, SignalingId } from '@nucleus-care/nucleuscare-connect-signaling';
import { AuthenticateBeforeRendering } from 'components/AuthenticateBeforeRendering';
import { useEffect, useMemo, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { useDispatch } from 'react-redux';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { JQuery } from 'types/jquery.types';
import { getToken } from 'utils/get-token';
import { configureUnauthorizedInterceptor } from 'utils/interceptor';
import { UserImpersonation } from 'utils/UserImpersonation';
import Communication from '../components/Communication';
import ProviderSideBar from '../components/ProviderSideBar';
import SideBar from '../components/SideBar/SideBar';
import TopBar from '../components/TopBar/TopBar';

import { useUserMeSelector } from '../selectors/user';
import { AuthStore, GeneralStore, CarePatientStore, CareCommunicationStore, CareQueueStore, MessagesStore, CareAlertsStore, CareBulletinStore, CarePicturesStore } from '../stores';

import DetectWakeup from '../utils/DetectWakeup';

// import token from localstorage to libraries
getToken();
// add 401 interceptor
configureUnauthorizedInterceptor();

function ClassSessionConfigFetcher() {
  const dispatch = useDispatch();
  const accountId = UserImpersonation.getImpersonatingAccount() || AuthStore.getUserAccountID();

  if (!accountId) {
    console.error('No account id found');
    return;
  }
  useEffect(() => {
    dispatch(
      getClassSessionConfig({
        AccountID: accountId,
      }),
    );
    dispatch(
      getAccountIntegrationConfig({
        AccountID: accountId,
      }),
    );
    dispatch(getLoggedInUserConfig(AuthStore.getUserID()));
  }, [accountId]);

  return null;
}

const signaling = NucleusSignaling.getInstance();

const Main = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const idleTimerRef = useRef<IdleTimer>(null);
  const lastActiveTimeRef = useRef(0);
  const idleTimeout = AuthStore.getIsHipaaCompliant() ? 1000 * 60 * AuthStore.getAutoLogoutTimeout() : 1000 * 60 * 10;
  const callInProgressRef = useRef(false);
  const user = useUserMeSelector();
  const userId = AuthStore.getUserID();
  const isProvider = AuthStore.isProvider();
  const accountId = AuthStore.getUserAccountID();

  const isImpersonating = UserImpersonation.isImpersonating();

  const onActive = () => {
    console.log('_onActive user is active', new Date().toLocaleTimeString());
    const currentTime = new Date().getTime();
    const inactiveMins = (currentTime - lastActiveTimeRef.current) / 60000;
    console.log('Has Pased ', inactiveMins, ' inactive Mins');
  };

  const onIdle = () => {
    const mins = idleTimeout / 60000;

    console.log('_onIdle user is idle for ' + mins + ' mins', new Date().toLocaleTimeString());

    const currentTime = new Date().getTime();
    lastActiveTimeRef.current = currentTime;

    if (AuthStore.getIsHipaaCompliant() && !callInProgressRef.current) {
      console.log('Expired Session & call ' + callInProgressRef.current);
      AuthStore.logout(dispatch);
    }
  };

  const onUpdateCallInProgressAction = value => {
    console.log('onUpdateCallInProgressAction', value);
    callInProgressRef.current = value;
  };

  const onAuthStoreChange = ({ ok }) => {
    console.log('onAuthStoreChange', ok);
    if (!ok) {
      navigate('/login', {
        replace: true,
      });
    }
  };

  const validateProviderRedirect = () => {
    const isProvider = !!AuthStore.getProviderID();

    console.log('MAIN validateProviderRedirect ===============>');
    console.log('MAIN validateProviderRedirect ===============> location', location);
    console.log('MAIN validateProviderRedirect ===============> isProvider', isProvider);
    console.log('MAIN validateProviderRedirect ===============> isImpersonating', isImpersonating);
    console.log('MAIN validateProviderRedirect ===============> location.pathname', location.pathname);

    if (location.pathname.indexOf('/provider') === -1 && isProvider && !isImpersonating) {
      console.log('11111 /provider')
      navigate(`/provider/${AuthStore.getProviderID()}/accounts`, {
        replace: true,
      });
    } else if (location.pathname === '/') {
      console.log('00000 /dashboard')
      navigate('/dashboard', {
        replace: true,
      });
    }
  };

  useEffect(() => {
    validateProviderRedirect();
  }, [location]);

  useEffect(() => {
    AuthStore.on('onAuthStoreLogout', onAuthStoreChange);
    CareCommunicationStore.on('onUpdateCallInProgress', onUpdateCallInProgressAction);
    console.warn('Main componentDidMount isProvider', isProvider);

    GeneralStore.getDSTDates();

    if (window.location.toString().includes('queue')) {
      CareQueueStore.setPollingQueueNumber(false);
    }

    const DetectWakeupInstance = new DetectWakeup(this);
    DetectWakeupInstance.init();

    ($('.nucleus-modal-call') as unknown as JQuery).modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });

    const environment = (process.env.REACT_APP_SIGNALING_ENV || 'PROD') as 'DEV' | 'TEST' | 'PROD';

    signaling.setEnvironment(environment);

    signaling.addListener(SignalingEvent.SIGNALING_CONNECTION_STATE_CHANGED, onSignalingStateChanged);

    return () => {
      AuthStore.removeListener('onAuthStoreLogout', onAuthStoreChange);
      CareCommunicationStore.removeListener('onUpdateCallInProgress', onUpdateCallInProgressAction);
      signaling.removeListener(SignalingEvent.SIGNALING_CONNECTION_STATE_CHANGED, onSignalingStateChanged);
    };
  }, []);

  useEffect(() => {
    const currentLocation = window.location.href;

    if (!accountId) {
      // If there is no account id, we don't need to fetch any data
      return;
    }

    if (!isProvider || isImpersonating) {
      CarePatientStore.getAllPatientsData({
        UserID: AuthStore.getUserID(),
      });

      CarePicturesStore.getPicturesByAccount({
        AccountID: accountId,
      });

      CareBulletinStore.getBulletinButtonsData({
        AccountID: accountId,
      });

      if (currentLocation.search('messages') < 0) {
        const fromDate = new Date();
        const toDate = new Date();

        fromDate.setDate(fromDate.getDate() - 7);
        toDate.setDate(toDate.getDate() + 7);

        if (accountId) {
          MessagesStore.getMessagesForAccount({
            AccountID: accountId,
            FromDateTime: fromDate.toISOString(),
            ToDateTime: toDate.toISOString(),
          });
        }
      }

      CareAlertsStore.getAlertsTypes();
      MessagesStore.getMessageCategries();

      if (currentLocation.search('alerts') < 0) {
        const currentDate = new Date();
        const beginDate = new Date();
        beginDate.setDate(currentDate.getDate() - 7);
        const currentTimezone = (currentDate.getTimezoneOffset() / 60) * -1;

        let formatedTimezone = '' + currentTimezone;
        if (currentTimezone > 0) {
          formatedTimezone = '+' + currentTimezone;
        }

        CareAlertsStore.getAlertsByAccount({
          AccountID: AuthStore.getUserAccountID(),
          FromDateTime:
            beginDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }) +
            ' ' +
            beginDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' }),
          ToDateTime:
            currentDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }) +
            ' ' +
            currentDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' }),
          TimeZoneOffset: formatedTimezone,
        });
      }
    }
  }, [accountId, isImpersonating]);

  const onSignalingStateChanged = state => {
    console.log('IS CONNECTED: ', state);
  };

  const connectSignaling = () => {
    if (!signaling.isConnectedToSignalingServer()) {
      signaling.startSignaling(new SignalingId(''), new SignalingId(userId), new SignalingId(userId));
    }
  };

  useEffect(() => {
    if (userId) {
      connectSignaling();
    }
  }, [userId]);

  const SideBarMemo = useMemo(() => {
    if (!user) return null;
    if (location.pathname.includes('provider')) {
      return <ProviderSideBar />;
    } else {
      return <SideBar />;
    }
  }, [location, user]);

  return (
    <div className="row nucleus-main-content">
      <ClassSessionConfigFetcher />
      <IdleTimer ref={idleTimerRef} element={document} onActive={onActive} onIdle={onIdle} debounce={250} timeout={idleTimeout} />

      {SideBarMemo}

      <div className="col s10 m10 l11 xl11  general-content">
        <div
          className="general-containter"
          style={{
            position: 'relative',
          }}
        >
          <TopBar />

          <Outlet />

          <Communication />
        </div>
      </div>
    </div>
  );
};

// We wrap the Main component in the AuthenticateBeforeRendering component
// to ensure that the user is authenticated before rendering the Main component.
const ProtectedMain = () => {
  return (
    <AuthenticateBeforeRendering>
      <Main />
    </AuthenticateBeforeRendering>
  );
};

export default ProtectedMain;

import dayjs from 'dayjs';
import React from 'react';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import CarePatientStore from '../../../stores/CarePatientStore';

class PatientScheduleItemRow extends React.Component<{
  ID: string;
  Day: string;
  From: string;
  To: string;
  Source: string;
}> {
  timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  constructor(props) {
    super(props);
    this.state = {
      scheduleID: this.props.ID,
    };

    this.handleRowClicked = this.handleRowClicked.bind(this);

    this.handleRemoveSchedule = this.handleRemoveSchedule.bind(this);
    this.handleModifySchedule = this.handleModifySchedule.bind(this);
  }

  componentDidMount() {
    //console.log(this.state.patientID);
    this.setState({
      scheduleID: this.props.ID,
    });
    //$('.tooltipped').tooltip({delay: 50});
  }

  componentWillUnmount() {
    //$('.tooltipped').tooltip('remove');
  }

  handleRowClicked() {
    //window.location.assign("#/patientProfile/" + this.props.PatientID + "/null" );
  }

  handleModifySchedule() {
    const fromTime = dayjs.utc(this.props.From, 'HH:mm:ss').format('hh:mm A');
    const toTime = dayjs.utc(this.props.To, 'HH:mm:ss').format('hh:mm A');

    CarePatientStore.handleOpenEditScheduleModal({
      ID: this.props.ID,
      Day: this.props.Day,
      From: fromTime,
      To: toTime,
      Source: this.props.Source,
    });
  }

  handleRemoveSchedule() {
    const fromTime = dayjs.utc(this.props.From, 'HH:mm:ss').format('hh:mm A');
    const toTime = dayjs.utc(this.props.To, 'HH:mm:ss').format('hh:mm A');

    CarePatientStore.handleOpenDeleteScheduleModal({
      ID: this.props.ID,
      Day: this.props.Day,
      From: fromTime,
      To: toTime,
      Source: this.props.Source,
    });
  }

  render() {

    const fromTime = dayjs.utc(this.props.From, 'HH:mm:ss').format('hh:mm A');
    const toTime = dayjs.utc(this.props.To, 'HH:mm:ss').format('hh:mm A');

    let dayName = 'Sunday';
    switch (this.props.Day) {
      case '0':
        dayName = 'Sunday';
        break;
      case '1':
        dayName = 'Monday';
        break;
      case '2':
        dayName = 'Tuesday';
        break;
      case '3':
        dayName = 'Wednesday';
        break;
      case '4':
        dayName = 'Thursday';
        break;
      case '5':
        dayName = 'Friday';
        break;
      case '6':
        dayName = 'Saturday';
        break;
    }

    //console.log("From 1",fromDateTime );
    //console.log("From 2",fromTime );

    return (
      <tr>
        <td className="">{dayName}</td>
        <td className="">{fromTime}</td>
        <td className="">{toTime}</td>
        <td className="center-align">
          <span className="nucleus-table-icon nucleus-link tooltipped" data-position="top" data-tooltip="Edit">
            <i className="Tiny material-icons" onClick={this.handleModifySchedule}>
              edit
            </i>
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="nucleus-table-icon nucleus-link tooltipped" data-position="top" data-tooltip="Remove">
            <i className="Tiny material-icons" onClick={this.handleRemoveSchedule}>
              delete
            </i>
          </span>
        </td>
      </tr>
    );
  }
}

export default PatientScheduleItemRow;

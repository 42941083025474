//@ts-nocheck
import classNames from 'classnames';
import TelephoneInputUnderline from 'components/UI/Inputs/TelephoneInputUnderlined';
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { AuthStore, CarePatientStore } from 'stores';
import CompressUtil from '../../utils/CompressUtil';
import Message from '../../utils/Message';

class PatientProfileEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientImageFilePreviewURL: '',
      name: '',
      deviceMacValue: this.props.patientDeviceMac || '',
      errorTelephoneVisibility: 'hide',
      errorTelephoneText: 'Telephone already registered ',
      patientTelephone: this.props.patientTelephone || '',
      txtPatientDate: this.props.patientDateOfBirth && this.props.patientDateOfBirth != null ? new Date(this.props.patientDateOfBirth) : null,
      wrongDateInfo: '',
    };
    this.submitUpdateCarePatientForm = this.submitUpdateCarePatientForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.onUpdatePatientResponseArrivedAction = this.onUpdatePatientResponseArrivedAction.bind(this);
    this.onZipCodeUpdatedAction = this.onZipCodeUpdatedAction.bind(this);
    this.selectedPatientImageFile = this.selectedPatientImageFile.bind(this);
    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  }

  componentDidMount() {
    console.log('PROPS', this.props);
    CarePatientStore.on('onUpdatePatientResponseArrived', this.onUpdatePatientResponseArrivedAction);
    CarePatientStore.on('onZipCodeUpdated', this.onZipCodeUpdatedAction);
    this.setState({
      name: this.props.FirstName,
      macPanel: this.props.patientDeviceMac == 'No Device assigned' ? ' hide ' : '',
      txtPatientDate: this.props.patientDateOfBirth && this.props.patientDateOfBirth != null ? new Date(this.props.patientDateOfBirth) : null,
      patientTelephone: this.props.patientTelephone || '',
    });
  }

  componentWillReceiveProps(updatedProps) {
    console.log('PROP 3', this.props.patientTelephone);
    this.setState({
      txtPatientDate: updatedProps.patientDateOfBirth && updatedProps.patientDateOfBirth != null ? new Date(updatedProps.patientDateOfBirth) : null,
      patientTelephone: this.props.patientTelephone || '',
    });
  }

  componentWillUnmount() {
    CarePatientStore.removeListener('onUpdatePatientResponseArrived', this.onUpdatePatientResponseArrivedAction);
    CarePatientStore.removeListener('onZipCodeUpdated', this.onZipCodeUpdatedAction);
  }

  validateForm() {
    if (this.state.patientTelephone.length > 0) {
      this.setState({
        errorTelephoneVisibility: 'hide',
      });
    }
  }

  onTelephoneInputChange = number => {
    console.log('onTelephoneInputChange');
    console.log('onTelephoneInputChange number', number);
    this.state.patientTelephone = number;

    if (number.length > 0) {
      this.setState({
        errorTelephoneVisibility: 'hide',
      });
    }
  };

  submitUpdateCarePatientForm() {
    console.log('submitUpdateCarePatientForm');

    if (this.refs.txtNewPatientFirstName.value === '') {
      Message.show('FirstName is required.');
      return;
    }

    if (this.refs.txtNewPatientLastName.value === '') {
      Message.show('LastName is required.');
      return;
    }

    let patientTelephone = this.state.patientTelephone;
    if (patientTelephone === '+1') {
      patientTelephone = '';
    }
    if (patientTelephone.length > 0) {
      patientTelephone = patientTelephone.replace(/[()-\s]/g, '');
      if (patientTelephone.length > 0 && patientTelephone.length < 12) {
        this.setState({
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Telephone must be at least 10 digits',
        });
        return;
      }

      const containsLetter = /[a-zA-Z]/.test(patientTelephone);
      if (containsLetter) {
        this.setState({
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Invalid Telephone.',
        });
        return;
      }
    }

    const updatePatientData = {
      PatientID: this.props.patientId,
      FirstName: this.refs.txtNewPatientFirstName.value,
      LastName: this.refs.txtNewPatientLastName.value,
      Telephone: patientTelephone,
      Address: this.refs.txtNewPatientAddress.value,
      //DateOfBirth: patientDateParam,
      //EmergencyContactName: this.refs.txtNewPatientContactName.value,
      //EmergencyContactTelephone: this.refs.txtNewPatientContactNumber.value,
      ZipCode: this.refs.txtNewPatientZipCode.value,
      //ShowWeather : this.refs.checkShowWeather.checked
    };
    CarePatientStore.UpdatePatientData(updatePatientData);
  }

  onUpdatePatientResponseArrivedAction(response) {
    console.log('onUpdatePatientResponseArrivedAction', response);

    if (!response.ok && response.telephoneRegistered) {
      this.setState({
        errorTelephoneVisibility: '',
        errorTelephoneText: response.error,
      });
    }
    // CarePatientStore.updatePatientZipCode({
    //     PatientID : this.props.patientId,
    //     ZipCode : this.refs.txtNewPatientZipCode.value
    // });
  }

  onZipCodeUpdatedAction(response) {
    if (!response.ok) {
      Message.show('Error updating ' + AuthStore.getPatientLabel() + ' Zip Code');
    }
  }
  selectedPatientImageFile(e) {
    const files = e.target.files;
    if (files.length > 0) {
      console.log('selectedPatientImageFile', e);
      const value = files[0];

      const selectedImage = value;
      const reader = new FileReader();
      const url = reader.readAsDataURL(value);
      const urlImage = URL.createObjectURL(value);
      // reader.onloadend = function (e) {
      this.setState({
        clientImageFilePreviewURL: urlImage,
      });
      // };
      console.log('LocalURL image:patient:', [reader.result]);
      console.log('LocalURL:', url); // Would see a path?
      console.log('selectedImage', selectedImage);
      this.setState({
        uploadingPatientImageStatus: 'Uploading...',
      });
      CarePatientStore.UploadPatientImage({
        PatientID: this.props.patientId,
        File: selectedImage,
      });
    } else {
      console.log('No File Selected');
    }
  }

  render() {
    const currentSection = classNames({
      hide: this.props.currentMode != 'Edit',
    });

    return (
      <div class={'nucleus-patient-edit-pending nucleus-form-container ' + currentSection}>
        <div className="row">
          <div className="col s4 m3 l3">
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
              <style>
                {`
                  .img-circle {
                      border-radius: 50%;
                  }
                  .img-circle {
                      border-radius: 0;
                  }
                  
                  .ratio {
                      background-position: center center;
                      background-repeat: no-repeat;
                      background-size: cover;
                  
                      height: 0;
                      padding-bottom: 100%;
                      position: relative;
                      width: 100%;
                  }
                  .img-circle {
                      border-radius: 50%;
                  }
                  .img-responsive {
                      display: block;
                      height: auto;
                      max-width: 100%;
                  }
                  .inputfile {
                      width: 0.1px;
                      height: 0.1px;
                      opacity: 0;
                      overflow: hidden;
                      position: absolute;
                      z-index: -1;
                  }
                  .inputfile + label {
                      margin-top:10px;
                      font-size: 1.25em;
                      // font-weight: 700;
                      text-decoration: underline;
                      color: gray;
                      // background-color: black;
                      display: inline-block;
                  }
                  
                  .inputfile:focus + label,
                  .inputfile + label:hover {
                      cursor: pointer;
                      // background-color: red;
                  }
                  .inputfile {
                      cursor: pointer;
                  }
                `}
              </style>
              <div style={{ width: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <img
                  className=""
                  style={{ width: 140, height: 140, borderRadius: 70, objectFit: 'cover' }}
                  src={
                    this.state.clientImageFilePreviewURL
                      ? this.state.clientImageFilePreviewURL
                      : this.props.patientImageThumb
                        ? this.props.patientImageThumb
                        : '/img/user_placeholder.png'
                  }
                  ref={img => (this.imgRef = img)}
                  //onError={() => (this.imgRef.src = '/img/user_placeholder.png')}
                />
                <div>
                  <input
                    onChange={e => {
                      this.selectedPatientImageFile(e);
                    }}
                    multiple={false}
                    type="file"
                    name="file"
                    id="file"
                    className="inputfile"
                  />
                  <label htmlFor="file">Upload Photo</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col s8 m8 l6">
            <div className="row">
              <div className="col s4 valgin-wrapper">
                <span className=" nucleus-labels">First Name</span> <span className="nucleus-labels txt-red">*</span>
              </div>
              <div className="col s8" key={this.props.patientFirstName}>
                <input id={'edit-firstname'} ref="txtNewPatientFirstName" type="text" className="validate nucleus-input-form" defaultValue={this.props.patientFirstName} />
              </div>
            </div>
            <div className="row">
              <div className="col s4 valgin-wrapper">
                <span className=" nucleus-labels">Last Name</span> <span className="nucleus-labels txt-red">*</span>
              </div>
              <div className="col s8" key={this.props.patientLastName}>
                <input id={'edit-lastname'} ref="txtNewPatientLastName" type="text" className="validate nucleus-input-form" defaultValue={this.props.patientLastName} />
              </div>
            </div>

            <div className="row">
              <div className="col s4">
                <span className=" nucleus-labels"> Telephone</span>
              </div>
              <div className="col s8" key={'txtPatientTelephone'}>
                <TelephoneInputUnderline
                  inputId="txtPatientTelephone"
                  value={this.state.patientTelephone}
                  onChange={this.onTelephoneInputChange}
                  onBlur={this.validateForm}
                  style={{ marginLeft: 3 }}
                />
                <span class={'nucleus-form-error center-align ' + this.state.errorTelephoneVisibility}>{this.state.errorTelephoneText}</span>
              </div>
            </div>
            <div className="row">
              <div className="col s4">
                <span className=" nucleus-labels"> Address</span>
              </div>
              <div className="col s8" key={this.props.patientAddress}>
                <input id={'edit-address'} ref="txtNewPatientAddress" type="text" className="validate nucleus-input-form" defaultValue={this.props.patientAddress || ''} />
              </div>
            </div>
            <div className="row">
              <div className="col s4">
                <span className=" nucleus-labels"> Zip Code </span>
              </div>
              <div className="col s8" key={this.props.patientZipCode}>
                <input id={'edit-zipcode'} ref="txtNewPatientZipCode" type="text" className="validate nucleus-input-form" defaultValue={this.props.patientZipCode || ''} />
              </div>
            </div>

            {/* 
              Removed on Feb 15, 2023
              Patient Date of Birth

              Emergency Contact
              txtNewPatientContactName
              txtNewPatientContactNumber
                        
            */}

            {/*
                        // Removed on Nov 2nd, 2017 - CARE-1343
                        <div className="row ">
                            <div className="col s4">
                                <span className=" nucleus-labels"> Show Weather</span> 
                            </div>
                            <div className="col s8">
                                <div className="switch">
                                    <label key={showWeatherValue}>
                                      <input type="checkbox" defaultChecked={showWeatherValue} ref="checkShowWeather"/>
                                      <span className="lever"></span>
                                    </label>
                                  </div>
                            </div>
                        </div>
       
                        */}
          </div>
          <div className="col s0 m1 l3"></div>
        </div>

        <div className="fixed-action-btn btn-add-new">
          <a
            id={'save-edit'}
            className="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped "
            data-position="left"
            data-tooltip={'Save ' + AuthStore.getPatientLabel()}
            onClick={this.submitUpdateCarePatientForm}
            style={{ marginRight: 16 }}
          >
            <i className="material-icons">save</i>
          </a>
        </div>
      </div>
    );
  }
}

export default props => useReactRouterProps(PatientProfileEdit, props);

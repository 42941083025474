import { reportCallStartedPromise, reportMultiCallStartedPromise } from '@nucleus-care/nucleuscare-backend-client';
import { getAccountDevicesPromise } from '@nucleus-care/nucleuscare-backend-client';

import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import api from 'utils/httpClient';
import NucleusNetwork from 'utils/NucleusNetwork';
import Config from '../Config';
import backendClient from 'utils/BackendClient';
class PatientApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<----------------------------------Get All Patients Data--------------------------->
  //<-------------------------------------  DEPRECATED -------------------------------->
  getAllPatientData(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetPatients',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------Get All Patients Data By User------------------------->
  getAllPatientDataForUser(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetPatientsForUser',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Get User Data-------------------------------->
  GetCarePatientData(data, callback) {
    backendClient.get(`/patient/${data.PatientID}/details`, callback);
  }

  GetPatientBiometricData(data, callback) {
    backendClient.get(`/patients/${data.PatientID}/biometric-data`, callback);
  }

  GetPatientRequestLogs(data, callback) {
    backendClient.get(`/patients/${data.PatientID}/call-request-call-logs?From=${data.From}&To=${data.To}`, callback);
  }

  //<----------------------------------Update Patient Data----------------------------->
  UpdateCarePatientData(data, callback) {
    backendClient.put(`/patients/${data.PatientID}`, data, callback);
  }

  //<----------------------------------Update Patient Image----------------------------->
  UploadCarePatientImage(data, callback) {
    const body = new FormData();
    body.append('File', data.File);
    backendClient.post(`/patients/${data.PatientID}/picture`, body, callback);
  }

  UpdatePatientZipCode(data, callback) {
    backendClient.put(`/patients/${data.PatientID}/zip-code`, data, callback);
  }

  //<----------------------------------Create New Patient--------------------------->
  CreateNewPatient(data, callback) {
    try {
      NucleusNetwork.request({
        context: this.context,
        type: 'POST',
        url: Config.domain + 'CarePatient.asmx/AddPatient',
        data: data,
        callback: callback,
      });
    } catch (ex) {
      callback({
        ok: false,
        error: 'Network Error' + ex.message,
      });
    }
  }

  //<---------------------------------Delete Patient--------------------------------->
  DeletePatient(data, callback) {
    let rsp;
    httpNodeApi
      .delete(`patient/${data.PatientID}/delete`)
      .then(response => {
        console.log('patient/:patientId/delete response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on patient/:patientId/delete' };
        console.log('Error patient/:patientId/delete error', data);
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('patient/:patientId/delete finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  //<-----------------------Get Available Devices for Patient----------------------->
  GetCareDevices(data, callback) {
    backendClient.get(`/devices?accountId=${data.AccountID}`, callback);
  }

  //<-----------------------Assign Device To Patient ----------------------->
  AssignDevicePatient(data, callback) {
    backendClient.put(
      `/patients/${data.PatientID}/device/${data.DeviceID}`,
      {
        deviceName: data.DeviceName,
      },
      callback,
    );
  }

  //<-------------------------- Update Device Name ------------------------->
  UpdateDeviceName(data, callback) {
    backendClient.put(`/devices/${data.DeviceID}/patient-device-name`, data, callback);
  }

  //<-----------------------Unassign Device To Patient ----------------------->
  UnassignDevicePatient(data, callback) {
    backendClient.delete(`/patients/${data.PatientID}/device/${data.DeviceID}`, callback);
  }
  //<-----------------------Request Reboot Device ----------------------->
  RequestRebootDevice(data, callback) {
    backendClient.post(`/devices/${data.DeviceID}/reboot`, data, callback);
  }

  //<-------------------------Report CareCall Started ----------------------->
  async ReportCareCallStarted(data) {
    return reportCallStartedPromise(data);
  }

  ReportMultiCallStarted(data, callback) {
    console.log('ReportMultiCallStarted', data);
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/ReportMultiCallStarted",
    //   data: data,
    //   callback: callback,
    // });
    reportMultiCallStartedPromise(data)
      .then(data => {
        callback(data);
      })
      .catch(e => {
        console.error('Error ReportMultiCallStarted: ', e);
        callback({
          ok: false,
        });
      });
  }

  //<-------------------------Report CareCall Ended ----------------------->
  async ReportCareCallEnded(data) {
    const { data: response } = await httpNodeApi.post('v2/call/report_connected_call_ended', data);
    console.log('v2/call/report_connected_call_ended response', response);
    return response;
  }

  ReportCanceledCall(data, callback) {
    console.log('ReportCanceledCall params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_canceled_call', data)
      .then(response => {
        console.log('v2/call/report_canceled_call response', response);
        const { data } = response;
        rsp = {
          ...data,
          ok: true,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_canceled_call' };
        console.log('Error v2/call/report_canceled_call error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('v2/call/report_canceled_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  ReportFailedCall(data, callback) {
    console.log('ReportFailedCall params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_failed_call', data)
      .then(response => {
        console.log('v2/call/report_failed_call response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_failed_call' };
        console.log('Error v2/call/report_failed_call error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('v2/call/report_failed_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  ReportNotAnsweredCall(data, callback) {
    console.log('ReportNotAnsweredCall params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_not_answered_call', data)
      .then(response => {
        console.log('call/report_not_answered_call response', response);
        const { data } = response;
        rsp = {
          ...data,
          ok: true,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_not_answered_call' };
        console.log('Error v2/call/report_not_answered_call error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('v2/call/report_not_answered_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  ReportBusyCall(data, callback) {
    console.log('ReportBusyCall params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_busy_call', data)
      .then(response => {
        console.log('v2/call/report_busy_call response', response);
        const { data } = response;
        rsp = {
          ...data,
          ok: true,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_busy_call' };
        console.log('Error call/report_busy_call error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('v2/call/report_busy_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  ReportCallDoNotDisturb(data, callback) {
    console.log('ReportCallDoNotDisturb params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_dnd_call', data)
      .then(response => {
        console.log('v2/call/report_dnd_call response', response);
        const { data } = response;
        rsp = {
          ...data,
          ok: true,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_dnd_call' };
        console.log('Error v2/call/report_dnd_call error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('v2/call/report_dnd_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  ReportDeclinedCall(data, callback) {
    console.log('ReportDeclinedCall params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_declined_call', data)
      .then(response => {
        console.log('v2/call/report_declined_call response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_declined_call' };
        console.log('Error call/report_declined_call error', data);
        rsp = {
          ...data,
          ok: true,
        };
      })
      .finally(() => {
        console.log('v2/call/report_declined_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  ReportDisconnectedCall(data, callback) {
    console.log('ReportDisconnectedCall params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_disconnected_call', data)
      .then(response => {
        console.log('v2/call/report_disconnected_call response', response);
        const { data } = response;
        rsp = {
          ...data,
          ok: true,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_disconnected_call' };
        console.log('Error v2/call/report_disconnected_call error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('v2/call/report_disconnected_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  //<-------------------------Report CareCall Ended ----------------------->
  ReportCareCallEndStatus(data, callback) {
    console.log('ReportCareCallEndStatus', Config.domain + 'CareCall.asmx/' + data.Endpoint);
    console.log('ReportCareCallEndStatus data', data);
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareCall.asmx/' + data.Endpoint,
      data: data,
      callback: callback,
    });
  }

  //<---------------------------Get Call Log Notes --------------------------->
  GetCallLogNotes(data, callback) {
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/GetCallLogNotes",
    //   data: data,
    //   callback: callback,
    // });

    let rsp;
    httpNodeApi
      .post('call_log/get_notes', data)
      .then(response => {
        console.log('call_log/get_notes response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call_log/get_notes' };
        console.log('Error call_log/get_notes error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call_log/get_notes finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  GetCallLogDetailsData(data, callback) {
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/GetCallLogDetailsData",
    //   data: data,
    //   callback: callback,
    // });
    let rsp;
    httpNodeApi
      .post('call_log/get_data', data)
      .then(response => {
        console.log('call_log/get_data response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call_log/get_data' };
        console.log('Error call_log/get_data error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call_log/get_data finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  GetCallRequestLogDetailsNotes(data, callback) {
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/GetCallRequestLogDetails",
    //   data: data,
    //   callback: callback,
    // });

    let rsp;
    httpNodeApi
      .post('call_log/get_request_notes', data)
      .then(response => {
        console.log('call_log/get_request_notes response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call_log/get_request_notes' };
        console.log('Error call_log/get_request_notes error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call_log/get_request_notes finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  GetCallRequestLogDetailsData(data, callback) {
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/GetCallRequestLogDetailsData",
    //   data: data,
    //   callback: callback,
    // });
    let rsp;
    httpNodeApi
      .post('call_log/get_request_data', data)
      .then(response => {
        console.log('call_log/get_request_data response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call_log/get_request_data' };
        console.log('Error call_log/get_request_data error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call_log/get_request_data finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  //<-----------------------Update Call Log Notes --------------------------->
  UpdateCallLogNotes(data, callback) {
    let rsp;
    httpNodeApi
      .put('call_log/' + data.CallLogID + '/update_notes', data)
      .then(response => {
        console.log('call_log/:callLogId/update_notes response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call_log/:callLogId/update_notes' };
        console.log('Error call_log/:callLogId/update_notes error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call_log/:callLogId/update_notes finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/UpdateCallLogNotes",
    //   data: data,
    //   callback: callback,
    // });
  }

  UpdateCallRequestLogNotes(data, callback) {
    let rsp;
    httpNodeApi
      .put('call_log/' + data.CallRequestLogID + '/update_request_notes', data)
      .then(response => {
        console.log('call_log/:callLogId/update_request_notes response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call_log/:requestLogId/update_request_notes' };
        console.log('Error call_log/:callLogId/update_request_notes error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call_log/:callLogId/update_request_notes finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/UpdateCallRequestLogNotes",
    //   data: data,
    //   callback: callback,
    // });
  }

  //<-----------------------Get Patient Assignments --------------------------->
  GetPatientAssignments(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/ListAssignments',
      data: data,
      callback: callback,
    });
  }

  //<-----------------------Get Patient FamilyMembers --------------------------->
  // GetPatientFamilyMembers(data, callback) {
  //   NucleusNetwork.request({
  //     context: this.context,
  //     type: "POST",
  //     url: Config.domain + "FamilyMember.asmx/List",
  //     data: data,
  //     callback: callback,
  //   });
  // }
  // DEPRECATED: Jan 26th 2022, Moved to Node Backend.

  CheckExistingTelephone(data, callback) {
    backendClient.get(`/family-members/already-exists?telephone=${data.Telephone}`, callback);
  }

  //<-----------------------Create New Family Member --------------------------->
  CreateNewFamilyMember(data, callback) {
    backendClient.post(`/patients/${data.PatientID}/family-members`, data, callback);
  }

  AssignPatientMember(data, callback) {
    backendClient.post(`/family-members/${data.FamilyMemberID}/patient`, data, callback);
  }

  //<-------------------------Update Family Member ----------------------------->
  UpdateFamilyMember(data, callback) {
    const { FamilyMemberID } = data;
    delete data.FamilyMemberID;
    backendClient.put(`/family-member/${FamilyMemberID}`, data, callback);
  }

  UpdateFamilyMemberName(data, callback) {
    let rsp;
    const payload = {
      FirstName: data?.FirstName,
      LastName: data?.LastName,
    };
    httpNodeApi
      .put(`family_member/${data.FamilyMemberID}`, payload)
      .then(response => {
        console.log('family_member/:familyMemberId response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on family_member/:familyMemberId' };
        console.log('Error family_member/:familyMemberId error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('family_member/:familyMemberId finally');
        console.log('rsp', rsp);
        callback(rsp);
      });
  }

  UpdateFamilyMemberTelephone(data, callback) {
    let rsp;
    const payload = {
      Telephone: data?.Telephone,
    };
    httpNodeApi
      .put(`family_member/${data.FamilyMemberID}/updateTelephone`, payload)
      .then(response => {
        console.log('family_member/:familyMemberId/updateTelephone response', response);
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on family_member/:familyMemberId/updateTelephone' };
        console.log('Error family_member/:familyMemberId/updateTelephone error', data);
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('family_member/:familyMemberId/updateTelephone finally');
        console.log('rsp', rsp);
        callback(rsp);
      });
  }

  //<--------------------------Delete Family Member --------------------------->
  DeleteFamilyMember(data, callback) {
    let rsp;
    httpNodeApi
      .delete(`patient/${data?.PatientID}/family/${data?.FamilyMemberID}`)
      .then(response => {
        console.log('patient/:patientId/family/:familyMemberId delete response', response);
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on patient/:patientId/family/:familyMemberId delete' };
        console.log('Error patient/:patientId/family/:familyMemberId error', data);
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('patient/:patientId/family/:familyMemberId delete finally');
        console.log('rsp', rsp);
        callback(rsp);
      });
  }

  //<--------------------------Get Patient Notes ------------------------------>
  GetPatientNotes(data, callback) {
    let rsp;
    httpNodeApi
      .get(`patient/${data.PatientID}/notes?fromDate=${data.From}&toDate=${data.To}`)
      .then(response => {
        console.log('patient/:patientId/notes response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on patient/:patientId/notes response' };
        console.log('Error patient/:patientId}/notes response error', data);
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('patient/:patientId/notes response finally');
        console.log('rsp', rsp);
        callback(rsp);
      });
  }
  //<--------------------------Add Patient Note ------------------------------>
  AddPatientNote(data, callback) {
    let rsp;
    const payload = {
      userId: data.UserID,
      note: data.Note,
    };
    httpNodeApi
      .post(`patient/${data.PatientID}/notes`, payload)
      .then(response => {
        console.log('patient/:patientId/notes response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on patient/:patientId/notes response' };
        console.log('Error patient/:patientId}/notes response error', data);
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('patient/:patientId/notes response finally');
        console.log('rsp', rsp);
        callback(rsp);
      });
  }

  //<------------------------Update Patient Note ------------------------------>
  UpdatePatientNote(data, callback) {
    backendClient.put(`/patients/${data.PatientID}/notes/${data.NoteID}`, data, callback);
  }

  //<------------------------Delete Patient Note ------------------------------>
  DeletePatientNote(data, callback) {
    backendClient.delete(`/patient/${data.PatientID}/notes/${data.NoteID}`, callback);
  }

  //<----------------------Update Patient Message ---------------------------->
  UpdatePatientMessage(data, callback) {
    backendClient.put(`/messages/${data.ID}`, data, callback);
  }

  //<-----------------------Delete Patient Message ------------------------------>
  DeletePatientMessage(data, callback) {
    backendClient.delete(`messages/patient-messages/${data.PatientMessageID}`, callback);
  }

  //<-------------------------Send Message to Device -------------------------->
  // Monday Januray 8th, 2018 -> DEPRECATED
  SendMessageToDevice(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/SendMessageToDevice',
      data: data,
      callback: callback,
    });
  }

  //<---------------------Send Message to All Patients ----------------------->
  SendBroadcastMessage(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/SendMessageToAllPatients',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------Get Patient Messages ---------------------------->
  GetPatientMessages(data, callback) {
    backendClient.get(`/patients/${data.PatientID}/messages`, callback);
  }

  GetDeliveredPatientMessages(data, callback) {
    backendClient.get(`/patients/${data.PatientID}/delivered-messages`, callback);
  }

  GetScheduledPatientMessages(data, callback) {
    backendClient.get(`/patients/${data.PatientID}/scheduled-messages`, callback);
  }

  UpdateDeviceCallShortcut(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareDevice.asmx/UpdateDeviceCallShortcut',
      data: data,
      callback: callback,
    });
  }

  //Config


  // * * * * * * * * * * * * * * * * * * * * * * * * *
  UpdatePatientConfigString(data, endpoint, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + endpoint,
      data: data,
      callback: callback,
    });
  }

  // * * * * * * * * * * * * * * * * * * * * * * * * *
  UpdatePatientConfigFlag(data, endpoint, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + endpoint,
      data: data,
      callback: callback,
    });
  }

  UpdateShowCallButton(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateShowCallButton',
      data: data,
      callback: callback,
    });
  }

  UpdateShowCallButton2(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateShowCallButton2',
      data: data,
      callback: callback,
    });
  }

  UpdateShowCheckInButton(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateShowCheckInButton',
      data: data,
      callback: callback,
    });
  }

  UpdateShowEmergencyButton(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateShowEmergencyButton',
      data: data,
      callback: callback,
    });
  }

  UpdateShowWeather(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateShowWeather',
      data: data,
      callback: callback,
    });
  }

  UpdateShowCallControls(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateShowCallButtonsBar',
      data: data,
      callback: callback,
    });
  }

  UpdateCallPhoneFromDevice(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateCallPhoneFromDevice',
      data: data,
      callback: callback,
    });
  }

  deleteFamilyPhotos(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/DeleteFamilyPhotos',
      data: data,
      callback: callback,
    });
  }

  deleteFamilyMembers(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/DeleteFamilyMembers',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableMemoryBox(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableMemoryBox',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableBlankMemoryBox(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateBlankEnableMemoryBox',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableExternalApps(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableExternalApps',
      data: data,
      callback: callback,
    });
  }

  UpdateZipCode(data, callback) {
    backendClient.put(`/patients/${data.PatientID}/zip-code`, data, callback);
  }

  UpdateEnableBiometrics(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableBiometrics',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableBluetoothButtons(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableBluetoothButtons',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableAutoAnswer(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableAutoAnswer',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableAppIncomingCall(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableAppIncomingCall',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableImpairedRingtone(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableImpairedRingtone',
      data: data,
      callback: callback,
    });
  }

  UpdateFamilyEnableAutoAnswer(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateFamilyEnableAutoAnswer',
      data: data,
      callback: callback,
    });
  }

  UpdateFamilyAdmin(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateFamilyAdmin',
      data: data,
      callback: callback,
    });
  }

  UpdateBloodPressureMetrics(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientBloodPressureAlert',
      data: data,
      callback: callback,
    });
  }

  UpdateOxiMeterMetrics(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientOxiMeterAlert',
      data: data,
      callback: callback,
    });
  }

  UpdateScaleMetrics(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientScaleAlert',
      data: data,
      callback: callback,
    });
  }

  UpdateTemperatureMetrics(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientTemperatureAlert',
      data: data,
      callback: callback,
    });
  }

  UpdateBloodSugarAlert(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientBloodSugarAlert',
      data: data,
      callback: callback,
    });
  }

  UpdateHoursSleptAlert(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientHoursSleptAlert',
      data: data,
      callback: callback,
    });
  }

  //Patient Schedule
  GetPatientSchedule(data, callback) {
    backendClient.get(`/patients/${data.PatientID}/button-schedule`, callback);
  }

  AddNewPatientSchedule(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareSchedule.asmx/AddPatientSchedule',
      data: data,
      callback: callback,
    });
  }

  EditPatientSchedule(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareSchedule.asmx/UpdatePatientSchedule',
      data: data,
      callback: callback,
    });
  }

  RemovePatientSchedule(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareSchedule.asmx/RemovePatientSchedule',
      data: data,
      callback: callback,
    });
  }

  CopySchedule(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareSchedule.asmx/AddDefaultScheduleToPatient',
      data: data,
      callback: callback,
    });
  }

  ResetPatientUseSchedule(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareSchedule.asmx/ResetPatientUseSchedule',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableVoiceMessage(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableVoiceMessage',
      data: data,
      callback: callback,
    });
  }

  GetAccountDevices(data, callback) {
    // DEPRECATED
    // NucleusNetwork.request({
    // 	context : this.context,
    // 	type : "POST",
    // 	url: Config.domain + "CareCs.asmx/GetAccountDevices",
    // 	data : data,
    // 	callback : callback,
    // });
    getAccountDevicesPromise(data.AccountID)
      .then(data => {
        callback(data);
      })
      .catch(e => {
        console.log('CarePatientStore GetAccountDevices Error: ', e);
        callback({
          ok: false,
        });
      });
  }

  ClearPatientCache(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'BulletinBoard.asmx/ClearPatientCache',
      data: data,
      callback: callback,
    });
  }
}

export default PatientApi;

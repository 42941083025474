import { EventEmitter } from 'events';
import AlertsApi from '../apis/AlertsApi';

class CareAlertsStore extends EventEmitter {
  private AlertsApi: AlertsApi;
  private AlertsArray: any[] = [];
  private TypesArray: any[] = [];
  private AlertsArraySize: number = 0;
  private AlertsCount: number = 0;
  private retrievingAlerts: boolean = false;
  constructor() {
    super();

    this.AlertsApi = new AlertsApi(this);

    try {
      this.AlertsArray = localStorage.AlertsArray ? JSON.parse(localStorage.AlertsArray) : [];
      this.TypesArray = localStorage.TypesArray ? JSON.parse(localStorage.TypesArray) : [];
      this.AlertsArraySize = this.AlertsArray ? this.AlertsArray.length : 0;
    } catch (e) {
      console.error('Error parsing AlertsArray from localStorage', e);
      this.AlertsArray = [];
      this.TypesArray = [];
      this.AlertsArraySize = 0;
    }
    this.AlertsCount = parseInt(localStorage.AlertsCount) || 0;
    this.retrievingAlerts = false;
  }

  getAlertsArray() {
    return this.AlertsArray;
  }

  getAlertsArraySize() {
    return this.AlertsArraySize;
  }

  getAlertsCount() {
    return this.AlertsCount;
  }

  getAlertsTypes() {
    console.log('getAlertsTypes');
    this.AlertsApi.GetAlertsTypes({}, rsp => {
      console.log('getAlertsTypes Response', rsp);
      this.TypesArray = rsp.Types;
      this.saveToLocalStorage();

      //this.emit("onGetMessageCategories", rsp);
    });
  }

  getAlertsCountForAccount({ AccountID }) {
    //console.log("getAlertsCountForAccount ");

    this.AlertsApi.GetAlertsCountByAccount({ AccountID }, response => {
      //console.log("GetAlertsCountByAccount response", response);
      //this.updateCachePendingRequest(response.requests);
      const alerts = (response && response.alerts) || [];
      this.emit('onAlertsCountUpdated', {
        ok: (response && response.ok) || false,
        alerts: alerts,
      });
      //console.log(">>>>> Saved Alerts:", this.AlertsCount );
      //console.log(">>>>> Current Alerts:", response.alerts );

      /*
			if (this.AlertsCount != response.alerts && !this.retrievingAlerts){
				console.log("Fech Data Again");
				this.getAlertsByAccount({ AccountID : AccountID });
			} else {
				console.log("We are good or retrieving.");
			}
			*/
    });
  }

  getAlertsByAccount({ AccountID, FromDateTime, ToDateTime, TimeZoneOffset }) {
    console.log('getAlertsByAccount ', AccountID, FromDateTime, ToDateTime, TimeZoneOffset);

    this.retrievingAlerts = true;
    this.AlertsApi.GetAlertsForAccountByType({ AccountID, FromDateTime, ToDateTime, TimeZoneOffset }, rsp => {
      //console.log("getAlertsByAccount Response", rsp);
      this.retrievingAlerts = false;
      if (rsp.ok) {
        let sortedArray = [].concat(rsp.alertsArray);
        sortedArray = sortedArray.sort((a, b) => Number(new Date(b.AlertOccurredOnMs)) - Number(new Date(a.AlertOccurredOnMs)));
        this.AlertsArray = sortedArray;
        this.AlertsArraySize = sortedArray.length;
        //this.saveToLocalStorage();
        this.AlertsCount = rsp.alertsArray.length;
      }

      this.emit('onGetAlertsByAccountUpdated', rsp.ok);
      this.emit('onAlertsCountUpdated_2', { ok: rsp.ok, alerts: rsp.alerts });
      this.saveToLocalStorage();
    });
  }

  openAlertDetails(alertData) {
    //console.log("openAlertDetails", alertData);

    this.emit('onOpenAlertDetails', alertData);
  }

  markAlertAsHandled({ AlertID, UserID, ActionTaken }) {
    console.log('markAlertAsHandled ', AlertID, UserID, ActionTaken);

    this.AlertsApi.MarkAlertAsHandled({ AlertID, UserID, ActionTaken }, rsp => {
      this.emit('onAlertHandled', rsp.ok);
    });
  }

  markAlertsByTypeAsHandled({ AccountID, TypeTag, UserID, ActionTaken }) {
    console.log('markAlertsByTypeAsHandled ', AccountID, TypeTag, UserID, ActionTaken);

    this.AlertsApi.MarkAlertsByTypeAsHandled({ AccountID, TypeTag, UserID, ActionTaken }, rsp => {
      this.emit('onAlertsByTypeHandled', rsp.ok);
    });
  }

  getHandledAlertsForAccountByDateTime({ AccountID, FromDateTime, ToDateTime, TimeZoneOffset }) {
    console.log('getHandledAlertsForAccountByDateTime ', AccountID, FromDateTime, ToDateTime, TimeZoneOffset);

    this.AlertsApi.GetHandledAlertsForAccountByDateTime({ AccountID, FromDateTime, ToDateTime, TimeZoneOffset }, rsp => {
      if (rsp.ok) {
        //this.AlertsArray = rsp.alertsArray;
        //this.saveToLocalStorage();
        //this.saveToLocalStorage();
      }

      this.emit('onGetHandledAlertsUpdated', rsp);
    });
  }

  refreshAlertsReport() {
    this.emit('onRefreshAlertsReport');
  }

  openHandledAlertDetails(handledAlertData) {
    //console.log("openHandledAlertDetails", handledAlertData);

    this.emit('onOpenHandledAlertDetails', handledAlertData);
  }

  saveToLocalStorage() {
    localStorage.AlertsArray = JSON.stringify(this.AlertsArray);
    localStorage.TypesArray = JSON.stringify(this.TypesArray);
    localStorage.AlertsCount = this.AlertsCount.toString();

    //localStorage.QueueEmergencyList = this.QueueEmergencyList;
    //localStorage.QueueRequestList = this.QueueRequestList;
  }
}

const careAlertsStore = new CareAlertsStore();

export default careAlertsStore;

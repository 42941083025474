import React, { useEffect, useState } from 'react';
import { Gallery } from 'react-grid-gallery';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const MemoryBoxGallery = ({ images, toolbarButtons, currentImageWillChange }) => {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  useEffect(() => {
    currentImageWillChange(index);
  }, [index]);

  const handleClick = (index, item) => {
    setIndex(index);
  };
  const handleClose = () => {
    setIndex(-1);
  };
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);


  return (
    <div>
      <Gallery images={images} onClick={handleClick} enableImageSelection={false} />
      {!!currentImage && !!(index >= 0) && (
        <Lightbox
          reactModalStyle={{
            overlay: { zIndex: 900 },
          }}
          mainSrc={currentImage.src}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.src}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.src}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
          toolbarButtons={toolbarButtons}
        />
      )}
    </div>
  );
};
export default MemoryBoxGallery;

import { httpApi } from '@nucleus-care/nucleuscare-backend-client';

class BackendClient {
  get(url, callback, config = {}) {
    let rsp;
    httpApi
      .get(url, config)
      .then(response => {
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch(e => {
        console.error('BackendClient > get > ' + url + ' > error: ', e);
        const { data } = e && e.response ? e.response : e.message || 'error BackendClient get for ' + url;
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        //console.log("BackendClient > get > " + url + " > finally: ", rsp);
        callback(rsp);
      });
  }

  put(url, data, callback) {
    let rsp;
    httpApi
      .put(url, data)
      .then(response => {
        console.log('BackendClient > put > ' + url + ' > response: ', response);
        rsp = {
          ok: true,
          ...response.data,
        };
      })
      .catch(e => {
        console.error('BackendClient > put > ' + url + ' > error: ', e);
        const { data } = e.response;
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('BackendClient > put > ' + url + ' > finally: ', rsp);
        callback(rsp);
      });
  }

  post(url, data, callback) {
    let rsp;
    httpApi
      .post(url, data)
      .then(response => {
        //console.log("BackendClient > post > " + url + " > response: ", response);
        rsp = response.data;
      })
      .catch(e => {
        console.error('BackendClient > post > ' + url + ' > error: ', e.message);
        const { data } = e.response;

        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        //console.log("BackendClient > post > " + url + " > finally: ", rsp);
        callback(rsp);
      });
  }

  delete(url, callback) {
    let rsp;
    httpApi
      .delete(url)
      .then(response => {
        console.log('BackendClient > delete > ' + url + ' > response: ', response);
        rsp = {
          ok: true,
          ...response.data,
        };
      })
      .catch(e => {
        console.error('BackendClient > delete > ' + url + ' > error: ', e);
        const { data } = e.message;
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('BackendClient > delete > ' + url + ' > finally: ', rsp);
        callback(rsp);
      });
  }
}

const backendClient = new BackendClient();
export default backendClient;

import { RootState } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/store';
import { NucleusCommunicationSignaling } from '@nucleus-care/nucleuscare-connect-signaling';
import signalingData from '@nucleus-care/nucleuscare-connect-signaling/package.json';
import webrtcData from '@nucleus-care/nucleuscare-connect-webrtc/package.json';
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import { useEffect, useMemo, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../../package.json';
import { useNucleusProviders } from '../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import { getProviderUserDetails } from '../../pages/Providers/utils/providerApi';
import AuthStore from '../../stores/AuthStore';
//import { useHistory } from "react-router-dom";

import { usePolling } from '../../utils/polling';
import {
  ProviderAccountsSVG,
  ProviderSchedule,
  ProviderQueue,
  ProviderCallLogs,
  LogoutSVG,
  AlertsCountSVG,
  ProvidersGroupingSVG,
  ProviderManageUsersSVG,
} from '../SideBar/SidebarSVGs';
import { ProviderSidebarMenuItem } from './ProviderSidebarMenuItem';

const communication = NucleusCommunicationSignaling.getInstance();

const ding_audio = new Audio('https://app-dev.nucleusportal.com/audio/ding_alert_sound.mp3');
const ring_audio = new Audio('https://app-dev.nucleusportal.com/audio/ring_alert_sound.mp3');
const playSound = () => {
  console.log('ProviderSideBar getProviderCallRequestSound', AuthStore.getProviderCallRequestSound());
  if (AuthStore.getProviderCallRequestSound() === 1) {
    ding_audio.play().catch(error => console.error('Error playing ding_audio sound:', error));
  } else if (AuthStore.getProviderCallRequestSound() === 2) {
    ring_audio.play().catch(error => console.error('Error playing ring_audio sound:', error));
  }
};

const PROVIDER_QUEUE_ROUTE = '/provider/queue';

const NucleusLogo = () => {
  return (
    <span id={'sili-dashboard'} className="txt-white">
      <div
        className="sidebar-logo center-align"
        style={{
          cursor: 'default',
          userSelect: 'none',
        }}
      >
        <br />
        <img className="responsive-img" src="/img/nucleus-logo.svg" />
      </div>
    </span>
  );
};

const ProviderSideBar = ({ dispatch, props }) => {
  const [providersAccountsEnabled, setProvidersAccountsEnabled] = useState<boolean>(false);
  const call = useSelector((state: RootState) => state.call.call);
  const notificationTimeout = useRef<NodeJS.Timeout>();
  const navigate = useNavigate();

  const [schedulingEnabled, setSchedulingEnabled] = useState<boolean>(false);
  const [queueEnabled, setQueueEnabled] = useState<boolean>(false);
  const [callLogsEnabled, setCallLogsEnabled] = useState<boolean>(false);
  const [providerGrouping, setProviderGrouping] = useState<boolean>(false);
  const [manageUsersEnabled, setManageUsersEnabled] = useState<boolean>(false);

  const providerId = AuthStore.getProviderID();
  const userId = AuthStore.getUserID();

  useEffect(() => {
    // Check for Notification permission
    console.log('Notification.permission', Notification.permission);
    if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        } else {
          console.log('Notification permission not granted.');
        }
      });
    }
  }, []);

  const triggerNotification = () => {
    if (notificationTimeout.current) {
      clearTimeout(notificationTimeout.current);
    }
    if (!communication.currentCall && !call) {
      // Play sound
      playSound();

      // Show notification
      const notification = new Notification(`${state.queue.length} call requests.`, {
        body: 'Click to open the call requests.',
        // Optional: icon, image, etc.
      });

      // Optional: Notification click event
      notification.onclick = () => {
        console.log('Notification clicked');
        // You can redirect the user or perform other actions
        // This will focus the tab if the app is already open in a tab.
        window.focus();
        // Change the location hash. This will navigate within your SPA.
        navigate('/provider/queue');
      };
    }

    if (notificationTimeout.current) {
      clearTimeout(notificationTimeout.current);
    }

    notificationTimeout.current = setTimeout(() => {
      triggerNotification();
    }, 1000 * 60); // 1 minute
  };

  const isProvider = !!providerId;
  //const history = useHistory();

  const { state, actions } = useNucleusProviders();

  useEffect(() => {
    if (state.queue.length > 0) {
      triggerNotification();
    } else if (notificationTimeout.current) {
      clearTimeout(notificationTimeout.current);
    }
    return () => {
      if (notificationTimeout.current) {
        clearTimeout(notificationTimeout.current);
      }
    };
  }, [state.queue.length, call]);

  useEffect(() => {
    async function getProviderUserPermissions(providerId: string, userId: string) {
      const { data: providerUser } = await getProviderUserDetails(providerId, userId);

      console.log('providerUser ==>', providerUser);
      //Fetch provider settings

      if (providerUser.providerPermissions) {
        if (
          (!providerUser.providerPermissions.canAccessProvidersSchedule && window.location?.hash?.includes('/provider/schedule')) ||
          (!providerUser.providerPermissions.canAccessProvidersQueue && window.location?.hash?.includes('/provider/queue')) ||
          (!providerUser.providerPermissions.canAccessProvidersCallLogs && window.location?.hash?.includes('/provider/call-logs')) ||
          (!providerUser.providerPermissions.canAccessProvidersGrouping && window.location?.hash?.includes('/provider/grouping')) ||
          (!providerUser.providerPermissions.canAccessManageUsers && window.location?.hash?.includes('/provider/manage-users'))
        ) {
          console.log('Provider SidebarProps', props);
          navigate(`/provider/${providerId}/accounts`, {
            replace: true,
          });
        }

        setProvidersAccountsEnabled(providerUser.providerPermissions.canAccessProvidersAccounts);
        setSchedulingEnabled(providerUser.providerPermissions.canAccessProvidersSchedule);
        setQueueEnabled(providerUser.providerPermissions.canAccessProvidersQueue);
        setCallLogsEnabled(providerUser.providerPermissions.canAccessProvidersCallLogs);
        setProviderGrouping(providerUser.providerPermissions.canAccessProvidersGrouping);
        setManageUsersEnabled(providerUser.providerPermissions.canAccessManageUsers);
      }
    }

    if (providerId && userId) {
      getProviderUserPermissions(providerId, userId);
    }
  }, [providerId, userId]);

  useEffect(() => {
    // Redirect to dashboard if not a provider
    if (!isProvider) {
      // navigate('/dashboard', {
      //   replace: true,
      // });
      //window.location.replace(`/provider/queue`);
    }
  }, [isProvider]);

  const ProviderRoutes = useMemo(
    () => [
      {
        id: 'sili-providers',
        path: `/provider/${providerId}/accounts`,
        isVisible: providersAccountsEnabled,
        onClick: () => {},
        menuIcon: <ProviderAccountsSVG />,
        text: 'Accounts',
      },
      {
        id: 'sili-manage-users',
        path: '/provider/manage-users',
        isVisible: manageUsersEnabled,
        onClick: () => {},
        menuIcon: <ProviderManageUsersSVG />,
        text: 'Manage Users',
      },
      {
        id: 'sili-schedule',
        path: '/provider/schedule',
        isVisible: schedulingEnabled,
        onClick: () => {},
        menuIcon: <ProviderSchedule />,
        text: 'Schedule',
      },
      {
        id: 'sili-provider-queue',
        path: PROVIDER_QUEUE_ROUTE,
        isVisible: queueEnabled,
        onClick: () => {},
        menuIcon:
          state.queue.length > 0 ? (
            <ProviderQueue>
              <AlertsCountSVG count={state.queue.length} />
            </ProviderQueue>
          ) : (
            <ProviderQueue />
          ),
        text: 'Queue',
      },
      {
        id: 'sili-provider-call-logs',
        path: '/provider/call-logs',
        isVisible: callLogsEnabled,
        onClick: () => {},
        menuIcon: <ProviderCallLogs />,
        text: 'Call Logs',
      },
      {
        id: 'sili-provider-grouping',
        path: '/provider/grouping',
        isVisible: providerGrouping,
        onClick: () => {},
        menuIcon: <ProvidersGroupingSVG />,
        text: 'Provider Grouping',
      },
    ],
    [state.queue, schedulingEnabled, queueEnabled, callLogsEnabled, providersAccountsEnabled, providerGrouping, manageUsersEnabled],
  );
  const startPollingQueue = async () => {
    if (providerId) {
      actions.fetchQueueForProvider(providerId);
    }
  };

  // Start polling for queue
  usePolling(startPollingQueue, 1000 * 10 * 1, !!providerId);

  const handleLogout = () => {
    console.log('handleLogout');
    if (notificationTimeout.current) {
      clearTimeout(notificationTimeout.current);
    }
    AuthStore.logout(dispatch);
  };

  const getVersion = () => {
    let version = 'v 2';
    try {
      version = 'v ' + packageJson.version;
    } catch (error) {
      console.warn('Error getVersion cannot read packageJson');
    }
    return version;
  };

  return (
    <div className="blue col s2 m2 l1 xl1  no-margin nucleus-sidebar">
      <div className="row txt-white menu-content">
        <NucleusLogo />
        {ProviderRoutes.map(route => {
          return (
            <ProviderSidebarMenuItem
              key={route.id}
              id={route.id}
              path={route.path}
              isVisible={route.isVisible}
              onClick={route.onClick}
              menuIcon={route.menuIcon}
              text={route.text}
            />
          );
        })}

        <div className="row"></div>

        <div id={'sili-logout'} className="row txt-white nucleus-sidebar-bottom nucleus-link" onClick={handleLogout}>
          <div className="center-align row nucleus-menu">
            <div className="col m12">
              <LogoutSVG />
            </div>
            <div className="col m12" style={{ color: 'white', fontWeight: 'normal' }}>
              Logout
            </div>
          </div>
        </div>
        <p className="nucleus-font-book" style={{ textAlign: 'center', marginTop: 25 }}>
          <span style={{ fontSize: 14, color: '#FFFFFFA9' }}>{getVersion()}</span>
          <br></br>
          <span style={{ fontSize: 12, color: '#FFFFFF39' }}>{`s ${signalingData.version}- w ${webrtcData.version}`}</span>
        </p>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const ProviderSideBarFC = props => {
  return useReactRouterProps(ProviderSideBar, props);
};

export default connect(null, mapDispatchToProps)(ProviderSideBarFC);

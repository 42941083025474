import backendClient from 'utils/BackendClient';
import Config from '../Config';
import NucleusNetwork from '../utils/NucleusNetwork';

class PicturesApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<---------------------------- Get Pictures by Account ------------------------->
  GetPicturesByAccount(data, callback) {
    let queryParams = '';

    if (data.page) {
      queryParams = `?page=${data.page}`;
    }

    if (data.limit) {
      queryParams = `${queryParams}&limit=${data.limit}`;
    }
    backendClient.get(`/accounts/${data.AccountID}/memorybox${queryParams}`, callback);
  }

  GetPicturePatients(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/GetPatientsForPicture',
      data: data,
      callback: callback,
    });
  }

  AssignPicturePatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/AttachPictureToPatient',
      data: data,
      callback: callback,
    });
  }

  UnAssignPicturePatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/RemovePictureForPatient',
      data: data,
      callback: callback,
    });
  }

  SetPictureForAllPatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/UpdateMediaForAllPatients',
      data: data,
      callback: callback,
    });
  }

  UpdateImagePatients(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/UpdatePictureForPatients',
      data: data,
      callback: callback,
    });
  }

  GetAutocompletePatientsList({ UserID }: { UserID: string }, callback) {
    backendClient.get(`/user/${UserID}/patients-autocomplete-list`, callback);
  }

  UploadMedia(data, callback) {
    backendClient.post(`/accounts/${data.AccountID}/memorybox`, data, callback);
    // NucleusNetwork.requestWithFile({
    //   context: this.context,
    //   type: 'POST',
    //   url: Config.domain + 'CareMemoryBox.asmx/UploadMedia',
    //   data: data,
    //   callback: callback,
    // });
    // } catch(ex){
    // 	callback({ok:false, error : ex});
    // }
  }

  //<----------------------------------Update Media Caption--------------------------->
  UpdateMediaCaption(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMemoryBox.asmx/UpdateMediaCaption',
      data: data,
      callback: callback,
    });
  }

  DeleteMedia(data, callback) {
    backendClient.delete(`/accounts/${data.AccountID}/memorybox/${data.MediaID}`, callback);
  }
}

export default PicturesApi;

import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import backendClient from 'utils/BackendClient';
class BulletinApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<-------------------- Get BulletinBoard Buttons by Account --------------------->
  GetAccountBulletinButtons(data, callback) {
    backendClient.get(`/bulletin-board/${data.AccountID}/all-buttons`, callback);
  }

  //<---------------------------Update Media Caption--------------------------->
  UpdateButtonContent(data, callback) {
    const payload = {
      content: data?.Content,
    };
    backendClient.put(`/bulletin_board/${data?.ButtonID}`, payload, callback);
  }

  UploadImage(data: FormData, callback) {
    const AccountID = data.get('AccountID');
    backendClient.post(`account/${AccountID}/bulletin-images`, data, callback);
  }

  UploadPdf(data: FormData, callback) {
    const AccountID = data.get('AccountID');
    backendClient.post(`account/${AccountID}/bulletin-pdfs`, data, callback);
  }
}

export default BulletinApi;

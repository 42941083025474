import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { useEffect, useState } from 'react';
import authStore from '../../stores/AuthStore';
import Message from '../../utils/Message';

export type Patient = {
  id: string;
  firstName?: string;
  lastName?: string;
  thumbnailUrl?: string;
  name?: string;
};

const userId = authStore.getUserID();

const usePatientSelector = () => {
  const [allPatients, setAllPatients] = useState<Patient[]>([]);
  const [selectedPatients, setSelectedPatients] = useState<Patient[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchPatients();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const fetchPatients = () => {
    if (!searchTerm) {
      setAllPatients([]);
      return;
    }
    setIsLoading(true);
    httpApi
      .get(`/user/${userId}/patients-autocomplete-list?name=${searchTerm}&limit=10`)
      .then(({ data }) => {
        if (data?.autocompleteOptions) {
          const fetchedPatients = data.autocompleteOptions.map((patient: any) => ({
            id: patient.ID,
            name: patient.Name,
            avatarUrl: patient.avatarUrl,
          }));
          // Update all patients, exclude already selected ones
          setAllPatients(fetchedPatients.filter(fp => !selectedPatients.some(sp => sp.id === fp.id)));
        } else {
          setAllPatients([]);
        }
        setIsLoading(false);
      })
      .catch(e => {
        if (e.response) {
          Message.show(`${e.response.data?.message || 'Error getting patients'}`);
        } else {
          Message.show('Error getting patients');
        }
        setIsLoading(false);
      });
  };

  const togglePatientSelection = (patientId: string) => {
    const isCurrentlySelected = selectedPatients.some(p => p.id === patientId);

    if (isCurrentlySelected) {
      setSelectedPatients(selectedPatients.filter(p => p.id !== patientId));
    } else {
      const patientToAdd = allPatients.find(p => p.id === patientId);
      if (patientToAdd) setSelectedPatients([...selectedPatients, patientToAdd]);
    }
  };

  const reset = () => {
    setSelectedPatients([]);
  };

  return {
    searchTerm,
    patients: allPatients,
    selectedPatients,
    togglePatientSelection,
    reset,
    setSearchTerm,
    isLoading,
  };
};

export default usePatientSelector;

//@ts-nocheck
import MomentUtils from '@date-io/moment';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';

import Config from '../../Config';
import AuthStore from '../../stores/AuthStore';
import UserStore from '../../stores/UserStore';

class DashboardContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData1: [],
      datesErrorVisible: ' hide',
      datesErrorText: 'The From Date has to be before the To Date.',

      requestCallFromDateTime: null,
      requestCallToDateTime: null,

      fromDateTime: null,
      toDateTime: null,

      messagesFromDateTime: null,
      messagesToDateTime: null,
    };

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    this.onGetDailyCallsAction = this.onGetDailyCallsAction.bind(this);
    this.onGetDailyCallsResponseAction = this.onGetDailyCallsResponseAction.bind(this);
    this.onGetDailyHandledCallsAction = this.onGetDailyHandledCallsAction.bind(this);
    this.onGetFamilyMembersAction = this.onGetFamilyMembersAction.bind(this);
    this.onGetMessagesReportAction = this.onGetMessagesReportAction.bind(this);
    this.onGetFamilyCallsAction = this.onGetFamilyCallsAction.bind(this);
    this.onGetMediaItemsPerDayReportAction = this.onGetMediaItemsPerDayReportAction.bind(this);

    this.handleExportCallLogs = this.handleExportCallLogs.bind(this);
    this.handleExportDailyRequests = this.handleExportDailyRequests.bind(this);
    this.handleExportMessages = this.handleExportMessages.bind(this);

    this.controlTimezone = 0;

    this.timeFormat = '';

    this.requestCallFromDateTimeRef = React.createRef();
    this.requestCallToDateTimeRef = React.createRef();

    this.fromDateTimeRef = React.createRef();
    this.toDateTimeRef = React.createRef();

    this.messagesFromDateTimeRef = React.createRef();
    this.messagesToDateTimeRef = React.createRef();
  }

  componentDidMount() {
    //Register Listener
    UserStore.on('onGetDailyCallsAction ', this.onGetDailyCallsAction);
    UserStore.on('onGetDailyCallsResponse', this.onGetDailyCallsResponseAction);
    UserStore.on('onGetDailyHandledCallsAction', this.onGetDailyHandledCallsAction);
    UserStore.on('onGetFamilyMembers', this.onGetFamilyMembersAction);
    UserStore.on('onGetMessagesReport', this.onGetMessagesReportAction);
    UserStore.on('onGetFamilyCalls', this.onGetFamilyCallsAction);
    UserStore.on('onGetMediaItemsPerDayReport', this.onGetMediaItemsPerDayReportAction);

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      this.timeFormat = 'MM/DD/YYYY hh:mm A';
      this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    } else {
      this.timeFormat = 'MM/DD/YYYY HH:mm';
      this.timeOptions = { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' };
    }

    //Only load Account related information if it has a valid AccountID

    if (AuthStore.getUserAccountID()) {
      UserStore.getDailyCalls({
        AccountID: AuthStore.getUserAccountID(),
      });

      UserStore.getDailyHandledCalls({
        AccountID: AuthStore.getUserAccountID(),
      });

      UserStore.getFamilyMembers({
        AccountID: AuthStore.getUserAccountID(),
      });

      UserStore.getMessagesReport({
        AccountID: AuthStore.getUserAccountID(),
      });

      UserStore.getFamilyCalls({
        AccountID: AuthStore.getUserAccountID(),
      });

      UserStore.getMediaItemsPerDayReport({
        AccountID: AuthStore.getUserAccountID(),
      });
    }

    const currentDate = new Date();
    //let firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const firstDay = new Date();
    firstDay.setDate(firstDay.getDate() - 30);

    console.log('DATE:', currentDate, firstDay);

    const currentTimezone = (currentDate.getTimezoneOffset() / 60) * -1;
    console.log('TIMEZONE:', currentTimezone);
    //console.log("TIMEZONE FORMAT:", currentDate.toString().match(/([-\+][0-9]+)\s/)[1]);
    this.controlTimezone = currentTimezone;
    console.log('CONTROL TIMEZONE:', this.controlTimezone);

    this.setState({
      requestCallFromDateTime: new Date(firstDay),
      requestCallToDateTime: new Date(currentDate),

      fromDateTime: firstDay,
      toDateTime: currentDate,

      messagesFromDateTime: firstDay,
      messagesToDateTime: currentDate,
    });
  }

  componentWillUnmount() {
    //Remove Listener
    UserStore.removeListener('onGetDailyCallsAction', this.onGetDailyCallsAction);
    UserStore.removeListener('onGetDailyCallsResponse', this.onGetDailyCallsResponseAction);
    UserStore.removeListener('onGetDailyHandledCallsAction', this.onGetDailyHandledCallsAction);
    UserStore.removeListener('onGetFamilyMembers', this.onGetFamilyMembersAction);
    UserStore.removeListener('onGetMessagesReport', this.onGetMessagesReportAction);

    UserStore.removeListener('onGetFamilyCalls', this.onGetFamilyCallsAction);
    UserStore.removeListener('onGetMediaItemsPerDayReport', this.onGetMediaItemsPerDayReportAction);
  }

  onRequestCallFromDateChange = (date, value) => {
    console.log('onRequestCallFromDateChange', date, value);
    this.setState({
      requestCallFromDateTime: value,
    });
  };

  onRequestCallToDateChange = (date, value) => {
    console.log('onRequestCallToDateChange', date, value);
    this.setState({
      requestCallToDateTime: value,
    });
  };

  onFromDateChange = (date, value) => {
    console.log('onFromDateChange', date, value);
    this.setState({
      fromDateTime: value,
    });
  };

  onToDateChange = (date, value) => {
    console.log('onToDateChange', date, value);
    this.setState({
      toDateTime: value,
    });
  };

  onMessagesFromDateChange = (date, value) => {
    console.log('onMessagesFromDateChange', date, value);
    this.setState({
      messagesFromDateTime: value,
    });
  };

  onMessagesToDateChange = (date, value) => {
    console.log('onMessagesToDateChange', date, value);
    this.setState({
      messagesToDateTime: value,
    });
  };

  onGetDailyCallsResponseAction(response) {
    console.log('onGetDailyCallsResponseAction', response);
    this.drawChartCallsRequested(response.calls);
  }

  onGetDailyCallsAction(data) {
    console.log('onGetDailyCallsAction', data);
    // this.setState({
    //     chartData1 : data.calls
    // });
    this.drawChartCallsRequested(data.calls);
  }

  onGetDailyHandledCallsAction(data) {
    // this.setState({
    //     chartData2 : data.calls
    // });
    this.drawChartCallsHandled(data.calls);
  }

  onGetFamilyMembersAction(data) {
    console.log('onGetFamilyMembersAction', data);

    if (data.ok) {
      this.drawChartFamilyMembers(data.active, data.pending);
    }
  }

  onGetMessagesReportAction(data) {
    console.log('onGetMessagesReportAction', data);

    if (data.ok) {
      this.drawChartMessagesReport(data.Data);
    }
  }

  onGetFamilyCallsAction(data) {
    console.log('onGetFamilyCallsAction', data);

    if (data.ok) {
      this.drawChartFamilyCalls(data.calls);
    }
  }

  onGetMediaItemsPerDayReportAction(data) {
    console.log('onGetMediaItemsPerDayReportAction', data);

    if (data.ok) {
      this.drawChartMediaBoxItems(data.MemoryBox);
    }
  }

  // TODO: AmCharts Documentation
  // https://docs.amcharts.com/3/javascriptcharts/AmPieChart
  drawChartCallsRequested(calls) {
    //console.log("drawChartCallsRequested", calls);
    AmCharts.makeChart('chartdiv', {
      type: 'serial',
      addClassNames: true,
      theme: 'light',
      autoMargins: false,
      marginLeft: 30,
      marginRight: 8,
      marginTop: 10,
      marginBottom: 26,
      balloon: {
        adjustBorderColor: false,
        horizontalPadding: 10,
        verticalPadding: 8,
        color: '#ffffff',
      },
      dataProvider: calls,
      valueAxes: [
        {
          axisAlpha: 0,
          position: 'left',
        },
      ],
      startDuration: 0,
      graphs: [
        {
          id: 'graph1',
          balloonText: "<span style='font-size:12px;'>[[title]] on [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          bullet: 'round',
          lineThickness: 2,
          bulletSize: 4,
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          lineColor: '#FA6500',
          useLineColorForBulletBorder: true,
          bulletBorderThickness: 3,
          fillAlphas: 0,
          lineAlpha: 1,
          title: 'Emergency requests',
          valueField: 'emergency',
          dashLengthField: 'dashLengthLine',
        },
        {
          id: 'graph2',
          balloonText: "<span style='font-size:12px;'>[[title]] on [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          bullet: 'round',
          lineThickness: 2,
          bulletSize: 4,
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          useLineColorForBulletBorder: true,
          bulletBorderThickness: 3,
          fillAlphas: 0,
          lineAlpha: 1,
          title: 'Call requests',
          valueField: 'request',
          dashLengthField: 'dashLengthLine',
        },
      ],
      categoryField: 'date',
      categoryAxis: {
        gridPosition: 'start',
        axisAlpha: 0,
        tickLength: 0,
        parseDates: true,
      },
      export: {
        enabled: true,
      },
    });
  }

  drawChartCallsHandled(calls) {
    //console.log("drawChartCallsHandled", calls);
    AmCharts.makeChart('chartdiv2', {
      type: 'serial',
      addClassNames: true,
      theme: 'light',
      autoMargins: false,
      marginLeft: 30,
      marginRight: 8,
      marginTop: 10,
      marginBottom: 26,
      balloon: {
        adjustBorderColor: false,
        horizontalPadding: 10,
        verticalPadding: 8,
        color: '#ffffff',
      },
      dataProvider: calls,
      valueAxes: [
        {
          axisAlpha: 0,
          position: 'left',
        },
      ],
      startDuration: 0,
      graphs: [
        {
          id: 'graph1',
          balloonText: "<span style='font-size:12px;'>[[title]] on [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          bullet: 'round',
          lineThickness: 2,
          bulletSize: 4,
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          lineColor: '#FA6500',
          useLineColorForBulletBorder: true,
          bulletBorderThickness: 3,
          fillAlphas: 0,
          lineAlpha: 1,
          title: 'Calls not answered',
          valueField: 'not_answered',
          dashLengthField: 'dashLengthLine',
        },
        {
          id: 'graph2',
          balloonText: "<span style='font-size:12px;'>[[title]] on [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          bullet: 'round',
          lineThickness: 2,
          bulletSize: 4,
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          useLineColorForBulletBorder: true,
          bulletBorderThickness: 3,
          fillAlphas: 0,
          lineAlpha: 1,
          title: 'Calls answered',
          valueField: 'answered',
          dashLengthField: 'dashLengthLine',
        },
      ],
      categoryField: 'date',
      categoryAxis: {
        gridPosition: 'start',
        axisAlpha: 0,
        tickLength: 0,
        parseDates: true,
      },
      export: {
        enabled: true,
      },
    });
  }

  drawChartFamilyMembers(active, pending) {
    console.log('drawChartFamilyMembers');
    console.log(active, pending);

    AmCharts.makeChart('chartFamilyMembers', {
      type: 'pie',
      theme: 'light',
      dataProvider: [
        {
          title: 'Active',
          value: active,
        },
        {
          title: 'Pending',
          value: pending,
        },
      ],
      titleField: 'title',
      valueField: 'value',
      labelRadius: 5,
      startDuration: 0,

      radius: '42%',
      innerRadius: '60%',
      labelText: '[[title]]',
      export: {
        enabled: true,
      },
      colors: ['#018FFF', '#FFCD01'],
    });
  }

  drawChartMessagesReport(messages) {
    //console.log("drawChartMessagesReport", messages);
    AmCharts.makeChart('chartMessages', {
      type: 'serial',
      addClassNames: true,
      theme: 'light',
      autoMargins: false,
      marginLeft: 30,
      marginRight: 8,
      marginTop: 10,
      marginBottom: 26,
      balloon: {
        adjustBorderColor: false,
        horizontalPadding: 10,
        verticalPadding: 8,
        color: '#ffffff',
      },
      dataProvider: messages,
      valueAxes: [
        {
          axisAlpha: 0,
          position: 'left',
        },
      ],
      startDuration: 0,
      graphs: [
        {
          id: 'graph1',
          balloonText: "<span style='font-size:12px;'>[[title]] on [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          bullet: 'round',
          lineThickness: 2,
          bulletSize: 4,
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          lineColor: '#FA6500',
          useLineColorForBulletBorder: true,
          bulletBorderThickness: 3,
          fillAlphas: 0,
          lineAlpha: 1,
          title: 'Messages not read',
          valueField: 'MessageNotRead',
          dashLengthField: 'dashLengthLine',
        },
        {
          id: 'graph2',
          balloonText: "<span style='font-size:12px;'>[[title]] on [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          bullet: 'round',
          lineThickness: 2,
          bulletSize: 4,
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          useLineColorForBulletBorder: true,
          bulletBorderThickness: 3,
          fillAlphas: 0,
          lineAlpha: 1,
          title: 'Messages read',
          valueField: 'MessageRead',
          dashLengthField: 'dashLengthLine',
        },
      ],
      categoryField: 'date',
      categoryAxis: {
        gridPosition: 'start',
        axisAlpha: 0,
        tickLength: 0,
        parseDates: true,
      },
      export: {
        enabled: true,
      },
    });
  }

  drawChartFamilyCalls(calls) {
    //console.log("drawChartFamilyCalls", calls);
    AmCharts.makeChart('chartFamilyCalls', {
      type: 'serial',
      addClassNames: true,
      theme: 'light',
      autoMargins: false,
      marginLeft: 30,
      marginRight: 8,
      marginTop: 10,
      marginBottom: 26,
      balloon: {
        adjustBorderColor: false,
        horizontalPadding: 10,
        verticalPadding: 8,
        color: '#ffffff',
      },
      dataProvider: calls,
      valueAxes: [
        {
          axisAlpha: 0,
          position: 'left',
        },
      ],
      startDuration: 0,
      graphs: [
        {
          id: 'graph1',
          balloonText: "<span style='font-size:12px;'>[[title]] on [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          bullet: 'round',
          lineThickness: 2,
          bulletSize: 4,
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          lineColor: '#FA6500',
          useLineColorForBulletBorder: true,
          bulletBorderThickness: 3,
          fillAlphas: 0,
          lineAlpha: 1,
          title: 'Calls failed',
          valueField: 'calls_fail',
          dashLengthField: 'dashLengthLine',
        },
        {
          id: 'graph2',
          balloonText: "<span style='font-size:12px;'>[[title]] on [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          bullet: 'round',
          lineThickness: 2,
          bulletSize: 4,
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          useLineColorForBulletBorder: true,
          bulletBorderThickness: 3,
          fillAlphas: 0,
          lineAlpha: 1,
          title: 'Calls made',
          valueField: 'calls_success',
          dashLengthField: 'dashLengthLine',
        },
      ],
      categoryField: 'date',
      categoryAxis: {
        gridPosition: 'start',
        axisAlpha: 0,
        tickLength: 0,
        parseDates: true,
      },
      export: {
        enabled: true,
      },
    });
  }

  drawChartMediaBoxItems(mediaPerDayArray) {
    AmCharts.makeChart('chartDailyPhotosUploaded', {
      type: 'serial',
      addClassNames: true,
      theme: 'light',
      autoMargins: false,
      marginLeft: 30,
      marginRight: 8,
      marginTop: 10,
      marginBottom: 26,
      balloon: {
        adjustBorderColor: false,
        horizontalPadding: 10,
        verticalPadding: 8,
        color: '#ffffff',
      },
      dataProvider: mediaPerDayArray,
      valueAxes: [
        {
          axisAlpha: 0,
          position: 'left',
        },
      ],
      startDuration: 0,
      graphs: [
        {
          id: 'graph1',
          balloonText: "<span style='font-size:12px;'>[[title]] on [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          bullet: 'round',
          lineThickness: 2,
          bulletSize: 4,
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          lineColor: '#018FFF',
          useLineColorForBulletBorder: true,
          bulletBorderThickness: 3,
          fillAlphas: 0,
          lineAlpha: 1,
          title: 'Photos uploaded',
          valueField: 'Count',
          dashLengthField: 'dashLengthLine',
        },
      ],
      categoryField: 'date',
      categoryAxis: {
        gridPosition: 'start',
        axisAlpha: 0,
        tickLength: 0,
        parseDates: true,
      },
      export: {
        enabled: true,
      },
    });
  }

  // validateDate(date, obj){

  //     let fromDate = this.refs.fromDateTime.value;
  //     let toDate = this.refs.toDateTime.value;

  //     alert(fromDate);
  //     alert(toDate);
  //     return false;
  //     // if(new Date(checkInDate) < new Date(date)){
  //     //     obj.addClass("invalid-field");
  //     //     // alert("CheckIn date should be greater than the Assignment Date");
  //     //     Materialize.toast('CheckIn date should be greater than the Assignment Date', 4000) // 4000 is the duration of the toast
  //     //     this.setState({
  //     //         editAssignmentBtnStyle : 'nucleus-submit-btn-disabled'
  //     //     })
  //     //     return false;
  //     // }else{
  //     //     console.log("removing class");
  //     //     obj.removeClass("invalid-field");
  //     //     this.setState({
  //     //         assignmentUpdateAction :  true,
  //     //         editAssignmentBtnStyle : 'nucleus-submit-btn'
  //     //     });
  //     //     return true;
  //     // }
  // }

  handleExportDailyRequests() {
    const fromDateString = this.state.requestCallFromDateTime;
    const toDateString = this.state.requestCallToDateTime;

    const fromDate = new Date(fromDateString);
    const toDate = new Date(toDateString);

    if (!fromDateString || !toDateString) {
      let errorMsg = !fromDateString ? 'Please enter the From Date' : 'Please enter the To Date';

      if (toDate < fromDate) {
        errorMsg = 'The From Date has to be before the To Date.';
      }
      this.setState({
        datesRequestsErrorVisible: ' ',
        datesRequestsErrorText: errorMsg,
      });
      return;
    }

    fromDate.setHours(fromDate.getHours() + this.controlTimezone * -1);
    toDate.setHours(toDate.getHours() + this.controlTimezone * -1);

    const fromDateParam = fromDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + fromDate.toLocaleTimeString('en-US', this.timeOptions);
    const toDateParam = toDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + toDate.toLocaleTimeString('en-US', this.timeOptions);

    //console.log(Config.rootDomain + "/csv/ExportCallLogs.aspx?AccountID=" + AuthStore.getUserAccountID());
    const URL = Config.rootDomain + '/csv/ExportDailyRequests.aspx?AccountID=' + AuthStore.getUserAccountID() + '&FromDateTime=' + fromDateParam + '&ToDateTime=' + toDateParam;
    window.open(URL, 'Download');
  }

  handleExportCallLogs() {
    if (!(this.state.fromDateTime && this.state.fromDateTime.toString().length > 0)) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: 'Please enter the From Date',
      });
      return;
    }

    if (!(this.state.toDateTime && this.state.toDateTime.toString().length > 0)) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: 'Please enter the To Date',
      });
      return;
    }

    const fromDate = new Date(this.state.fromDateTime);
    const toDate = new Date(this.state.toDateTime);

    if (toDate < fromDate) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: 'The From Date has to be before the To Date.',
      });
      return;
    }

    fromDate.setHours(fromDate.getHours() + this.controlTimezone * -1);
    toDate.setHours(toDate.getHours() + this.controlTimezone * -1);

    const fromDateParam = fromDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + fromDate.toLocaleTimeString('en-US', this.timeOptions);
    const toDateParam = toDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + toDate.toLocaleTimeString('en-US', this.timeOptions);

    //console.log(Config.rootDomain + "/csv/ExportCallLogs.aspx?AccountID=" + AuthStore.getUserAccountID());
    const URL = Config.rootDomain + '/csv/ExportCallLogs.aspx?AccountID=' + AuthStore.getUserAccountID() + '&FromDateTime=' + fromDateParam + '&ToDateTime=' + toDateParam;
    window.open(URL, 'Download');
  }

  handleExportMessages() {
    console.log('handleExportMessages');
    const fromDateString = this.state.messagesFromDateTime;
    const toDateString = this.state.messagesToDateTime;

    const fromDate = new Date(fromDateString);
    const toDate = new Date(toDateString);

    if (!fromDateString || !toDateString) {
      let errorMsg = !fromDateString ? 'Please enter the From Date' : 'Please enter the To Date';

      if (toDate < fromDate) {
        errorMsg = 'The From Date has to be before the To Date.';
      }
      this.setState({
        datesMessagesErrorVisible: ' ',
        datesMessagesErrorText: errorMsg,
      });
      return;
    }

    fromDate.setHours(fromDate.getHours() + this.controlTimezone * -1);
    toDate.setHours(toDate.getHours() + this.controlTimezone * -1);

    const fromDateParam = fromDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + fromDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    const toDateParam = toDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + toDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });

    //console.log(Config.rootDomain + "/csv/ExportCallLogs.aspx?AccountID=" + AuthStore.getUserAccountID());
    const URL = Config.rootDomain + '/csv/ExportMessages.aspx?AccountID=' + AuthStore.getUserAccountID() + '&FromDateTime=' + fromDateParam + '&ToDateTime=' + toDateParam;
    window.open(URL, 'Download');
  }

  render() {
    return (
      <div className="nucleus-dashboard-news-pending">
        {/* <DashboardContentInfo/> */}

        <div className="row big-space-content-disabled no-margin">
          <div className="col s6 left-align line-form" style={{ paddingRight: 50 }}>
            <div className="row no-margin">
              <div className="col s6 no-margin left-align">
                <span className="nucleus-labels">From:</span> &nbsp;
                {/* <input 
                                    autoComplete="off" 
                                    id="requestCallFromDateTime" 
                                    ref="requestCallFromDateTime" 
                                    type="text" 
                                    className="validate nucleus-date-picker-short" /> */}
                <div style={{ width: 210, display: 'inline-flex' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      //ref={this.requestCallFromDateTimeRef}
                      variant="dialog"
                      className="browser-default nucleusDateTimePickerD"
                      value={this.state.requestCallFromDateTime}
                      onChange={this.onRequestCallFromDateChange}
                      onError={console.log}
                      onClose={() => {}}
                      //minDate={new Date("2018-01-01T00:00")}
                      format={this.timeFormat}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="col s6 no-margin right-align">
                <span className="nucleus-labels">To:</span> &nbsp;
                {/* <input 
                                    autoComplete="off" 
                                    id="requestCallToDateTime" 
                                    ref="requestCallToDateTime" 
                                    type="text" 
                                    className="validate nucleus-date-picker-short" /> */}
                <div style={{ width: 210, display: 'inline-flex' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      //ref={this.requestCallToDateTimeRef}
                      variant="dialog"
                      className="browser-default"
                      value={this.state.requestCallToDateTime}
                      onChange={this.onRequestCallToDateChange}
                      onError={console.log}
                      onClose={() => {}}
                      //minDate={new Date("2018-01-01T00:00")}
                      format={this.timeFormat}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            <div className="row no-margin">
              <div className="col s12 no-margin right-align padding-btn">
                <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={this.handleExportDailyRequests}>
                  Export Call Requests
                </a>
                &nbsp;
                <br />
                <span className={'nucleus-form-error center-align ' + this.state.datesRequestsErrorVisible}>{this.state.datesRequestsErrorText}</span>
              </div>
            </div>
          </div>

          <div className="col s6 left-align line-form" style={{ paddingRight: 50 }}>
            <div className="row no-margin">
              <div className="col s6 no-margin left-align">
                <span className="nucleus-labels">From:</span> &nbsp;
                {/* <input 
                                    autoComplete="off" 
                                    id="fromDateTime" 
                                    ref="fromDateTime" 
                                    type="text" 
                                    className="validate nucleus-date-picker-short" /> */}
                <div style={{ width: 210, display: 'inline-flex' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      //ref={this.fromDateTimeRef}
                      variant="dialog"
                      className="browser-default"
                      value={this.state.fromDateTime}
                      onChange={this.onFromDateChange}
                      onError={console.log}
                      onClose={() => {}}
                      //minDate={new Date("2018-01-01T00:00")}
                      format={this.timeFormat}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="col s6 no-margin right-align">
                <span className="nucleus-labels">To:</span> &nbsp;
                {/* <input 
                                    autoComplete="off" 
                                    id="toDateTime" 
                                    ref="toDateTime" 
                                    type="text" 
                                    className="validate nucleus-date-picker-short" /> */}
                <div style={{ width: 210, display: 'inline-flex' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      //ref={this.toDateTimeRef}
                      variant="dialog"
                      className="browser-default"
                      value={this.state.toDateTime}
                      onChange={this.onToDateChange}
                      onError={console.log}
                      onClose={() => {}}
                      //minDate={new Date("2018-01-01T00:00")}
                      format={this.timeFormat}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            <div className="row no-margin">
              <div className="col s12 no-margin right-align padding-btn">
                {/* <a className={"txt-white nucleus-font-small nucleus-submit-btn-small"} onClick={this.handleExportCallLogs}>Export Call Logs</a>&nbsp; */}
                <br />
                <span className={'nucleus-form-error center-align ' + this.state.datesErrorVisible}>{this.state.datesErrorText}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row content">
          <div className="col s6 center-align">
            <div className="nucleus-chart center-align">
              <div className="nucleus-chart-title valign-wrapper">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Daily Call Requests</span>
              </div>
              <div id="chartdiv" className="nucleus-chart-body"></div>
              <p className="nucleus-font nucleus-font-small left-align">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="chart-yellow"> &bull; Call Requests</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="chart-orange"> &bull; Emergency Requests</span>
              </p>
            </div>
          </div>
          <div className="col s6 center-align">
            <div className="nucleus-chart center-align">
              <div className="nucleus-chart-title valign-wrapper">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Daily Calls Made</span>
              </div>
              <div id="chartdiv2" className="nucleus-chart-body"></div>
              <p className="nucleus-font nucleus-font-small left-align">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="chart-yellow"> &bull; Calls Answered</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="chart-orange"> &bull; Calls Not Answered</span>
              </p>
            </div>
          </div>
        </div>

        <br />
        <div className="row big-space-content-disabled no-margin">
          <div className="col s6 left-align line-form">&nbsp;</div>
          <div className="col s6 left-align line-form" style={{ paddingRight: 50 }}>
            <div className="row no-margin">
              <div className="col s6 no-margin left-align">
                <span className="nucleus-labels">From:</span> &nbsp;
                {/* <input 
                                    autoComplete="off" 
                                    id="messagesFromDateTime" 
                                    ref="messagesFromDateTime" 
                                    type="text" 
                                    className="validate nucleus-date-picker-short" /> */}
                <div style={{ width: 210, display: 'inline-flex' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      //ref={this.messagesFromDateTimeRef}
                      variant="dialog"
                      className="browser-default"
                      value={this.state.messagesFromDateTime}
                      onChange={this.onMessagesFromDateChange}
                      onError={console.log}
                      onClose={() => {}}
                      //minDate={new Date("2018-01-01T00:00")}
                      format={this.timeFormat}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="col s6 no-margin right-align">
                <span className="nucleus-labels">To:</span> &nbsp;
                {/* <input 
                                    autoComplete="off" 
                                    id="messagesToDateTime" 
                                    ref="messagesToDateTime" 
                                    type="text" 
                                    className="validate nucleus-date-picker-short" /> */}
                <div style={{ width: 210, display: 'inline-flex' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      //ref={this.messagesToDateTimeRef}
                      variant="dialog"
                      className="browser-default"
                      value={this.state.messagesToDateTime}
                      onChange={this.onMessagesToDateChange}
                      onError={console.log}
                      onClose={() => {}}
                      //minDate={new Date("2018-01-01T00:00")}
                      format={this.timeFormat}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            <div className="row no-margin">
              <div className="col s12 no-margin right-align padding-btn">
                <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={this.handleExportMessages}>
                  Export Messages
                </a>
                &nbsp;
                <br />
                <span className={'nucleus-form-error center-align ' + this.state.datesMessagesErrorVisible}>{this.state.datesMessagesErrorText}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row content">
          <div className="col s6 center-align">
            <div className="nucleus-chart center-align">
              <div className="nucleus-chart-title valign-wrapper">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Family Members</span>
              </div>
              <div id="chartFamilyMembers" className="nucleus-chart-body"></div>
              <p className="nucleus-font nucleus-font-small left-align">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="color-nucleus-blue"> &bull; Active Family Members</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="color-nucleus-yellow"> &bull; Pending Family Members</span>
              </p>
            </div>
          </div>
          <div className="col s6 center-align">
            <div className="nucleus-chart center-align">
              <div className="nucleus-chart-title valign-wrapper">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Daily Messages</span>
              </div>
              <div id="chartMessages" className="nucleus-chart-body"></div>
              <p className="nucleus-font nucleus-font-small left-align">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="chart-yellow "> &bull;Messages Read</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="chart-orange"> &bull;Messages Not Read</span>
              </p>
            </div>
          </div>
        </div>

        <div className="row content">
          <div className="col s6 center-align">
            <div className="nucleus-chart center-align">
              <div className="nucleus-chart-title valign-wrapper">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Family Call logs</span>
              </div>
              <div id="chartFamilyCalls" className="nucleus-chart-body"></div>
              <p className="nucleus-font nucleus-font-small left-align">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="chart-yellow "> &bull; Calls Made</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="chart-orange"> &bull; Calls Failed</span>
              </p>
            </div>
          </div>
          <div className="col s6 center-align">
            <div className="nucleus-chart center-align">
              <div className="nucleus-chart-title valign-wrapper">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="nucleus-font-family-medium nucleus-font-medium nucleus-chart-title-text">Daily Photo upload per Day</span>
              </div>
              <div id="chartDailyPhotosUploaded" className="nucleus-chart-body"></div>
              <p className="nucleus-font nucleus-font-small left-align">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="color-nucleus-blue"> &bull;Photos Uploaded</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardContent;

import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import axios from 'axios';
import httpClient from './httpClient';
import { UserImpersonation } from './UserImpersonation';



function handleError(error) {
  const { response = {} } = error;
  const url = response.config?.url;
  if (response.status === 401 && url !== '/user/me') {
    return (window.location.href = '/login');
  }
  return Promise.reject(error);
}


// Create interceptor to handle impersonation

httpApi.interceptors.request.use(config => {
  const accountId = UserImpersonation.getImpersonatingAccount();
  if (accountId) {
    config.headers['x-account-id'] = accountId;
  }
  return config;
});

export function configureUnauthorizedInterceptor() {
  axios.interceptors.response.use(response => response, handleError);
  httpApi.interceptors.response.use(response => response, handleError);
  httpClient.interceptors.response.use(response => response, handleError);
}

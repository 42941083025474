import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import styled from 'styled-components';
import { UIModal } from '../../../../components/UI';
import authStore from '../../../../stores/AuthStore';
import { ProviderUser } from '../types';
const Title = styled.p`
  color: #f00;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;
const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 20px;
`;
const Name = styled.p`
  color: #0a313f;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
`;
interface DeleteProviderUser {
  closeDeleteModal: () => void;
  providerUser: ProviderUser | null;
  deleteModalActive: boolean;
}
const DeleteProviderUser = ({ closeDeleteModal, providerUser, deleteModalActive }: DeleteProviderUser) => {
  const deleteProviderUser = () => {
    const providerId = authStore.getProviderID();
    httpApi
      .delete(`providers/${providerId}/users/${providerUser?.id}`)
      .then(response => {
        closeDeleteModal();
      })
      .catch(error => {
        console.log('Error deleting provider user: ', error);
      });
  };
  return (
    <UIModal
      actions={[
        {
          label: 'Cancel',
          onClick: closeDeleteModal,
          buttonVariant: 'secondary',
        },
        {
          label: 'Proceed',
          onClick: deleteProviderUser,
          buttonVariant: 'primary',
        },
      ]}
      close={closeDeleteModal}
      isOpen={deleteModalActive}
      title={''}
      size="medium"
    >
      <Title>WARNING!</Title>
      <Note>You are about to delete</Note>
      <Name>Provider User: {providerUser?.name}</Name>
      <Note>this action is permanent</Note>
      <Note>Would you like to proceed?</Note>
    </UIModal>
  );
};

export default DeleteProviderUser;

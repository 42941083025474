//@ts-nocheck
import classNames from 'classnames';
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { Link } from 'react-router-dom';
import TelephoneInputUnderline from '../../components/UI/Inputs/TelephoneInputUnderlined';
import AuthStore from '../../stores/AuthStore';
import CarePatientStore from '../../stores/CarePatientStore';
import GeneralStore from '../../stores/GeneralStore';
import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';

class NewFamilyMember extends React.Component {
  constructor() {
    super();

    validateAccessGranted('/newFamilyMember');

    this.state = {
      panel: 'Form',
      newFamilyMemberFullName: '',
      btnStyle: 'disabled',
      errorEmailVisibility: 'hide',
      errorEmailText: 'Email Address already registered',
      errorTelephoneVisibility: 'hide',
      errorTelephoneText: 'Telephone already registered',
      familyMemberExists: false,
      familyMemberID: '',
      familyMemberFirstName: '',
      familyMemberLastName: '',
      familyMemberEmail: '',
      familyMemberTelephone: '',
      familyMemberAddress: '',
    };

    this.submitFamilyMemberForm = this.submitFamilyMemberForm.bind(this);
    this.onCreateNewFamilyMemberAction = this.onCreateNewFamilyMemberAction.bind(this);
    this.validateFormValues = this.validateFormValues.bind(this);

    this.validateEnteredTelephone = this.validateEnteredTelephone.bind(this);
    this.onCheckExistingTelephoneAction = this.onCheckExistingTelephoneAction.bind(this);
    this.handleCancelExistingFamilyMember = this.handleCancelExistingFamilyMember.bind(this);
    this.handleAddExistingFamilyMember = this.handleAddExistingFamilyMember.bind(this);
    this.onPatientMemberAssignedAction = this.onPatientMemberAssignedAction.bind(this);
  }

  componentDidMount() {
    CarePatientStore.on('onCreateNewFamilyMember', this.onCreateNewFamilyMemberAction);
    CarePatientStore.on('onCheckExistingTelephone', this.onCheckExistingTelephoneAction);
    CarePatientStore.on('onPatientMemberAssigned', this.onPatientMemberAssignedAction);

    $('.modalNewFamilyMember').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
  }

  componentWillUnmount() {
    CarePatientStore.removeListener('onCreateNewFamilyMember', this.onCreateNewFamilyMemberAction);
    CarePatientStore.removeListener('onCheckExistingTelephone', this.onCheckExistingTelephoneAction);
    CarePatientStore.removeListener('onPatientMemberAssigned', this.onPatientMemberAssignedAction);
  }

  validateFormValues() {
    if (this.refs.txtNewFamilyMemberFirstName.value.length > 0 && this.refs.txtNewFamilyMemberLastName.value.length > 0 && this.state.familyMemberTelephone.length > 0) {
      this.setState({
        btnStyle: '',
      });
    } else {
      this.setState({
        btnStyle: 'disabled',
      });
    }
    if (this.state.familyMemberTelephone.length > 0) {
      this.setState({
        errorTelephoneVisibility: 'hide',
      });
    }
  }

  onTelephoneInputChange = number => {
    this.state.familyMemberTelephone = number;
    if (number.length > 0) {
      this.setState({
        btnStyle: '',
        errorTelephoneVisibility: 'hide',
      });
    } else {
      this.setState({
        btnStyle: 'disabled',
      });
    }
    this.validateFormValues();
  };

  submitFamilyMemberForm() {
    const { match } = this.props;
    console.log('submitFamilyMemberForm');
    if (this.refs.txtNewFamilyMemberEmail.value.length > 0 && !/^[\w.+-]+@\w+([.-]?\w+)*(\.\w{2,15})+$/.test(this.refs.txtNewFamilyMemberEmail.value)) {
      Message.show('Invalid Email.');
      this.refs.txtNewFamilyMemberEmail.focus();
      return;
    }

    if (this.refs.txtNewFamilyMemberFirstName.value == '') {
      Message.show('FirstName is required.');
      return;
    }

    if (this.refs.txtNewFamilyMemberLastName.value === '') {
      Message.show('LastName is required.');
      return;
    }

    let familyMemberTelephone = this.state.familyMemberTelephone;
    if (familyMemberTelephone.length > 0) {
      familyMemberTelephone = familyMemberTelephone.replace(/[()-\s]/g, '');
      if (familyMemberTelephone.length > 0 && familyMemberTelephone.length < 12) {
        this.setState({
          errorEmailVisibility: 'hide',
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Invalid Telephone. It must be at least 10 digits.',
        });
        return;
      }

      const containsLetter = /[a-zA-Z]/.test(familyMemberTelephone);
      if (containsLetter) {
        this.setState({
          errorEmailVisibility: 'hide',
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Invalid Telephone.',
        });
        return;
      }
    }
    const newFamilyMemberData = {
      PatientID: match.params.patientId,
      FirstName: this.refs.txtNewFamilyMemberFirstName.value,
      LastName: this.refs.txtNewFamilyMemberLastName.value,
      Email: this.refs.txtNewFamilyMemberEmail.value,
      Telephone: familyMemberTelephone,
      Address: this.refs.txtNewFamilyMemberAddress.value,
      ReceiveUpdates: true, // Deprecated Oct 13th 2017 CARE-1236 -> this.refs.checkReceiveUpdates.checked
    };
    this.setState({
      btnStyle: 'disabled',
    });
    console.log('New ', match.params.patientId);
    CarePatientStore.createNewFamilyMember(newFamilyMemberData);
  }

  onCreateNewFamilyMemberAction(response) {
    const { match } = this.props;
    console.log('onCreateNewFamilyMemberAction', response);
    if (response.ok) {
      // this.setState({
      //     panel : "Message",
      //     newFamilyMemberFullName : this.refs.txtNewFamilyMemberFirstName.value + ' ' + this.refs.txtNewFamilyMemberLastName.value
      // });

      // CarePatientStore.getAllPatientsData({
      //    UserID : AuthStore.getUserID()
      // });
      this.props.history.navigate('/patientProfile/' + match.params.patientId + '/null');
      setTimeout(function () {
        Message.show(AuthStore.getFamilyLabel() + ' created');
      }, 100);
    } else if (response.emailRegistered) {
      this.setState({
        errorEmailVisibility: '',
        errorTelephoneVisibility: 'hide',
        errorEmailText: 'Email Address already registered',
      });
    } else if (response.telephoneRegistered) {
      this.setState({
        errorEmailVisibility: 'hide',
        errorTelephoneVisibility: '',
        errorTelephoneText: 'Telephone already registered',
      });
    } else if (response.errorType == 1) {
      this.setState({
        errorEmailVisibility: '',
        errorTelephoneVisibility: 'hide',
        errorEmailText: response.error,
      });
    } else {
      this.setState({
        errorEmailVisibility: 'hide',
        errorTelephoneVisibility: '',
        errorTelephoneText: response.error,
      });
    }

    this.setState({
      btnStyle: '',
    });
  }

  validateEnteredTelephone() {
    console.log('validateEnteredTelephone');
    let familyMemberTelephone = this.state.familyMemberTelephone;
    if (familyMemberTelephone.length > 0) {
      familyMemberTelephone = familyMemberTelephone.replace(/[()-\s]/g, '');
      if (familyMemberTelephone.length < 12) {
        this.setState({
          errorEmailVisibility: 'hide',
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Invalid Telephone. It must be at least 10 digits.',
        });
        return;
      }
    }
    CarePatientStore.checkExistingTelephone({
      Telephone: familyMemberTelephone,
    });
  }

  onCheckExistingTelephoneAction(response) {
    console.log('onCheckExistingTelephoneAction');
    console.log(response);

    if (response.ok) {
      if (response.doesExist) {
        this.setState({
          familyMemberExists: true,
          familyMemberID: response.familyMemberID,
          familyMemberEmail: this.refs.txtNewFamilyMemberEmail.value,
          familyMemberFirstName: response.firstName,
          familyMemberLastName: response.lastName,
          familyMemberTelephone: response.telephone,
        });
        $('#modalExistingFamilyMember').modal('open');
      } else {
        this.setState({
          familyMemberExists: false,
        });
      }
    } else {
      Message.show('Error getting Information');
    }
  }

  handleCancelExistingFamilyMember() {
    $('#modalExistingFamilyMember').modal('close');
    GeneralStore.requestHandleBack();
  }

  handleAddExistingFamilyMember() {
    const { match } = this.props;
    CarePatientStore.assignPatientMember({
      FamilyMemberID: this.state.familyMemberID,
      PatientID: match.params.patientId,
    });
  }

  onPatientMemberAssignedAction(response) {
    const { match } = this.props;
    $('#modalExistingFamilyMember').modal('close');
    if (response.ok) {
      this.props.history.navigate('/patientProfile/' + match.params.patientId + '/null');
      setTimeout(function () {
        Message.show('Family Member assigned.');
      }, 100);
    } else {
      if (response.assigned) {
        this.props.history.navigate('/patientProfile/' + match.params.patientId + '/null');
        setTimeout(function () {
          Message.show('This ' + AuthStore.getFamilyLabel() + ' is already assigned to this ' + AuthStore.getPatientLabel() + '.');
        }, 100);
      } else {
        Message.show('Error assigning new Family Member');
      }
    }
  }

  render() {
    const { match } = this.props;
    const formVisibility = classNames({
      hide: this.state.panel != 'Form',
    });
    const messageVisibility = classNames({
      hide: this.state.panel != 'Message',
    });

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <p className="nucleus-page-title">New {AuthStore.getFamilyLabel()}</p>
          <br />
          <div class={'nucleus-patient-form-pending ' + formVisibility}>
            <br />
            <div className="row">
              <div className="col s2 m1 l1"></div>
              <div className="col s8 m8 l6">
                <div className="row">
                  <div className="col s4 left-align">
                    <span className=" nucleus-labels"> Telephone</span> <span className="nucleus-labels txt-red">*</span>
                  </div>
                  <div className="col s8">
                    <TelephoneInputUnderline
                      inputId="txtNewFamilyMemberTelephone"
                      fieldId="newPhone"
                      value=""
                      onChange={this.onTelephoneInputChange}
                      onBlur={this.validateEnteredTelephone}
                    />
                    <span class={'nucleus-form-error left-align txt-black-forced '}>Click the country code icon for more country codes</span>
                    <br />
                    <span class={'nucleus-form-error left-align ' + this.state.errorTelephoneVisibility}>{this.state.errorTelephoneText}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col s4 valgin-wrapper left-align">
                    <span className=" nucleus-labels"> Email</span>
                  </div>
                  <div className="col s8">
                    <input ref="txtNewFamilyMemberEmail" type="text" className="validate nucleus-input-form txtEmail" placeholder="Email" onChange={this.validateFormValues} />
                    <span class={'nucleus-form-error center-align ' + this.state.errorEmailVisibility}>{this.state.errorEmailText}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col s4 valgin-wrapper left-align">
                    <span className=" nucleus-labels"> First Name</span> <span className="nucleus-labels txt-red">*</span>
                  </div>
                  <div className="col s8">
                    <input ref="txtNewFamilyMemberFirstName" type="text" className="validate nucleus-input-form" placeholder="First Name" onChange={this.validateFormValues} />
                  </div>
                </div>

                <div className="row">
                  <div className="col s4 valgin-wrapper left-align">
                    <span className=" nucleus-labels"> Last Name</span> <span className="nucleus-labels txt-red">*</span>
                  </div>
                  <div className="col s8">
                    <input ref="txtNewFamilyMemberLastName" type="text" className="validate nucleus-input-form" placeholder="Last Name" onChange={this.validateFormValues} />
                  </div>
                </div>

                <div className="row">
                  <div className="col s4 left-align">
                    <span className=" nucleus-labels"> Address</span>
                  </div>
                  <div className="col s8">
                    <input
                      ref="txtNewFamilyMemberAddress"
                      type="text"
                      className="validate nucleus-input-form"
                      placeholder="1234 Street Lane, New City, NC 00000"
                      onChange={this.validateFormValues}
                    />
                  </div>
                </div>

                {/*
                // Deprecated Oct 13th 2017, CARE-1236
            <div className="row ">
                <div className="col s4 left-align">
                    <span className=" nucleus-labels"> Receive Updates</span> 
                </div>
                <div className="col s8">
                    <div className="switch">
                        <label >
                          <input ref="checkReceiveUpdates" type="checkbox"/>
                          <span className="lever"></span>
                        </label>
                      </div>

                </div>
            </div>
            
            <br/><br/><br/>

            <div className="row">
                <div className="col s4">
                </div>
                <div className="col s8 m8 l8 center-align">
                    <a class={"txt-white nucleus-font-medium " + this.state.btnStyle} onClick={this.submitFamilyMemberForm}>Submit</a>
                </div>
            </div>
            */}
              </div>
              <div className="col s2 m3 l5"></div>
            </div>

            <div className="fixed-action-btn btn-add-new">
              <a
                className="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped "
                disabled={this.state.btnStyle}
                data-position="left"
                data-tooltip="Save new Family Member"
                onClick={this.submitFamilyMemberForm}
                style={{ marginRight: 12 }}
              >
                <i className="material-icons">save</i>
              </a>
            </div>
          </div>

          <div ref="nucleus-new-patient-confirmation-pending" class={'nucleus-new-patient-form-pending ' + messageVisibility}>
            <br />
            <div className="row">
              <div className="col s12">
                <p className=" nucleus-labels"> Complete! The family member {this.state.newFamilyMemberFullName} has been created. </p>
                <Link to={'/patientProfile/' + match.params.patientId + '/null'}>
                  <span className="nucleus-link nucleus-font-medium">Go to {AuthStore.getPatientLabelPlural()} Page </span>
                </Link>
              </div>
            </div>
          </div>

          <div id="modalExistingFamilyMember" className="modal modalNewFamilyMember modalExistingFamilyMember">
            <div className="modal-content modal-content-delete">
              <h3 className="nucleus-page-subtitle"> Existing {AuthStore.getFamilyLabel()}</h3>
              <p></p>
              <div className="row">
                <div className="col s12 left-align nucleus-font-medium">
                  {'The ' +
                    AuthStore.getFamilyLabel() +
                    ' ' +
                    this.state.familyMemberFirstName +
                    ' ' +
                    this.state.familyMemberLastName +
                    ' with Phone ' +
                    this.state.familyMemberTelephone +
                    ' is already a ' +
                    AuthStore.getFamilyLabel() +
                    ' of an existing ' +
                    AuthStore.getPatientLabel().toLowerCase() +
                    '.'}
                  <br />
                  Do you want to add him/her to this patient as well?
                  <br />
                </div>
              </div>
              <div className="row ">
                <div className="col s12 right-align ">
                  <p></p>
                  <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelExistingFamilyMember}>
                    Cancel
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleAddExistingFamilyMember}>
                    Confirm
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default props => useReactRouterProps(NewFamilyMember, props);

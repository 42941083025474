//@ts-nocheck
import React from 'react';
import Config from '../../../Config';
import CarePatientStore from '../../../stores/CarePatientStore';
import GeneralStore from '../../../stores/GeneralStore';

import Message from '../../../utils/Message';
import nucleusNetwork, { ControlSecret } from '../../../utils/NucleusNetwork';

class PatientBiometricsSection extends React.Component<{
  patientID: string;
  accountID: string;
  enableBiometrics: boolean;
  patientDevices: any[];
  bloodPressureAlert: any;
  oxiMeterAlert: any;
  scaleAlert: any;
  temperatureAlert: any;
  bloodSugarAlert: any;
  sleepHoursAlert: any;
}> {
  constructor(props) {
    super(props);
    this.TAG = 'PatientBiometricsSection';
    this.state = {
      patientID: this.props.patientID,
      //bioDevicesTab : '',
      bioAlertsTab: '',
      pressureTab: 'staffTab',
      weightTab: '',
      temperatureTab: '',
      oximeterTab: '',
      sugarTab: '',
      sleepTab: '',

      //bioDevicesContent : ' ',
      bioAlertsContent: ' hide',
      pressureContent: ' ',
      weightContent: ' hide',
      temperatureContent: ' hide',
      oximeterContent: ' hide',
      sugarContent: ' hide',
      sleepContent: ' hide',

      enableBiometrics: false,

      patientDevicesArray: [],

      btnBloodPressureStyle: '',
      btnOxiMeterValueStyle: '',
      btnScaleAlertStyle: '',
      btnTemperatureStyle: '',
      btnBloodSugarStyle: '',
      btnHoursSleptStyle: '',

      pressureArray: [],
      weightArray: [],
      temperatureArray: [],
      oximeterArray: [],
      sugarBloodArray: [],
      sleepHoursArray: [],

      weightUnit: 'Lb',
      // DEPRECATED Nov 20th, 2019
      // https://nucleusintercom.atlassian.net/browse/CARE-6159
      //biometricDevicesArray : [],
      //availableBiometricDevicesArray : [],

      //addBiometricDeviceBtnTitle : "Assign Device",
      //addBiometricDeviceLabelMode : " ",
      //addBiometricDeviceControlsMode :  " hide",

      //btnNewBiometricDeviceStyle : "nucleus-submit-btn-small-disabled",
      //newBiometricDeviceMacAddress : "",

      //bioDeviceModalOptionLabel : "Remove",
      //bioDeviceModeEdit : '',
      //bioDeviceModeDelete : 'hide',
      //bioDeviceModalOptionColor : 'txt-red',

      //saveEditBioDeviceStyle : 'nucleus-submit-btn',
      //deleteBioDeviceBtnStyle : 'nucleus-submit-btn',
    };
    this.onRequestRefreshBiometricsAction = this.onRequestRefreshBiometricsAction.bind(this);

    // DEPRECATED Nov 20th, 2019
    // https://nucleusintercom.atlassian.net/browse/CARE-6159
    // this.handleBiometricDevicesTab = this.handleBiometricDevicesTab.bind(this);
    this.handleBiometricAlertsTab = this.handleBiometricAlertsTab.bind(this);
    this.handlePressureTab = this.handlePressureTab.bind(this);
    this.handleWeightTab = this.handleWeightTab.bind(this);
    this.handleTemperatureTab = this.handleTemperatureTab.bind(this);
    this.handleOximeterTab = this.handleOximeterTab.bind(this);
    this.handleSugarTab = this.handleSugarTab.bind(this);
    this.handleSleepTab = this.handleSleepTab.bind(this);

    this.onGetPatientBiometricDevicesAction = this.onGetPatientBiometricDevicesAction.bind(this);
    this.onGetUnAssignedBioDevicesAction = this.onGetUnAssignedBioDevicesAction.bind(this);

    // this.handlePanelAssignNewBiometricDevice = this.handlePanelAssignNewBiometricDevice.bind(this);
    // this.handleNewBiometricDeviceName = this.handleNewBiometricDeviceName.bind(this);
    // this.handleAssignNewBiometricDevice = this.handleAssignNewBiometricDevice.bind(this);
    // this.onAssignBiometricDeviceToPatientAction = this.onAssignBiometricDeviceToPatientAction.bind(this);

    // this.onOpenBiometricDeviceModalAction = this.onOpenBiometricDeviceModalAction.bind(this);

    // this.handleCloseEditBiometricDeviceModal = this.handleCloseEditBiometricDeviceModal.bind(this);
    // this.handleBiometricDeviceModalMode = this.handleBiometricDeviceModalMode.bind(this);
    // this.handleSaveBiometricDeviceEdited = this.handleSaveBiometricDeviceEdited.bind(this);
    // this.onBioDeviceUpdatedAction = this.onBioDeviceUpdatedAction.bind(this);
    // this.handleSubmitDeleteBiometricDevice = this.handleSubmitDeleteBiometricDevice.bind(this);
    // this.onBioDeviceUnassignedAction = this.onBioDeviceUnassignedAction.bind(this);

    //Biometrics Alerts
    this.handleEnableBloodPressure = this.handleEnableBloodPressure.bind(this);
    this.handleUpdateBloodPressure = this.handleUpdateBloodPressure.bind(this);
    this.onBloodPressureMetricsUpdatedAction = this.onBloodPressureMetricsUpdatedAction.bind(this);

    this.handleEnableOxiMeter = this.handleEnableOxiMeter.bind(this);
    this.handleUpdateOxiMeter = this.handleUpdateOxiMeter.bind(this);
    this.onOxiMeterMetricsUpdatedAction = this.onOxiMeterMetricsUpdatedAction.bind(this);

    this.handleEnableScale = this.handleEnableScale.bind(this);
    this.handleUpdateScaleAlert = this.handleUpdateScaleAlert.bind(this);
    this.onScaleMetricsUpdatedAction = this.onScaleMetricsUpdatedAction.bind(this);

    this.handleEnableTemperature = this.handleEnableTemperature.bind(this);
    this.handleUpdateTemperatureAlert = this.handleUpdateTemperatureAlert.bind(this);
    this.onTemperatureMetricsUpdatedAction = this.onTemperatureMetricsUpdatedAction.bind(this);

    this.handleEnableBloodSugar = this.handleEnableBloodSugar.bind(this);
    this.handleUpdateBloodSugarAlert = this.handleUpdateBloodSugarAlert.bind(this);
    this.onBloodSugarAlertUpdatedAction = this.onBloodSugarAlertUpdatedAction.bind(this);

    this.handleEnableHoursSlept = this.handleEnableHoursSlept.bind(this);
    this.handleUpdateHoursSleptAlert = this.handleUpdateHoursSleptAlert.bind(this);
    this.onHoursSleptAlertUpdatedAction = this.onHoursSleptAlertUpdatedAction.bind(this);
  }

  componentDidMount() {
    GeneralStore.on('onRequestRefreshBiometrics', this.onRequestRefreshBiometricsAction);

    CarePatientStore.on('onGetPatientBiometricDevices', this.onGetPatientBiometricDevicesAction);
    CarePatientStore.on('onGetUnAssignedBioDevices', this.onGetUnAssignedBioDevicesAction);

    //CarePatientStore.on("onAssignBiometricDeviceToPatient", this.onAssignBiometricDeviceToPatientAction);

    //CarePatientStore.on("onOpenBiometricDeviceModal", this.onOpenBiometricDeviceModalAction);
    //CarePatientStore.on("onBioDeviceUpdated", this.onBioDeviceUpdatedAction);
    //CarePatientStore.on("onBioDeviceUnassigned", this.onBioDeviceUnassignedAction);

    CarePatientStore.on('onBloodPressureMetricsUpdated', this.onBloodPressureMetricsUpdatedAction);
    CarePatientStore.on('onOxiMeterMetricsUpdated', this.onOxiMeterMetricsUpdatedAction);
    CarePatientStore.on('onScaleMetricsUpdated', this.onScaleMetricsUpdatedAction);
    CarePatientStore.on('onTemperatureMetricsUpdated', this.onTemperatureMetricsUpdatedAction);
    CarePatientStore.on('onBloodSugarAlertUpdated', this.onBloodSugarAlertUpdatedAction);
    CarePatientStore.on('onHoursSleptAlertUpdated', this.onHoursSleptAlertUpdatedAction);

    // Message.show("Deprecated 2 CarePatientStore.getPatientBiometricDevices");
    // CarePatientStore.getPatientBiometricDevices({
    //     PatientID : this.props.patientID
    // });
    // onGetPatientBiometricDevices

    // Message.show("Deprecated 1 CarePatientStore.getUnAssignedBioDevicesForAccount");
    // CarePatientStore.getUnAssignedBioDevicesForAccount({
    //     AccountID : this.props.accountID
    // });

    $('.modalBiometric').modal();
    this.loadChartsData();
  }

  componentWillReceiveProps(newProps) {
    this.state.props = newProps;
  }

  componentDidUpdate() {
    if (this.props.enableBiometrics != this.state.enableBiometrics) {
      this.setState({
        enableBiometrics: this.props.enableBiometrics,
      });
    }

    if (this.props.patientDevices != this.state.patientDevicesArray) {
      this.setState({
        patientDevicesArray: this.props.patientDevices,
      });
    }
  }

  componentWillUnmount() {
    GeneralStore.removeListener('onRequestRefreshBiometrics', this.onRequestRefreshBiometricsAction);

    CarePatientStore.removeListener('onGetPatientBiometricDevices', this.onGetPatientBiometricDevicesAction);
    CarePatientStore.removeListener('onGetUnAssignedBioDevices', this.onGetUnAssignedBioDevicesAction);

    //CarePatientStore.removeListener("onAssignBiometricDeviceToPatient", this.onAssignBiometricDeviceToPatientAction);

    //CarePatientStore.removeListener("onOpenBiometricDeviceModal", this.onOpenBiometricDeviceModalAction);
    //CarePatientStore.removeListener("onBioDeviceUpdated", this.onBioDeviceUpdatedAction);
    //CarePatientStore.removeListener("onBioDeviceUnassigned", this.onBioDeviceUnassignedAction);

    CarePatientStore.removeListener('onBloodPressureMetricsUpdated', this.onBloodPressureMetricsUpdatedAction);
    CarePatientStore.removeListener('onOxiMeterMetricsUpdated', this.onOxiMeterMetricsUpdatedAction);
    CarePatientStore.removeListener('onScaleMetricsUpdated', this.onScaleMetricsUpdatedAction);
    CarePatientStore.removeListener('onTemperatureMetricsUpdated', this.onTemperatureMetricsUpdatedAction);
    CarePatientStore.removeListener('onBloodSugarAlertUpdated', this.onBloodSugarAlertUpdatedAction);
    CarePatientStore.removeListener('onHoursSleptAlertUpdated', this.onHoursSleptAlertUpdatedAction);
  }

  onRequestRefreshBiometricsAction() {
    this.loadChartsData();
    //Message.show("Refreshing Biometrics...");
  }

  loadChartsData() {
    let headers = null;
    if (headers == null && nucleusNetwork.getToken())
      headers = Object.assign(
        {},
        {
          'Authorization': `Bearer ${nucleusNetwork.getToken()}`,
          'X-EntityType': 'Control',
          'X-Secret': ControlSecret,
        },
        headers,
      );
    // Blood Pressure
    $.ajax({
      url: Config.domain + 'CareAccountReports.asmx/GetBloodPressureReport?PatientID=' + this.props.patientID,
      //url: "https://care-test.nucleuslife.io/ws/CareAccountReports.asmx/GetBloodPressureReport?PatientID=6f49ebea-1484-4d97-8939-c5fa9afbadfb",
      headers: headers,
      success: result => {
        //json = JSON.parse(result);
        console.log('GetBloodPressureReport ==>', result);
        //this.createBloodPressureChart(result.DataList);
        if (result.ok) {
          this.setState({
            pressureArray: result.DataList,
          });
        }
      },
    });

    // Scale
    $.ajax({
      url: Config.domain + 'CareAccountReports.asmx/GetScaleReport?PatientID=' + this.props.patientID,
      headers: headers,
      success: result => {
        //json = JSON.parse(result);
        ///console.log(this.TAG, result);
        //this.createScaleChart(result.DataList);
        if (result.ok) {
          this.setState({
            weightArray: result.DataList,
            weightUnit: result.WeightUnit,
          });
        }
      },
    });

    // Temperature
    $.ajax({
      url: Config.domain + 'CareAccountReports.asmx/GetTemperatureReport?PatientID=' + this.props.patientID,
      //url: "https://care-test.nucleuslife.io/ws/CareAccountReports.asmx/GetTemperatureReport?PatientID=6f49ebea-1484-4d97-8939-c5fa9afbadfb",
      headers: headers,
      success: result => {
        //json = JSON.parse(result);
        ///console.log(this.TAG, result);
        //this.createTemperatureChart(result.DataList);

        if (result.ok) {
          this.setState({
            temperatureArray: result.DataList,
          });
        }
      },
    });

    // Oximeter
    $.ajax({
      url: Config.domain + 'CareAccountReports.asmx/GetOximeterReport?PatientID=' + this.props.patientID,
      //url: "https://care-test.nucleuslife.io/ws/CareAccountReports.asmx/GetOximeterReport?PatientID=6f49ebea-1484-4d97-8939-c5fa9afbadfb",
      headers: headers,
      success: result => {
        //json = JSON.parse(result);
        ///console.log(this.TAG, result);
        //this.createOxiChart(result.DataList);
        if (result.ok) {
          this.setState({
            oximeterArray: result.DataList,
          });
        }
      },
    });

    //BloodSugar
    $.ajax({
      url: Config.domain + 'CareAccountReports.asmx/GetBloodSugarReport?PatientID=' + this.props.patientID,
      //url: "https://care-test.nucleuslife.io/ws/CareAccountReports.asmx/GetOximeterReport?PatientID=6f49ebea-1484-4d97-8939-c5fa9afbadfb",
      headers: headers,
      success: result => {
        //json = JSON.parse(result);
        ///console.log(this.TAG, result);
        //this.createOxiChart(result.DataList);
        if (result.ok) {
          this.setState({
            sugarBloodArray: result.DataList,
          });
        }
      },
    });

    //Hours Slept
    $.ajax({
      url: Config.domain + 'CareAccountReports.asmx/GetHoursSleptReport?PatientID=' + this.props.patientID,
      //url: "https://care-test.nucleuslife.io/ws/CareAccountReports.asmx/GetOximeterReport?PatientID=6f49ebea-1484-4d97-8939-c5fa9afbadfb",
      headers: headers,
      success: result => {
        //json = JSON.parse(result);
        ///console.log(this.TAG, result);
        //this.createOxiChart(result.DataList);
        if (result.ok) {
          this.setState({
            sleepHoursArray: result.DataList,
          });
        }
      },
    });
  }

  // DEPRECATED Nov 20th, 2019
  // https://nucleusintercom.atlassian.net/browse/CARE-6159

  // handleBiometricDevicesTab(){

  //     this.setState({
  //         bioDevicesTab : 'staffTab',
  //         bioAlertsTab : '',
  //         pressureTab : '',
  //         weightTab : '',
  //         temperatureTab : '',
  //         oximeterTab : '',
  //         sugarTab : '',
  //         sleepTab : '',

  //         bioDevicesContent : ' ',
  //         bioAlertsContent : ' hide',
  //         pressureContent : ' hide',
  //         weightContent : ' hide',
  //         temperatureContent : ' hide',
  //         oximeterContent : ' hide',
  //         sugarContent : ' hide',
  //         sleepContent : ' hide'
  //     });

  //     Message.show("Deprecated 3 CarePatientStore.getPatientBiometricDevices");
  //     // CarePatientStore.getPatientBiometricDevices({
  //     //     PatientID : this.props.patientID
  //     // });
  //     // onGetPatientBiometricDevices

  //     Message.show("Deprecated 2 CarePatientStore.getUnAssignedBioDevicesForAccount");
  //     // CarePatientStore.getUnAssignedBioDevicesForAccount({
  //     //     AccountID : this.props.accountID
  //     // });

  //     //Message.show("Biometric Devices reload.");

  // }

  handleBiometricAlertsTab() {
    this.setState({
      //bioDevicesTab : '',
      bioAlertsTab: 'staffTab',
      pressureTab: '',
      weightTab: '',
      temperatureTab: '',
      oximeterTab: '',
      sugarTab: '',
      sleepTab: '',

      //bioDevicesContent : ' hide',
      bioAlertsContent: ' ',
      pressureContent: ' hide',
      weightContent: ' hide',
      temperatureContent: ' hide',
      oximeterContent: ' hide',
      sugarContent: ' hide',
      sleepContent: ' hide',
    });

    CarePatientStore.getPatientBiometricData({
      PatientID: this.props.patientID,
    });
    //Message.show("Biometrics Information updated");
  }

  handlePressureTab() {
    //console.log("Tab Blood Pressure");
    let headers = null;
    if (headers == null && nucleusNetwork.getToken())
      headers = Object.assign(
        {},
        {
          'Authorization': `Bearer ${nucleusNetwork.getToken()}`,
          'X-EntityType': 'Control',
          'X-Secret': ControlSecret,
        },
        headers,
      );
    // Blood Pressure
    $.ajax({
      url: Config.domain + 'CareAccountReports.asmx/GetBloodPressureReport?PatientID=' + this.props.patientID,
      //url: "https://care-test.nucleuslife.io/ws/CareAccountReports.asmx/GetBloodPressureReport?PatientID=6f49ebea-1484-4d97-8939-c5fa9afbadfb",
      headers: headers,
      type: 'GET',
      success: result => {
        //json = JSON.parse(result);
        console.log(this.TAG, result);
        //this.createBloodPressureChart(result.DataList);
        this.setState({
          pressureArray: [],
        });
        //Message.show("Blood Pressure data updated.");
        if (result.ok) {
          this.setState({
            pressureArray: result.DataList,
          });
        }
      },
    });

    this.setState({
      //bioDevicesTab : '',
      bioAlertsTab: '',
      pressureTab: 'staffTab',
      weightTab: '',
      temperatureTab: '',
      oximeterTab: '',
      sugarTab: '',
      sleepTab: '',

      //bioDevicesContent : ' hide',
      bioAlertsContent: ' hide',
      pressureContent: ' ',
      weightContent: ' hide',
      temperatureContent: ' hide',
      oximeterContent: ' hide',
      sugarContent: ' hide',
      sleepContent: ' hide',
    });
  }

  handleWeightTab() {
    let headers = null;
    if (headers == null && nucleusNetwork.getToken())
      headers = Object.assign(
        {},
        {
          'Authorization': `Bearer ${nucleusNetwork.getToken()}`,
          'X-EntityType': 'Control',
          'X-Secret': ControlSecret,
        },
        headers,
      );
    // Scale
    $.ajax({
      type: 'GET',
      url: Config.domain + 'CareAccountReports.asmx/GetScaleReport?PatientID=' + this.props.patientID,
      headers,
      success: result => {
        //json = JSON.parse(result);
        console.log(this.TAG, result);
        //this.createScaleChart(result.DataList);
        this.setState({
          weightArray: [],
        });
        //Message.show("Weight data updated.");
        if (result.ok) {
          this.setState({
            weightArray: result.DataList,
            weightUnit: result.WeightUnit,
          });
        }
      },
    });

    this.setState({
      //bioDevicesTab : '',
      bioAlertsTab: '',
      pressureTab: '',
      weightTab: 'staffTab',
      temperatureTab: '',
      oximeterTab: '',
      sugarTab: '',
      sleepTab: '',

      //bioDevicesContent : ' hide',
      bioAlertsContent: ' hide',
      pressureContent: ' hide',
      weightContent: ' ',
      temperatureContent: ' hide',
      oximeterContent: ' hide',
      sugarContent: ' hide',
      sleepContent: ' hide',
    });
  }

  handleTemperatureTab() {
    //console.log("Tab Temperature");
    let headers = null;
    if (headers == null && nucleusNetwork.getToken())
      headers = Object.assign(
        {},
        {
          'Authorization': `Bearer ${nucleusNetwork.getToken()}`,
          'X-EntityType': 'Control',
          'X-Secret': ControlSecret,
        },
        headers,
      );
    // Temperature
    $.ajax({
      type: 'GET',
      url: Config.domain + 'CareAccountReports.asmx/GetTemperatureReport?PatientID=' + this.props.patientID,
      headers: headers,
      //url: "https://care-test.nucleuslife.io/ws/CareAccountReports.asmx/GetTemperatureReport?PatientID=6f49ebea-1484-4d97-8939-c5fa9afbadfb",
      success: result => {
        //json = JSON.parse(result);
        console.log(this.TAG, result);
        //this.createTemperatureChart(result.DataList);
        this.setState({
          temperatureArray: [],
        });
        //Message.show("Temperature data updated.");
        if (result.ok) {
          this.setState({
            temperatureArray: result.DataList,
          });
        }
      },
    });

    this.setState({
      //bioDevicesTab : '',
      bioAlertsTab: '',
      pressureTab: '',
      weightTab: '',
      temperatureTab: 'staffTab',
      oximeterTab: '',
      sugarTab: '',
      sleepTab: '',

      //bioDevicesContent : ' hide',
      bioAlertsContent: ' hide',
      pressureContent: ' hide',
      weightContent: ' hide',
      temperatureContent: ' ',
      oximeterContent: ' hide',
      sugarContent: ' hide',
      sleepContent: ' hide',
    });
  }

  handleOximeterTab() {
    // Oximeter
    //console.log("Tab Oximeter");
    let headers = null;
    if (headers == null && nucleusNetwork.getToken())
      headers = Object.assign(
        {},
        {
          'Authorization': `Bearer ${nucleusNetwork.getToken()}`,
          'X-EntityType': 'Control',
          'X-Secret': ControlSecret,
        },
        headers,
      );
    $.ajax({
      type: 'GET',
      url: Config.domain + 'CareAccountReports.asmx/GetOximeterReport?PatientID=' + this.props.patientID,
      //url: "https://care-test.nucleuslife.io/ws/CareAccountReports.asmx/GetOximeterReport?PatientID=6f49ebea-1484-4d97-8939-c5fa9afbadfb",
      headers: headers,
      success: result => {
        //json = JSON.parse(result);
        console.log(this.TAG, result);
        //this.createOxiChart(result.DataList);
        this.setState({
          oximeterArray: [],
        });
        //Message.show("Oximeter data updated.");
        if (result.ok) {
          this.setState({
            oximeterArray: result.DataList,
          });
        }
      },
    });

    this.setState({
      //bioDevicesTab : '',
      bioAlertsTab: '',
      pressureTab: '',
      weightTab: '',
      temperatureTab: '',
      oximeterTab: 'staffTab',
      sugarTab: '',
      sleepTab: '',

      //bioDevicesContent : ' hide',
      bioAlertsContent: ' hide',
      pressureContent: ' hide',
      weightContent: ' hide',
      temperatureContent: ' hide',
      oximeterContent: ' ',
      sugarContent: ' hide',
      sleepContent: ' hide',
    });
  }

  handleSugarTab() {
    // Blood Sugar
    //console.log("Tab Blood Sugar");
    let headers = null;
    if (headers == null && nucleusNetwork.getToken())
      headers = Object.assign(
        {},
        {
          'Authorization': `Bearer ${nucleusNetwork.getToken()}`,
          'X-EntityType': 'Control',
          'X-Secret': ControlSecret,
        },
        headers,
      );
    $.ajax({
      type: 'GET',
      url: Config.domain + 'CareAccountReports.asmx/GetBloodSugarReport?PatientID=' + this.props.patientID,
      //url: "https://care-test.nucleuslife.io/ws/CareAccountReports.asmx/GetOximeterReport?PatientID=6f49ebea-1484-4d97-8939-c5fa9afbadfb",
      headers: headers,
      success: result => {
        //json = JSON.parse(result);
        console.log(this.TAG, result);
        //this.createOxiChart(result.DataList);
        this.setState({
          sugarBloodArray: [],
        });
        //Message.show("Blood Sugar data updated.");
        if (result.ok) {
          this.setState({
            sugarBloodArray: result.DataList,
          });
        }
      },
    });

    this.setState({
      //bioDevicesTab : '',
      bioAlertsTab: '',
      pressureTab: '',
      weightTab: '',
      temperatureTab: '',
      oximeterTab: '',
      sugarTab: 'staffTab',
      sleepTab: '',

      //bioDevicesContent : ' hide',
      bioAlertsContent: ' hide',
      pressureContent: ' hide',
      weightContent: ' hide',
      temperatureContent: ' hide',
      oximeterContent: ' hide',
      sugarContent: ' ',
      sleepContent: ' hide',
    });
  }

  handleSleepTab() {
    // Hours Slept
    //console.log("Tab Hours Slept");
    let headers = null;
    if (headers == null && nucleusNetwork.getToken())
      headers = Object.assign(
        {},
        {
          'Authorization': `Bearer ${nucleusNetwork.getToken()}`,
          'X-EntityType': 'Control',
          'X-Secret': ControlSecret,
        },
        headers,
      );
    $.ajax({
      type: 'GET',
      url: Config.domain + 'CareAccountReports.asmx/GetHoursSleptReport?PatientID=' + this.props.patientID,
      headers: headers,
      //url: "https://care-test.nucleuslife.io/ws/CareAccountReports.asmx/GetOximeterReport?PatientID=6f49ebea-1484-4d97-8939-c5fa9afbadfb",
      success: result => {
        //json = JSON.parse(result);
        console.log(this.TAG, result);
        //this.createOxiChart(result.DataList);
        this.setState({
          sleepHoursArray: [],
        });
        //Message.show("Hours Slept data updated.");
        if (result.ok) {
          this.setState({
            sleepHoursArray: result.DataList,
          });
        }
      },
    });

    this.setState({
      //bioDevicesTab : '',
      bioAlertsTab: '',
      pressureTab: '',
      weightTab: '',
      temperatureTab: '',
      oximeterTab: '',
      sugarTab: '',
      sleepTab: 'staffTab',

      //bioDevicesContent : ' hide',
      bioAlertsContent: ' hide',
      pressureContent: ' hide',
      weightContent: ' hide',
      temperatureContent: ' hide',
      oximeterContent: ' hide',
      sugarContent: ' hide',
      sleepContent: ' ',
    });
  }

  /*
   

    */

  onGetPatientBiometricDevicesAction(response) {
    console.log('onGetPatientBiometricDevicesAction', response);
    Message.show('DEPRECATED onGetPatientBiometricDevicesAction');

    // DEPRECATED Nov 20th, 2019
    // https://nucleusintercom.atlassian.net/browse/CARE-6159

    // if (response.ok){

    //     let biometricDevicesArrayTemp = response.BiometricDevices;

    //     for(var i = 0; i < biometricDevicesArrayTemp.length; i++){

    //         //console.log("Estamos en el bioDevice", biometricDevicesArrayTemp[i]);
    //         biometricDevicesArrayTemp[i]["DeviceName"] = "Not assigned";
    //         if (biometricDevicesArrayTemp[i].DeviceID != null && biometricDevicesArrayTemp[i].DeviceID.toString().length > 0){

    //             for(var j = 0; j < this.state.patientDevicesArray.length; j++){

    //                 //console.log("Estamos buscando el ", biometricDevicesArrayTemp[i].DeviceID, " en ", this.state.patientDevicesArray[j]);
    //                 if (biometricDevicesArrayTemp[i].DeviceID == this.state.patientDevicesArray[j].DeviceID){
    //                     biometricDevicesArrayTemp[i]["DeviceName"] = this.state.patientDevicesArray[j].Name;
    //                     break;
    //                 }
    //             }
    //         }
    //     }

    //     //console.log("-->", biometricDevicesArrayTemp);
    //     this.setState({
    //         biometricDevicesArray : biometricDevicesArrayTemp
    //     });
    //     // this.buttonsInfoLoaded = this.buttonsInfoLoaded + 1;
    //     // if (this.buttonsInfoLoaded == 2){
    //     //     Message.show(AuthStore.getPatientLabel() + " Buttons information reloaded.");
    //     // }
    // }
  }

  onGetUnAssignedBioDevicesAction(response) {
    console.log('onGetUnAssignedBioDevicesAction', response);
    Message.show('DEPRECATED onGetUnAssignedBioDevicesAction');

    // DEPRECATED Nov 20th, 2019
    // https://nucleusintercom.atlassian.net/browse/CARE-6159

    // if (response.ok){
    //     this.setState({
    //         availableBiometricDevicesArray : response.BiometricDevices
    //     });
    //     // this.buttonsInfoLoaded = this.buttonsInfoLoaded + 1;
    //     // if (this.buttonsInfoLoaded == 2){
    //     //     Message.show(AuthStore.getPatientLabel() + " Buttons information reloaded.");
    //     // }
    // }
  }

  // DEPRECATED Nov 20th, 2019
  // https://nucleusintercom.atlassian.net/browse/CARE-6159
  // handlePanelAssignNewBiometricDevice(){
  //     if (this.state.addBiometricDeviceBtnTitle == "Assign Device"){
  //         this.setState({
  //             addBiometricDeviceBtnTitle : "Cancel",
  //             addBiometricDeviceLabelMode : " hide",
  //             addBiometricDeviceControlsMode :  " ",

  //             btnNewBiometricDeviceStyle : "nucleus-submit-btn-small-disabled"
  //         });

  //         this.refs.selectBiometricDeviceMac.value = "";
  //         this.refs.txtNewBiometricDeviceLocation.value = "";
  //         this.refs.selectDevice.value = "";

  //         setTimeout(()=>{
  //             this.refs.txtNewBiometricDeviceLocation.focus();
  //         }, 100);

  //     } else if (this.state.addBiometricDeviceBtnTitle == "Cancel"){
  //         this.setState({
  //             addBiometricDeviceBtnTitle : "Assign Device",
  //             addBiometricDeviceLabelMode : " ",
  //             addBiometricDeviceControlsMode :  " hide"
  //         });
  //     }
  // }

  // handleNewBiometricDeviceName(){
  //     if(!(this.refs.selectBiometricDeviceMac.value == "") && (this.refs.txtNewBiometricDeviceLocation.value.length > 0) && !(this.refs.selectDevice.value == "") ) {
  //         this.setState({
  //             btnNewBiometricDeviceStyle : "nucleus-submit-btn-small"
  //         });
  //     } else {

  //         this.setState({
  //             btnNewBiometricDeviceStyle : "nucleus-submit-btn-small-disabled"
  //         });
  //     }
  //     this.refs.txtNewBiometricDeviceLocation.focus();
  // }

  // handleAssignNewBiometricDevice(){

  //     Message.show("Deprecated 1 CarePatientStore.assignBiometricDeviceToPatient");
  //     // CarePatientStore.assignBiometricDeviceToPatient({
  //     //     PatientID : this.props.patientID,
  //     //     Mac : this.refs.selectBiometricDeviceMac.value,
  //     //     DeviceID : this.refs.selectDevice.value,
  //     //     Location : this.refs.txtNewBiometricDeviceLocation.value
  //     // });

  //     this.setState({
  //         newBiometricDeviceMacAddress : this.refs.selectBiometricDeviceMac.selectedOptions[0].text
  //     });
  // }

  // onAssignBiometricDeviceToPatientAction(response){
  //     console.log("onAssignBiometricDeviceToPatientAction", response);
  //     if (response.ok){

  //         Message.show("Deprecated 4 CarePatientStore.getPatientBiometricDevices");
  //         // CarePatientStore.getPatientBiometricDevices({
  //         //     PatientID : this.props.patientID
  //         // });
  //         // onGetPatientBiometricDevices

  //         Message.show("Deprecated 3 CarePatientStore.getUnAssignedBioDevicesForAccount");
  //         // CarePatientStore.getUnAssignedBioDevicesForAccount({
  //         //     AccountID : this.props.accountID
  //         // });

  //         /*
  //         this.setState({
  //             patientDeviceMac : this.state.newButtonMacAddress, // this.refs.selectDeviceMacAddress.selectedOptions[0].text,
  //             updated: true
  //         });
  //         */
  //         this.handlePanelAssignNewBiometricDevice();
  //         Message.show("The biometric device with mac address " + this.state.newBiometricDeviceMacAddress + " is now assigned to the " + AuthStore.getPatientLabel().toLowerCase() + ".");
  //     } else {
  //         Message.show("There was a problem, please try again.");
  //     }
  // }

  // onOpenBiometricDeviceModalAction(biometricDeviceInfo){
  //     console.log("onOpenBiometricDeviceModalAction", biometricDeviceInfo);
  //     $('#modalPatientEditBiometricDevice').modal('open');

  //     this.refs.txtBioDeviceMac.value = biometricDeviceInfo.Mac;
  //     this.refs.selectBioDeviceType.value = biometricDeviceInfo.Type;
  //     this.refs.txtBioDeviceLocation.value = biometricDeviceInfo.Location;
  //     this.refs.selectPatientDevice.value = biometricDeviceInfo.DeviceID || "";

  //     this.setState({
  //         bioDeviceModalOptionLabel : "Remove",
  //         bioDeviceModeEdit : '',
  //         bioDeviceModeDelete : 'hide',
  //         bioDeviceModalOptionColor : 'txt-red',

  //         saveEditBioDeviceStyle : 'nucleus-submit-btn'
  //     });
  // }

  // handleCloseEditBiometricDeviceModal(){
  //     $('#modalPatientEditBiometricDevice').modal('close');
  // }

  // handleBiometricDeviceModalMode(){
  //     if (this.state.bioDeviceModalOptionLabel == "Remove") {
  //         this.setState({
  //             bioDeviceModalOptionLabel : "Cancel",
  //             bioDeviceModeEdit : 'hide',
  //             bioDeviceModeDelete : '',
  //             bioDeviceModalOptionColor : ''
  //         });
  //     } else {
  //         this.setState({
  //             bioDeviceModalOptionLabel : "Remove",
  //             bioDeviceModeEdit : '',
  //             bioDeviceModeDelete : 'hide',
  //             bioDeviceModalOptionColor : 'txt-red'
  //         });
  //     }
  // }

  // handleSaveBiometricDeviceEdited(){

  //     this.setState({
  //         saveEditBioDeviceStyle : 'nucleus-submit-btn-disabled'
  //     });

  //     Message.show("Deprecated 1 updateBiometricDevice");
  //     // CarePatientStore.updateBiometricDevice({
  //     //     Mac : this.refs.txtBioDeviceMac.value,
  //     //     //Type : this.refs.selectBioDeviceType.value,
  //     //     Location : this.refs.txtBioDeviceLocation.value,
  //     //     DeviceID : this.refs.selectPatientDevice.value
  //     // });

  //     // this.setState({
  //     //     errorMemberEmailVisibility : 'hide'
  //     // });
  // }

  // onBioDeviceUpdatedAction(response){
  //     console.log("onBioDeviceUpdatedAction", response);

  //     this.setState({
  //         saveEditBioDeviceStyle : 'nucleus-submit-btn'
  //     });

  //     if (response.ok){
  //         $('#modalPatientEditBiometricDevice').modal('close');
  //         Message.show("Biometric Device updated");

  //         Message.show("Deprecated 5 CarePatientStore.getPatientBiometricDevices");
  //         // CarePatientStore.getPatientBiometricDevices({
  //         //     PatientID : this.props.patientID
  //         // });
  //     } else {
  //         Message.show("Error updating Biometric Device");
  //     }
  // }

  // handleSubmitDeleteBiometricDevice(){

  //     this.setState({
  //         deleteMemberBtnStyle : 'nucleus-submit-btn-disabled'
  //     });

  //     Message.show("Deprecated 1 unAssignBioDeviceFromPatient");
  //     // CarePatientStore.unAssignBioDeviceFromPatient({
  //     //     Mac : this.refs.txtBioDeviceMac.value
  //     // });

  // }

  // onBioDeviceUnassignedAction(response){
  //     console.log("onBioDeviceUnassignedAction", response);

  //     this.setState({
  //         deleteMemberBtnStyle : 'nucleus-submit-btn'
  //     });

  //     if (response.ok){
  //         $('#modalPatientEditBiometricDevice').modal('close');
  //         Message.show("Biometric Device unassigned");

  //         Message.show("Deprecated 6 CarePatientStore.getPatientBiometricDevices");
  //         // CarePatientStore.getPatientBiometricDevices({
  //         //     PatientID : this.props.patientID
  //         // });

  //         Message.show("Deprecated 4 CarePatientStore.getUnAssignedBioDevicesForAccount");
  //         // CarePatientStore.getUnAssignedBioDevicesForAccount({
  //         //     AccountID : this.props.accountID
  //         // });

  //     } else {
  //         Message.show("Error unassigning Biometric Device");
  //     }
  // }

  //Alerts
  handleEnableBloodPressure(event) {
    console.log('this has changed ', event.target.checked);

    //this.handleUpdateBloodPressure();
  }

  handleUpdateBloodPressure() {
    if (this.props.bloodPressureAlert.ID.length > 0) {
      CarePatientStore.updateBloodPressureMetrics({
        BloodPressureAlertID: this.props.bloodPressureAlert.ID,
        Active: this.refs.checkBloodPressure.checked,
        DiastolicAbove: this.refs.txtDiastolicAbove.value,
        DiastolicBelow: this.refs.txtDiastolicBelow.value,
        HeartRateAbove: this.refs.txtHeartRateAbove.value,
        HeartRateBelow: this.refs.txtHeartRateBelow.value,
        SystolicAbove: this.refs.txtSystolicAbove.value,
        SystolicBelow: this.refs.txtSystolicBelow.value,
      });

      this.setState({
        btnBloodPressureStyle: 'disabled',
      });
    }
  }
  onBloodPressureMetricsUpdatedAction(response) {
    console.log('onBloodPressureMetricsUpdatedAction');
    console.log(response);

    this.setState({
      btnBloodPressureStyle: '',
    });

    if (response.ok) {
      Message.show('Blood Pressure alerts updated');
    } else {
      Message.show('Error updating Blood Pressure alerts');
    }
  }

  handleEnableOxiMeter(event) {
    console.log('this has changed ', event.target.checked);

    // CarePatientStore.updateCallPhoneFromDevice({
    //     PatientID : this.props.patientID,
    //     CallPhoneFromDevice : event.target.checked
    // });
    // onCallPhoneFromDeviceUpdated
  }
  handleUpdateOxiMeter() {
    if (this.props.oxiMeterAlert.ID.length > 0) {
      CarePatientStore.updateOxiMeterMetrics({
        OxiMeterAlertID: this.props.oxiMeterAlert.ID,
        Active: this.refs.checkOxiMeter.checked,
        HeartRateAbove: this.refs.txtHeartRateAbove2.value,
        HeartRateBelow: this.refs.txtHeartRateBelow2.value,
        SPO2Above: this.refs.txtSPO2Above.value,
        SPO2Below: this.refs.txtSPO2Below.value,
        RespAbove: this.refs.txtRespAbove.value,
        RespBelow: this.refs.txtRespBelow.value,
      });

      this.setState({
        btnOxiMeterValueStyle: 'disabled',
      });
    }
  }

  onOxiMeterMetricsUpdatedAction(response) {
    console.log('onOxiMeterMetricsUpdatedAction');
    console.log(response);

    this.setState({
      btnOxiMeterValueStyle: '',
    });

    if (response.ok) {
      Message.show('OxiMeter alerts updated');
    } else {
      Message.show('Error updating OxiMeter alerts');
    }
  }

  handleEnableScale(event) {
    console.log('this has changed ', event.target.checked);

    // CarePatientStore.updateCallPhoneFromDevice({
    //     PatientID : this.props.patientID,
    //     CallPhoneFromDevice : event.target.checked
    // });
    // onCallPhoneFromDeviceUpdated
  }

  handleUpdateScaleAlert() {
    if (this.props.scaleAlert.ID.length > 0) {
      CarePatientStore.updateScaleMetrics({
        ScaleAlertID: this.props.scaleAlert.ID,
        Active: this.refs.checkScaleAlert.checked,
        WeightLBAbove: this.refs.txtWeightLBAbove.value,
        WeightLBBelow: this.refs.txtWeightLBBelow.value,
        TimeFrameForChangeCheck: this.refs.txtWeightTimeCheck.value,
      });

      this.setState({
        btnScaleAlertStyle: 'disabled',
      });
    }
  }
  onScaleMetricsUpdatedAction(response) {
    console.log('onScaleMetricsUpdatedAction');
    console.log(response);

    this.setState({
      btnScaleAlertStyle: '',
    });

    if (response.ok) {
      Message.show('Scale Metrics alerts updated');
    } else {
      Message.show('Error updating Scale Metrics alerts');
    }
  }

  handleEnableTemperature(event) {
    console.log('this has changed ', event.target.checked);

    // CarePatientStore.updateCallPhoneFromDevice({
    //     PatientID : this.props.patientID,
    //     CallPhoneFromDevice : event.target.checked
    // });
    // onCallPhoneFromDeviceUpdated
  }

  handleUpdateTemperatureAlert() {
    if (this.props.temperatureAlert.ID.length > 0) {
      console.log('UPDATE 1');
      console.log(this.props.temperatureAlert.ID);
      console.log(this.refs.checkTemperature.checked);
      console.log(this.refs.txtTemperatureBelow.value);
      console.log(this.refs.txtTemperatureAbove.value);

      CarePatientStore.updateTemperatureMetrics({
        TemperatureAlertID: this.props.temperatureAlert.ID,
        Active: this.refs.checkTemperature.checked,
        TemperatureAbove: this.refs.txtTemperatureAbove.value,
        TemperatureBelow: this.refs.txtTemperatureBelow.value,
      });

      this.setState({
        btnTemperatureStyle: 'disabled',
      });
    }
  }

  onTemperatureMetricsUpdatedAction(response) {
    console.log('onTemperatureMetricsUpdatedAction');
    console.log(response);

    this.setState({
      btnTemperatureStyle: '',
    });

    if (response.ok) {
      Message.show('Temperature Metrics alerts updated');
    } else {
      Message.show('Error updating Temperature Metrics alerts');
    }
  }

  handleEnableBloodSugar(event) {
    console.log('handleEnableBloodSugar changed ', event.target.checked);
  }

  handleUpdateBloodSugarAlert() {
    if (this.props.bloodSugarAlert.ID.length > 0) {
      console.log('UPDATE bloodSugarAlert');
      console.log(this.props.bloodSugarAlert.ID);
      console.log(this.refs.checkBloodSugar.checked);
      console.log(this.refs.txtBloodSugarBelow.value);
      console.log(this.refs.txtBloodSugarAbove.value);

      CarePatientStore.updateBloodSugarAlert({
        BloodSugarAlertID: this.props.bloodSugarAlert.ID,
        Active: this.refs.checkBloodSugar.checked,
        SugarLevelBelow: this.refs.txtBloodSugarBelow.value,
        SugarLevelAbove: this.refs.txtBloodSugarAbove.value,
      });
      //onBloodSugarAlertUpdated

      this.setState({
        btnBloodSugarStyle: 'disabled',
      });
    } else {
      Message.show('Error updating Blood Sugar Alerts');
    }
  }
  onBloodSugarAlertUpdatedAction(response) {
    console.log('onBloodSugarAlertUpdatedAction', response);

    this.setState({
      btnBloodSugarStyle: '',
    });

    if (response.ok) {
      Message.show('Blood Sugar Alerts updated');
    } else {
      Message.show('Error updating Blood Sugar Alerts');
    }
  }

  handleEnableHoursSlept(event) {
    console.log('handleEnableHoursSlept changed ', event.target.checked);
  }

  handleUpdateHoursSleptAlert() {
    if (this.props.bloodSugarAlert.ID.length > 0) {
      console.log('UPDATE sleepHoursAlert');
      console.log(this.props.sleepHoursAlert.ID);
      console.log(this.refs.checkHoursSlept.checked);
      console.log(this.refs.txtHoursSleptBelow.value);
      console.log(this.refs.txtHoursSleptAbove.value);

      CarePatientStore.updateHoursSleptAlert({
        HoursSleptAlertID: this.props.sleepHoursAlert.ID,
        Active: this.refs.checkHoursSlept.checked,
        HoursSleptBelow: this.refs.txtHoursSleptBelow.value,
        HoursSleptAbove: this.refs.txtHoursSleptAbove.value,
      });
      //onHoursSleptAlertUpdated

      this.setState({
        btnHoursSleptStyle: 'disabled',
      });
    } else {
      Message.show('Error updating Sleep Hours Alerts');
    }
  }
  onHoursSleptAlertUpdatedAction(response) {
    console.log('onHoursSleptAlertUpdatedAction', response);

    this.setState({
      btnHoursSleptStyle: '',
    });

    if (response.ok) {
      Message.show('Sleep Hours Alerts updated');
    } else {
      Message.show('Error updating Sleep Hours Alerts');
    }
  }

  render() {
    // DEPRECATED Nov 20th, 2019
    // https://nucleusintercom.atlassian.net/browse/CARE-6159

    // let patientBioDevicesRows = [];

    // if (this.state.biometricDevicesArray != null && this.state.biometricDevicesArray != []){
    //     this.state.biometricDevicesArray.map((bioDevice, key) => {

    //         patientBioDevicesRows.push(
    //             <CarePatientBioDeviceItemRow
    //               key={key}
    //               Mac={ bioDevice.Mac }
    //               Type={ bioDevice.Type }
    //               DeviceID={ bioDevice.DeviceID }
    //               DeviceName={ bioDevice.DeviceName }
    //               Location={ bioDevice.Location }
    //             />
    //         );
    //     });
    // }

    // let availableBioDevicesOptions = [];
    // if (this.state.availableBiometricDevicesArray != null && this.state.availableBiometricDevicesArray.length){
    //     this.state.availableBiometricDevicesArray.map((bioDevice, key) => {
    //         availableBioDevicesOptions.push(<option key={bioDevice.Mac} value={bioDevice.Mac} >{bioDevice.Mac + " - " + bioDevice.Type}</option>);
    //     });
    // }

    // let selectBioDevicesCaption = (availableBioDevicesOptions.length > 0) ? 'Choose one of the available devices' : 'No available devices';

    // --
    // let availablePatientDevicesOptions = [];
    // if (this.state.patientDevicesArray != null && this.state.patientDevicesArray.length){
    //     this.state.patientDevicesArray.map((device, key) => {
    //         availablePatientDevicesOptions.push(<option key={device.DeviceID} value={device.DeviceID} >{device.Name }</option>);
    //     });
    // }

    // let selectDevicesCaption = (availablePatientDevicesOptions.length > 0) ? 'Choose one of the patient devices' : 'No available devices';

    // --
    let biometricAlertsSection = ' hide';
    if (this.state.enableBiometrics && this.state.enableBiometrics.toString() == 'true') {
      biometricAlertsSection = ' ';
    }

    let bloodPressureValue = '';
    let systolicBelowValue = '';
    let systolicAboveValue = '';

    let diastolicBelowValue = '';
    let diastolicAboveValue = '';

    let heartRateBelowValue = '';
    let heartRateAboveValue = '';

    if (this.props.bloodPressureAlert) {
      if (this.props.bloodPressureAlert.Active) {
        bloodPressureValue = 'checked';
      }
      systolicBelowValue = this.props.bloodPressureAlert.SystolicBelow;
      systolicAboveValue = this.props.bloodPressureAlert.SystolicAbove;

      diastolicBelowValue = this.props.bloodPressureAlert.DiastolicBelow;
      diastolicAboveValue = this.props.bloodPressureAlert.DiastolicAbove;

      heartRateBelowValue = this.props.bloodPressureAlert.HeartRateBelow;
      heartRateAboveValue = this.props.bloodPressureAlert.HeartRateAbove;
    }

    // ---

    let oxiMeterValue = '';
    let heartRateBelowValue2 = '';
    let heartRateAboveValue2 = '';

    let SPO2BelowValue = '';
    let SPO2AboveValue = '';

    let RespBelowValue = '';
    let RespAboveValue = '';

    if (this.props.oxiMeterAlert) {
      if (this.props.oxiMeterAlert.Active) {
        oxiMeterValue = 'checked';
      }
      heartRateBelowValue2 = this.props.oxiMeterAlert.HeartRateBelow;
      heartRateAboveValue2 = this.props.oxiMeterAlert.HeartRateAbove;

      SPO2BelowValue = this.props.oxiMeterAlert.SPO2Below;
      SPO2AboveValue = this.props.oxiMeterAlert.SPO2Above;

      RespBelowValue = this.props.oxiMeterAlert.RespBelow;
      RespAboveValue = this.props.oxiMeterAlert.RespAbove;
    }

    // --

    let scaleAlertValue = '';
    let weightLBBelowValue = '';
    let weightLBAboveValue = '';

    let weightTimeCheckValue = '';

    if (this.props.scaleAlert) {
      if (this.props.scaleAlert.Active) {
        scaleAlertValue = 'checked';
      }
      weightLBBelowValue = this.props.scaleAlert.WeightLBBelow;
      weightLBAboveValue = this.props.scaleAlert.WeightLBAbove;

      weightTimeCheckValue = this.props.scaleAlert.TimeFrameForChangeCheck;
    }

    // Temperature
    let temperatureAlertValue = '';
    let temperatureBelowValue = '';
    let temperatureAboveValue = '';

    if (this.props.temperatureAlert) {
      if (this.props.temperatureAlert.Active) {
        temperatureAlertValue = 'checked';
      }
      temperatureBelowValue = this.props.temperatureAlert.TemperatureBelow;
      temperatureAboveValue = this.props.temperatureAlert.TemperatureAbove;
    }

    // Blood Sugar
    let bloodSugarAlertValue = '';
    let bloodSugarBelowValue = '';
    let bloodSugarAboveValue = '';

    if (this.props.bloodSugarAlert) {
      if (this.props.bloodSugarAlert.Active) {
        bloodSugarAlertValue = 'checked';
      }
      bloodSugarBelowValue = this.props.bloodSugarAlert.BloodSugarLevelBelow;
      bloodSugarAboveValue = this.props.bloodSugarAlert.BloodSugarLevelAbove;
    }

    // Hours Slept
    let sleepHoursAlertValue = '';
    let sleepHoursBelowValue = '';
    let sleepHoursAboveValue = '';

    if (this.props.sleepHoursAlert) {
      if (this.props.sleepHoursAlert.Active) {
        sleepHoursAlertValue = 'checked';
      }
      sleepHoursBelowValue = this.props.sleepHoursAlert.HoursSleptBelow;
      sleepHoursAboveValue = this.props.sleepHoursAlert.HoursSleptAbove;
    }

    const pressureRecords = [];
    if (this.state.pressureArray != null) {
      this.state.pressureArray.map((pressureRecord, key) => {
        pressureRecords.push(
          <tr key={key}>
            <td className="nucleus-biotable-row left-align">&nbsp;&nbsp; {pressureRecord.DateCheck}&nbsp;&nbsp; </td>
            <td className="nucleus-biotable-row center-align">{pressureRecord.Systolic}</td>
            <td className="nucleus-biotable-row center-align">{pressureRecord.Diastolic || 90}</td>
            <td className="nucleus-biotable-row center-align">{pressureRecord.Pulse}</td>
            <td className="nucleus-biotable-row left-align">{pressureRecord.BPName}</td>
          </tr>,
          /*<CarePatientAssignmentItemRow
                  key={key}
                  AssignmentID={ pressureRecord.ID}
                  CareGiverID={pressureRecord.CareGiverID}
                  CareGiverName={pressureRecord.CareGiverName}
                  Message={pressureRecord.Message}
                /> */
        );
      });
    }

    const weightRecords = [];
    if (this.state.weightArray != null) {
      this.state.weightArray.map((weightRecord, key) => {
        weightRecords.push(
          <tr key={key}>
            <td className="nucleus-biotable-row left-align">&nbsp;&nbsp;{weightRecord.DateCheck}&nbsp;&nbsp;</td>
            <td className="nucleus-biotable-row center-align">{weightRecord.Weight}</td>
            <td className="nucleus-biotable-row left-align">{weightRecord.BSName}</td>
          </tr>,
        );
      });
    }

    const temperatureRecords = [];
    if (this.state.temperatureArray != null) {
      this.state.temperatureArray.map((temperatureRecord, key) => {
        temperatureRecords.push(
          <tr key={key}>
            <td className="nucleus-biotable-row left-align">&nbsp;&nbsp;{temperatureRecord.DateCheck}&nbsp;&nbsp;</td>
            <td className="nucleus-biotable-row center-align">{temperatureRecord.Temperature}</td>
          </tr>,
        );
      });
    }

    const oximeterRecords = [];
    if (this.state.oximeterArray != null) {
      this.state.oximeterArray.map((oximeterRecord, key) => {
        oximeterRecords.push(
          <tr key={key}>
            <td className="nucleus-biotable-row left-align">&nbsp;&nbsp;{oximeterRecord.DateCheck}&nbsp;</td>
            <td className="nucleus-biotable-row center-align">{oximeterRecord.HeartRate}</td>
            <td className="nucleus-biotable-row center-align">{oximeterRecord.SPO2}</td>
            <td className="nucleus-biotable-row center-align">{oximeterRecord.PI}</td>
            <td className="nucleus-biotable-row center-align">{oximeterRecord.Resp}</td>
          </tr>,
        );
      });
    }

    const bloodSugarRecords = [];
    if (this.state.sugarBloodArray != null) {
      this.state.sugarBloodArray.map((sugarRecord, key) => {
        bloodSugarRecords.push(
          <tr key={key}>
            <td className="nucleus-biotable-row left-align">&nbsp;&nbsp;{sugarRecord.DateCheck}&nbsp;&nbsp;</td>
            <td className="nucleus-biotable-row center-align">{sugarRecord.BloodSugarLevel}</td>
          </tr>,
        );
      });
    }

    const sleptHoursRecords = [];
    if (this.state.sleepHoursArray != null) {
      this.state.sleepHoursArray.map((sleepRecord, key) => {
        sleptHoursRecords.push(
          <tr key={key}>
            <td className="nucleus-biotable-row left-align">&nbsp;&nbsp;{sleepRecord.DateCheck}&nbsp;&nbsp;</td>
            <td className="nucleus-biotable-row center-align">{sleepRecord.HoursSlept}</td>
          </tr>,
        );
      });
    }

    return (
      <div className=" assignments-table-pending biometricsChartContainer">
        <div>
          {/*
                    // DEPRECATED Nov 20th, 2019
                    // https://nucleusintercom.atlassian.net/browse/CARE-6159
                    // 
                    <a class={"staffTabsSmall nucleus-link " + this.state.bioDevicesTab }  onClick={this.handleBiometricDevicesTab}>&nbsp;Biometrics Devices&nbsp;</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    */}
          <a class={'staffTabsSmall nucleus-link ' + this.state.pressureTab} onClick={this.handlePressureTab}>
            &nbsp;Blood Pressure&nbsp;
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a class={'staffTabsSmall nucleus-link ' + this.state.weightTab} onClick={this.handleWeightTab}>
            &nbsp;Weight&nbsp;
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a class={'staffTabsSmall nucleus-link ' + this.state.temperatureTab} onClick={this.handleTemperatureTab}>
            &nbsp;Temperature&nbsp;
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a class={'staffTabsSmall nucleus-link ' + this.state.oximeterTab} onClick={this.handleOximeterTab}>
            &nbsp;Oximeter&nbsp;
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a class={'staffTabsSmall nucleus-link ' + this.state.sugarTab} onClick={this.handleSugarTab}>
            &nbsp;Blood Sugar&nbsp;
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a class={'staffTabsSmall nucleus-link ' + this.state.sleepTab} onClick={this.handleSleepTab}>
            &nbsp;Sleep&nbsp;
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a class={'staffTabsSmall nucleus-link ' + this.state.bioAlertsTab} onClick={this.handleBiometricAlertsTab}>
            &nbsp;Biometrics Alerts Settings&nbsp;
          </a>
        </div>
        <br />

        {/*
                <div class={"row " + this.state.bioDevicesContent }>
                    
                    <table className="bordered highlight width-70" >
                        <thead>
                            <tr>
                                <th className="table-col-20 left-align "><span className="nucleus-table-header">Mac Address</span></th>
                                <th className="table-col-15 left-align "><span className="nucleus-table-header">Type</span></th>
                                <th className="table-col-30 left-align "><span className="nucleus-table-header">Name</span></th>
                                <th className="table-col-30 left-align "><span className="nucleus-table-header">Assigned Device</span></th>
                                <th className="table-col-5 right-align "><span className="nucleus-table-header"></span></th>
                            </tr>
                        </thead>
                        <tbody>
                            { patientBioDevicesRows }
                        </tbody>
                    </table>

                    <br/><br/>
                    <div class={"row " + this.state.addBiometricDeviceLabelMode} >
                        <div className="col s12 no-margin">
                            { "Assign a button to the " + AuthStore.getPatientLabel() }
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handlePanelAssignNewBiometricDevice}>{this.state.addBiometricDeviceBtnTitle}</a>
                        </div>
                    </div>
                    <div class={ "no-margin " + this.state.addBiometricDeviceControlsMode}>
                        <div className="row no-margin">
                            <div className="col s12 no-margin">
                                { "Select the mac address of the " + AuthStore.getPatientLabel().toLowerCase() + " new Biometric Device and give it a name.\n\rAlso select the patient device to assign the biometric device." }
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handlePanelAssignNewBiometricDevice}>{this.state.addBiometricDeviceBtnTitle}</a>
                            </div>
                        </div>
                        <div className="row no-margin">
                            <div className="col s4 m4 l3 no-margin">
                                <select ref="selectBiometricDeviceMac" className="browser-default select-device-mac" onChange={this.handleNewBiometricDeviceName}>
                                    <option value="" disabled selected> { selectBioDevicesCaption } </option>
                                    { availableBioDevicesOptions }
                                </select>
                            </div>
                            <div className="col s8 m8 l9 ">
                                <input ref="txtNewBiometricDeviceLocation" type="text" className="validate nucleus-input-form txt-button-name" placeholder="Click to name this device " onChange={this.handleNewBiometricDeviceName}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;

                                <select ref="selectDevice" className="browser-default select-middle" onChange={this.handleNewBiometricDeviceName}>
                                    <option value="" disabled selected> { selectDevicesCaption } </option>
                                    { availablePatientDevicesOptions }
                                </select>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a class={"txt-white nucleus-font-small " + this.state.btnNewBiometricDeviceStyle} onClick={this.handleAssignNewBiometricDevice}>Submit</a>
                            </div>
                        </div>
                        <div className="row no-margin a-bit-upper ">
                            <div className="col s4 m4 l3 no-margin "> &nbsp; </div>
                            <div className="col s8 m8 l9 no-margin a-bit-upper">
                               <span className="nucleus-font-small">&nbsp;&nbsp;&nbsp;&nbsp;For example: Scale Room</span>
                            </div>
                        </div>
                    </div>

                </div>
                */}

        <div class={'row ' + this.state.bioAlertsContent}>
          <div class={'row no-margin ' + biometricAlertsSection}>
            {/*
                        <span className="nucleus-labels-bold ">Biometrics</span>
                        <br/><br/>
                        */}
            <div className="col s5 content-bordered">
              <br />
              <div className="row vbottom-align">
                <div className="col s1 no-margin">
                  <span key={bloodPressureValue}>
                    <input
                      ref="checkBloodPressure"
                      type="checkbox"
                      className="filled-in"
                      id={'checkBloodPressure' + this.props.accountID}
                      defaultChecked={bloodPressureValue}
                      onChange={this.handleEnableBloodPressure}
                    />
                    <label htmlFor={'checkBloodPressure' + this.props.accountID}>&nbsp;</label>
                  </span>
                </div>
                <div className="col s10 no-margin">
                  <span className="nucleus-labels">Blood Pressure</span>
                </div>
              </div>
              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align no-margin"></div>
                <div className="col s4 no-margin center-align no-margin">Below</div>

                <div className="col s4 no-margin center-align no-margin">Above</div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align">Systolic&nbsp;</div>
                <div className="col s4 no-margin center-align" key={' below ' + systolicBelowValue}>
                  <input ref="txtSystolicBelow" type="text" className="validate nucleus-input-form-small" defaultValue={systolicBelowValue} />
                </div>

                <div className="col s4 no-margin center-align" key={' above ' + systolicAboveValue}>
                  <input ref="txtSystolicAbove" type="text" className="validate nucleus-input-form-small" defaultValue={systolicAboveValue} />
                </div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align">Diastolic</div>
                <div className="col s4 no-margin center-align" key={' below ' + diastolicBelowValue}>
                  <input ref="txtDiastolicBelow" type="text" className="validate nucleus-input-form-small" defaultValue={diastolicBelowValue} />
                </div>

                <div className="col s4 no-margin center-align" key={' above ' + diastolicAboveValue}>
                  <input ref="txtDiastolicAbove" type="text" className="validate nucleus-input-form-small" defaultValue={diastolicAboveValue} />
                </div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align">Pulse&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div className="col s4 no-margin center-align" key={' below ' + heartRateBelowValue}>
                  <input ref="txtHeartRateBelow" type="text" className="validate nucleus-input-form-small" defaultValue={heartRateBelowValue} />
                </div>

                <div className="col s4 no-margin center-align" key={' above ' + heartRateAboveValue}>
                  <input ref="txtHeartRateAbove" type="text" className="validate nucleus-input-form-small" defaultValue={heartRateAboveValue} />
                </div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin right-align">
                  <a
                    className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn"
                    disabled={this.state.btnBloodPressureStyle}
                    onClick={this.handleUpdateBloodPressure}
                  >
                    <i className="material-icons nucleus-floating-btn-icon">save</i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col s1 no-margin "></div>

            <div className="col s5 content-bordered ">
              <br />
              <div className="row vbottom-align">
                <div className="col s1 no-margin">
                  <span key={oxiMeterValue}>
                    <input
                      ref="checkOxiMeter"
                      type="checkbox"
                      className="filled-in"
                      id={'checkOxiMeter' + this.props.accountID}
                      defaultChecked={oxiMeterValue}
                      onChange={this.handleEnableOxiMeter}
                    />
                    <label htmlFor={'checkOxiMeter' + this.props.accountID}>&nbsp;</label>
                  </span>
                </div>
                <div className="col s10 no-margin">
                  <span className="nucleus-labels">OxiMeter</span>
                </div>
              </div>
              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align no-margin"></div>
                <div className="col s4 no-margin center-align no-margin">Below</div>

                <div className="col s4 no-margin center-align no-margin">Above</div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align">Heart Rate</div>
                <div className="col s4 no-margin center-align" key={' below ' + heartRateBelowValue2}>
                  <input ref="txtHeartRateBelow2" type="text" className="validate nucleus-input-form-small" defaultValue={heartRateBelowValue2} />
                </div>

                <div className="col s4 no-margin center-align" key={' above ' + heartRateAboveValue2}>
                  <input ref="txtHeartRateAbove2" type="text" className="validate nucleus-input-form-small" defaultValue={heartRateAboveValue2} />
                </div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align">SPO2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div className="col s4 no-margin center-align" key={' below ' + SPO2BelowValue}>
                  <input ref="txtSPO2Below" type="text" className="validate nucleus-input-form-small" defaultValue={SPO2BelowValue} />
                </div>

                <div className="col s4 no-margin center-align" key={' above ' + SPO2AboveValue}>
                  <input ref="txtSPO2Above" type="text" className="validate nucleus-input-form-small" defaultValue={SPO2AboveValue} />
                </div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align">RESP&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div className="col s4 no-margin center-align" key={' below ' + RespBelowValue}>
                  <input ref="txtRespBelow" type="text" className="validate nucleus-input-form-small" defaultValue={RespBelowValue} />
                </div>

                <div className="col s4 no-margin center-align" key={' above ' + RespAboveValue}>
                  <input ref="txtRespAbove" type="text" className="validate nucleus-input-form-small" defaultValue={RespAboveValue} />
                </div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align"></div>
                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin center-align"></div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align"></div>
                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin right-align">
                  <a
                    className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn"
                    disabled={this.state.btnOxiMeterValueStyle}
                    onClick={this.handleUpdateOxiMeter}
                  >
                    <i className="material-icons nucleus-floating-btn-icon">save</i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col s1 no-margin"></div>
          </div>

          <br />

          <div class={'row no-margin ' + biometricAlertsSection}>
            <div className="col s5 content-bordered">
              <br />
              <div className="row vbottom-align">
                <div className="col s1 no-margin">
                  <span key={scaleAlertValue}>
                    <input
                      ref="checkScaleAlert"
                      type="checkbox"
                      className="filled-in"
                      id={'checkScaleAlert' + this.props.accountID}
                      defaultChecked={scaleAlertValue}
                      onChange={this.handleEnableScale}
                    />
                    <label htmlFor={'checkScaleAlert' + this.props.accountID}>&nbsp;</label>
                  </span>
                </div>
                <div className="col s10 no-margin">
                  <span className="nucleus-labels">Weight</span>
                </div>
              </div>
              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align no-margin"></div>
                <div className="col s4 no-margin center-align no-margin">Below</div>

                <div className="col s4 no-margin center-align no-margin">Above</div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align">Weight&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div className="col s4 no-margin center-align" key={' below ' + weightLBBelowValue}>
                  <input ref="txtWeightLBBelow" type="text" className="validate nucleus-input-form-small" defaultValue={weightLBBelowValue} />
                </div>

                <div className="col s4 no-margin center-align" key={' above ' + weightLBAboveValue}>
                  <input ref="txtWeightLBAbove" type="text" className="validate nucleus-input-form-small" defaultValue={weightLBAboveValue} />
                </div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align">Time-Frame</div>
                <div className="col s4 no-margin center-align" key={weightTimeCheckValue}>
                  <input ref="txtWeightTimeCheck" type="text" className="validate nucleus-input-form-small" defaultValue={weightTimeCheckValue} />
                </div>

                <div className="col s4 no-margin center-align top-separated-margin">Days</div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align"></div>

                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin center-align"></div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align"></div>
                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin right-align">
                  <a
                    className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn"
                    disabled={this.state.btnScaleAlertStyle}
                    onClick={this.handleUpdateScaleAlert}
                  >
                    <i className="material-icons nucleus-floating-btn-icon">save</i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col s1 no-margin "></div>

            <div className="col s5 content-bordered">
              <br />
              <div className="row vbottom-align">
                <div className="col s1 no-margin">
                  <span key={temperatureAlertValue}>
                    <input
                      ref="checkTemperature"
                      type="checkbox"
                      className="filled-in"
                      id={'checkTemperature' + this.props.accountID}
                      defaultChecked={temperatureAlertValue}
                      onChange={this.handleEnableTemperature}
                    />
                    <label htmlFor={'checkTemperature' + this.props.accountID}>&nbsp;</label>
                  </span>
                </div>
                <div className="col s10 no-margin">
                  <span className="nucleus-labels">Temperature</span>
                </div>
              </div>
              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align no-margin"></div>
                <div className="col s4 no-margin center-align no-margin">Below</div>

                <div className="col s4 no-margin center-align no-margin">Above</div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align">Temperature</div>
                <div className="col s4 no-margin center-align" key={' below ' + temperatureBelowValue}>
                  <input ref="txtTemperatureBelow" type="text" className="validate nucleus-input-form-small" defaultValue={temperatureBelowValue} />
                </div>

                <div className="col s4 no-margin center-align" key={' above ' + temperatureAboveValue}>
                  <input ref="txtTemperatureAbove" type="text" className="validate nucleus-input-form-small" defaultValue={temperatureAboveValue} />
                </div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align"></div>

                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin center-align"></div>
              </div>
              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align"></div>

                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin center-align"></div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align"></div>
                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin right-align">
                  <a
                    className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn"
                    disabled={this.state.btnTemperatureStyle}
                    onClick={this.handleUpdateTemperatureAlert}
                  >
                    <i className="material-icons nucleus-floating-btn-icon">save</i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col s1 no-margin "></div>
          </div>

          <br />
          {
            // BIOMETRICS 3 Row
          }
          <div class={'row no-margin ' + biometricAlertsSection}>
            <div className="col s5 content-bordered">
              <br />
              <div className="row vbottom-align">
                <div className="col s1 no-margin">
                  <span key={bloodSugarAlertValue}>
                    <input
                      ref="checkBloodSugar"
                      type="checkbox"
                      className="filled-in"
                      id="checkBloodSugar"
                      defaultChecked={bloodSugarAlertValue}
                      onChange={this.handleEnableBloodSugar}
                    />
                    <label htmlFor="checkBloodSugar">&nbsp;</label>
                  </span>
                </div>
                <div className="col s10 no-margin">
                  <span className="nucleus-labels">Blood Sugar</span>
                </div>
              </div>
              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align no-margin"></div>
                <div className="col s4 no-margin center-align no-margin">Below</div>

                <div className="col s4 no-margin center-align no-margin">Above</div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align">Blood Sugar (mg/dL)</div>
                <div className="col s4 no-margin center-align" key={' below ' + bloodSugarBelowValue}>
                  <input ref="txtBloodSugarBelow" type="text" className="validate nucleus-input-form-small" defaultValue={bloodSugarBelowValue} />
                </div>

                <div className="col s4 no-margin center-align" key={' above ' + bloodSugarAboveValue}>
                  <input ref="txtBloodSugarAbove" type="text" className="validate nucleus-input-form-small" defaultValue={bloodSugarAboveValue} />
                </div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align"></div>

                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin center-align"></div>
              </div>
              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align"></div>

                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin center-align"></div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align"></div>
                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin right-align">
                  <a
                    className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn"
                    disabled={this.state.btnBloodSugarStyle}
                    onClick={this.handleUpdateBloodSugarAlert}
                  >
                    <i className="material-icons nucleus-floating-btn-icon">save</i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col s1 no-margin "></div>

            <div className="col s5 content-bordered">
              <br />
              <div className="row vbottom-align">
                <div className="col s1 no-margin">
                  <span key={sleepHoursAlertValue}>
                    <input
                      ref="checkHoursSlept"
                      type="checkbox"
                      className="filled-in"
                      id="checkHoursSlept"
                      defaultChecked={sleepHoursAlertValue}
                      onChange={this.handleEnableHoursSlept}
                    />
                    <label htmlFor="checkHoursSlept">&nbsp;</label>
                  </span>
                </div>
                <div className="col s10 no-margin">
                  <span className="nucleus-labels">Sleep</span>
                </div>
              </div>
              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align no-margin"></div>
                <div className="col s4 no-margin center-align no-margin">Below</div>

                <div className="col s4 no-margin center-align no-margin">Above</div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align">Sleep Hours</div>
                <div className="col s4 no-margin center-align" key={' below ' + sleepHoursBelowValue}>
                  <input ref="txtHoursSleptBelow" type="text" className="validate nucleus-input-form-small" defaultValue={sleepHoursBelowValue} />
                </div>

                <div className="col s4 no-margin center-align" key={' above ' + sleepHoursAboveValue}>
                  <input ref="txtHoursSleptAbove" type="text" className="validate nucleus-input-form-small" defaultValue={sleepHoursAboveValue} />
                </div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align"></div>

                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin center-align"></div>
              </div>
              <div className="row vbottom-align no-margin">
                <div className="col s4 center-align"></div>

                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin center-align"></div>
              </div>

              <div className="row vbottom-align no-margin">
                <div className="col s4 no-margin center-align"></div>
                <div className="col s4 no-margin center-align"></div>

                <div className="col s4 no-margin right-align">
                  <a
                    className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn"
                    disabled={this.state.btnHoursSleptStyle}
                    onClick={this.handleUpdateHoursSleptAlert}
                  >
                    <i className="material-icons nucleus-floating-btn-icon">save</i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col s1 no-margin "></div>
          </div>
        </div>

        <div class={'row ' + this.state.pressureContent}>
          {/*
                    <div className="row">
                        <div id="chartdivBP"></div>
                    </div>
                    */}

          <div className="assignments-table-pending ">
            <table className="bordered highlight nucleus-bio-table">
              <thead>
                <tr className="header-biotable-row">
                  <th className="table-col-30 header-biotable-col left-align">
                    <span className="nucleus-table-header-small">&nbsp;&nbsp;Date&nbsp;&nbsp;</span>
                  </th>
                  <th className="table-col-15 header-biotable-col center-align">
                    <span className="nucleus-table-header-small">Systolic</span>
                  </th>
                  <th className="table-col-15 header-biotable-col center-align">
                    <span className="nucleus-table-header-small">Diastolic</span>
                  </th>
                  <th className="table-col-15 header-biotable-col center-align">
                    <span className="nucleus-table-header-small">Pulse</span>
                  </th>
                  <th className="table-col-25 header-biotable-col left-align">
                    <span className="nucleus-table-header-small">BP Monitor</span>
                  </th>
                </tr>
              </thead>

              <tbody>{pressureRecords}</tbody>
            </table>
          </div>
        </div>

        <div class={'row ' + this.state.weightContent}>
          {/*
                    <div className="row">
                        <div id="chartdivScale"></div>
                    </div>
                    */}
          <div className="assignments-table-pending ">
            <table className="bordered highlight nucleus-bio-table">
              <thead>
                <tr className="header-biotable-row">
                  <th className="table-col-40 header-biotable-col left-align">
                    <span className="nucleus-table-header-small">&nbsp;&nbsp;Date&nbsp;&nbsp;</span>
                  </th>
                  <th className="table-col-25 header-biotable-col center-align">
                    <span className="nucleus-table-header-small">Weight ({this.state.weightUnit}s)</span>
                  </th>
                  <th className="table-col-35 header-biotable-col left-align">
                    <span className="nucleus-table-header-small">Scale Name</span>
                  </th>
                </tr>
              </thead>

              <tbody>{weightRecords}</tbody>
            </table>
          </div>
        </div>

        <div class={'row ' + this.state.temperatureContent}>
          {/* 
                    <div className="row">
                        <div  id="chartdivTemp"></div>
                    </div>
                    */}

          <div className="assignments-table-pending ">
            <table className="bordered highlight nucleus-bio-table">
              <thead>
                <tr className="header-biotable-row">
                  <th className="table-col-60 header-biotable-col left-align">
                    <span className="nucleus-table-header-small">&nbsp;&nbsp;Date&nbsp;&nbsp;</span>
                  </th>
                  <th className="table-col-40 header-biotable-col center-align">
                    <span className="nucleus-table-header-small">Temperature</span>
                  </th>
                </tr>
              </thead>

              <tbody>{temperatureRecords}</tbody>
            </table>
          </div>
        </div>

        <div class={'row ' + this.state.oximeterContent}>
          {/*
                    <div className="row">
                        <div  id="chartdivOxi"></div>
                    </div>
                    */}
          <div className="assignments-table-pending ">
            <table className="bordered highlight nucleus-bio-table">
              <thead>
                <tr className="header-biotable-row">
                  <th className="table-col-32 header-biotable-col left-align">
                    <span className="nucleus-table-header-small">&nbsp;&nbsp;Date&nbsp;</span>
                  </th>
                  <th className="table-col-17 header-biotable-col center-align">
                    <span className="nucleus-table-header-small">Heart Rate</span>
                  </th>
                  <th className="table-col-17 header-biotable-col center-align">
                    <span className="nucleus-table-header-small">SP02</span>
                  </th>
                  <th className="table-col-17 header-biotable-col center-align">
                    <span className="nucleus-table-header-small">PI</span>
                  </th>
                  <th className="table-col-17 header-biotable-col center-align">
                    <span className="nucleus-table-header-small">Resp</span>
                  </th>
                </tr>
              </thead>

              <tbody>{oximeterRecords}</tbody>
            </table>
          </div>
        </div>

        <div class={'row ' + this.state.sugarContent}>
          {/*
                    <div className="row">
                        <div  id="chartdivOxi"></div>
                    </div>
                    */}
          <div className="assignments-table-pending ">
            <table className="bordered highlight nucleus-bio-table">
              <thead>
                <tr className="header-biotable-row">
                  <th className="table-col-50 header-biotable-col left-align">
                    <span className="nucleus-table-header-small">&nbsp;&nbsp;Date&nbsp;</span>
                  </th>
                  <th className="table-col-50 header-biotable-col center-align">
                    <span className="nucleus-table-header-small">Blood Sugar (mg/dL)</span>
                  </th>
                </tr>
              </thead>

              <tbody>{bloodSugarRecords}</tbody>
            </table>
          </div>
        </div>

        <div class={'row ' + this.state.sleepContent}>
          {/*
                    <div className="row">
                        <div  id="chartdivOxi"></div>
                    </div>
                    */}
          <div className="assignments-table-pending ">
            <table className="bordered highlight nucleus-bio-table">
              <thead>
                <tr className="header-biotable-row">
                  <th className="table-col-50 header-biotable-col left-align">
                    <span className="nucleus-table-header-small">&nbsp;&nbsp;Date&nbsp;</span>
                  </th>
                  <th className="table-col-50 header-biotable-col center-align">
                    <span className="nucleus-table-header-small">Hours Slept</span>
                  </th>
                </tr>
              </thead>

              <tbody>{sleptHoursRecords}</tbody>
            </table>
          </div>
        </div>

        {/*
                <div id="modalPatientEditBiometricDevice" className="modal modalScrollableNotWide modalBiometric">
                    <div className="modal-content nucleus-modal-content txt-special-color nucleus-modal-wrapper-scrollable2">
                        <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseEditBiometricDeviceModal}/>
                        <h3 className="nucleus-page-subtitle">&nbsp;  Edit Biometric Device</h3>
                        <a class={"nucleus-font nucleus-font-small nucleus-link" } onClick={this.handleBiometricDeviceModalMode}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class={this.state.bioDeviceModalOptionColor } > {this.state.bioDeviceModalOptionLabel}</span></a>
                        <br/><br/>
                        <div class={" modallScrollable " + this.state.bioDeviceModeEdit }>
                            <div className="row">
                                <div className="col s5">
                                    <span className="nucleus-labels"><b>Mac</b></span>
                                </div>
                                <div className="col s7">
                                    <input ref="txtBioDeviceMac" type="text" disabled="disabled" className="validate nucleus-input-form" />

                                 </div>
                            </div>

                            <div className="row">
                                <div className="col s5">
                                    <span className="nucleus-labels"><b>Type</b></span>
                                </div>
                                <div className="col s7">
                                    
                                    <select ref="selectBioDeviceType" className="browser-default select-device-mac"  disabled="disabled" >
                                        <option value="Thermometer">Thermometer</option>
                                        <option value="Scale" >Scale</option>
                                        <option value="Oximeter" >Oximeter</option>
                                        <option value="Blood Pressure" >Blood Pressure</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s5">
                                    <span className="nucleus-labels"><b>Name</b></span>
                                </div>
                                <div className="col s7" >
                                    <input ref="txtBioDeviceLocation" type="text" className="validate nucleus-input-form"/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s5">
                                    <span className="nucleus-labels"><b>Assigned Device</b></span>
                                </div>
                                <div className="col s7" >
                                    <select ref="selectPatientDevice" className="browser-default select-device-mac">
                                        <option value="" disabled selected> { "No device assigned" } </option>
                                        { availablePatientDevicesOptions }
                                    </select>
                                </div>
                            </div>
                            
                            <div className="input-field col s12 modalCallLogContent2">
                                <div className="row no-margin">
                                    <div className="col s12 center-align no-margin">
                                        <span className=" modalSeparator"> <label> &nbsp;</label></span>
                                        <a class={"txt-white nucleus-font-modal-btn " + this.state.saveEditBioDeviceStyle } onClick={this.handleSaveBiometricDeviceEdited}>Save</a>
                                        <div>&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class={" " + this.state.bioDeviceModeDelete }>
                            <div className="row">
                                <div className="col s12">
                                    <span className="nucleus-labels"> Do you want to remove this biometric device from the { AuthStore.getPatientLabel() }? </span>
                                </div>
                            </div>

                            <div className="input-field col s12 modalCallLogContent2">
                                <br/>
                                <div className="row no-margin">
                                    <div className="col s12 center-align no-margin">
                                        <p></p>
                                        <span className=" modalSeparator"> <label> &nbsp;</label></span>
                                        <a class={"txt-white nucleus-font-modal-btn " + this.state.deleteBioDeviceBtnStyle } onClick={this.handleSubmitDeleteBiometricDevice}>Remove</a>
                                        <div>&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                */}
      </div>
    );
  }
}

export default PatientBiometricsSection;

import NucleusSearchInput from 'components/NucleusSearchInput';
import NucleusTable from 'components/NucleusTable';
import { UINucleusContainer } from 'components/UI';
import { UINucleusContentContainer } from 'components/UI/NucleusContainer/Content';
import { UINucleusPageTitle } from 'components/UI/NucleusPageTitle/Default';
import { IProviderAccount, useNucleusProviders } from 'context-api/nucleusProvidersContext/NucleusProvidersContext';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row } from 'react-table';
import { SpinnerCircular } from 'spinners-react';
import { getProviderAccounts } from 'utils/providerApi';
import { isValidID } from 'utils/uuid';
import ProviderDetailsWrapper from '../components/ProviderDetailsWrapper';
import { UserImpersonation } from 'utils/UserImpersonation';
import { useNavigate } from 'react-router-dom';

const ProviderAccounts = () => {
  const fastFilter = true;
  const {
    state: {
      provider: { data },
    },
  } = useNucleusProviders();
  const [providerAccountsData, setProviderAccountsData] = React.useState<IProviderAccount[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const { register, setValue, watch, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const columns = [
    {
      Header: 'Account Name',
      accessor: 'AccountName',
      Cell: ({ value }) => <div style={{ textAlign: 'left' }}>{value}</div>,
    },
    {
      Header: 'Residents',
      accessor: 'PatientCount',
      Cell: ({ value }) => <div style={{ textAlign: 'center', width: '30%' }}>{value}</div>,
    },
    {
      Header: 'Total Devices',
      accessor: 'DeviceCount',
      Cell: ({ value }) => <div style={{ textAlign: 'center', width: '30%' }}>{value}</div>,
    },
    {
      Header: 'Online Devices',
      accessor: 'OnlineDeviceCount',
      Cell: ({ value }) => <div style={{ textAlign: 'center', width: '30%' }}>{value}</div>,
    },
    {
      Header: 'Users',
      accessor: 'UsersCount',
      Cell: ({ value }) => <div style={{ textAlign: 'center', width: '30%' }}>{value}</div>,
    },
  ];

  useEffect(() => {
    if (!isValidID(data?.ID)) return;
    async function fetchProviderAccounts(providerId: string) {
      setIsLoading(true);
      console.log('getProviderAccounts', providerId);
      const providerAccounts = await getProviderAccounts(providerId);
      console.log('providerAccounts', providerAccounts);
      if (providerAccounts?.length) {
        setProviderAccountsData(providerAccounts);
      }
      setIsLoading(false);
    }
    fetchProviderAccounts(data?.ID);
  }, [data?.ID]);

  const handleSubmitSearch = data => {
    console.log('handleSubmitSearch', data);
    setSearchText(data.search);
  };

  const startAccountImpersonation = (account: any) => {
    console.log('startAccountImpersonation', account);
    UserImpersonation.impersonateUserAccount(account.AccountID);
    window.location.href = '/';
  };

  return (
    <ProviderDetailsWrapper>
      <UINucleusContainer>
        <UINucleusPageTitle>Provider: {providerAccountsData[0]?.ProviderName}</UINucleusPageTitle>
        <UINucleusContentContainer style={{ paddingTop: 30 }}>
          <form onSubmit={handleSubmit(handleSubmitSearch)}>
            <NucleusSearchInput
              // name='search'
              value={watch('search')}
              register={register('search', {
                onChange: () => {
                  setValue('search', watch('search'));
                  if (fastFilter) {
                    setSearchText(watch('search'));
                  }
                },
              })}
              placeholder="Filter by Group/Name"
            />
          </form>
          {isLoading ? (
            <UINucleusContentContainer centerContent>
              <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness={100} />
            </UINucleusContentContainer>
          ) : (
            <NucleusTable
              onRowClick={({ row }: { row: Row }) => {
                startAccountImpersonation(row.original);
              }}
              columns={columns}
              data={providerAccountsData.filter(data => !searchText || data.accountName?.toLowerCase().includes(searchText.toLowerCase()))}
            />
          )}
        </UINucleusContentContainer>
      </UINucleusContainer>
    </ProviderDetailsWrapper>
  );
};

export default ProviderAccounts;

'use strict';
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { connect } from 'react-redux';
import AuthStore from '../../stores/AuthStore';
import Message from '../../utils/Message';

import CompleteProfileForm from './CompleteProfileForm';

class CompleteProfile extends React.Component<{
  history: any;
}> {
  constructor() {
    super();
    this.state = {
      btnStyle: ' nucleus-submit-btn-disabled',
      errorView1: ' hide',
      errorText: '',
    };

    //this.validateFields = this.validateFields.bind(this);
    //this.validateFieldsFromKeyboard = this.validateFieldsFromKeyboard.bind(this);
    //this.validateTxtInput = this.validateTxtInput.bind(this);

    this.onUserUpdateDataResult = this.onUserUpdateDataResult.bind(this);
    this.handleGoToLogin = this.handleGoToLogin.bind(this);
    this.location = null;
    //this.history = createHistory({forceRefresh:true});
  }

  componentDidMount() {
    //Register Listener
    console.log('>>>>>>>>>>>>>>>> old Password', AuthStore.getNucleusUserTemporalPassword());
    AuthStore.on('onUserUpdateData', this.onUserUpdateDataResult);
  }

  componentWillUnmount() {
    //Remove Listener
    AuthStore.removeListener('onUserUpdateData', this.onUserUpdateDataResult);
  }

  // validateTxtInput(e){
  //     ////console.log(e.target.value);
  //     this.setState({
  //         errorView1 : " hide",
  //     });
  //     if (this.refs.nucleusNewPassword.value.length > 0 && this.refs.nucleusConfirmPassword.value.length > 0){
  //         this.setState({
  //             btnStyle : " nucleus-submit-btn"
  //         });
  //     } else {

  //         this.setState({
  //             btnStyle : " nucleus-submit-btn-disabled"
  //         });
  //     }
  // }

  // validateFieldsFromKeyboard(e){
  //     if ((e.charCode == 13) || (e.keyCode == 13) || (e.key == 13) ){
  //         this.validateFields();
  //     }
  // }

  //validateFields() {
  // if (this.refs.nucleusNewPassword.value != this.refs.nucleusConfirmPassword.value){
  //     //Materialize.toast('Your passwords do not match', 3000, 'rounded');
  //     this.setState({
  //         errorView1 : " ",
  //         errorText : "Your passwords do not match"
  //     });
  //     return;
  // }
  // let newPassword = this.refs.nucleusConfirmPassword.value;
  // if (newPassword.length < 6) {
  //     this.setState({
  //         errorView1 : " ",
  //         errorText : "Your password must be at least 6 characters"
  //     });
  //     return;
  // }
  // if (newPassword.search(/[a-zA-Z]/)< 0) {
  //     this.setState({
  //         errorView1 : " ",
  //         errorText : "Your password must contain at least one letter"
  //     });
  //     return;
  // }
  // if (newPassword.search(/\d/) < 0 && newPassword.search(/[\!\@\#\$\%\^\&\*\(\)\_\+]/) < 0){
  //     this.setState({
  //         errorView1 : " ",
  //         errorText : "Your password must contain at least one digit or one special character"
  //     });
  //     return;
  // }
  //this.resetPassword();
  //}

  resetPassword = newPassword => {
    console.log('resetPassword!', newPassword);

    //console.log("this.refs.nucleusNewPassword.value", this.refs.nucleusNewPassword.value);
    AuthStore.updateUserData({
      UserID: AuthStore.getUserID(),
      Password: AuthStore.getNucleusUserTemporalPassword(),
      NewPassword: newPassword,
      IsOnDuty: true,
      UpdateUserData: false,
      UpdateUserPassword: true,
    });
  };

  onUserUpdateDataResult = response => {
    console.log('onUserUpdateDataResult >', response);

    if (response.ok) {
      if (response.updateUserData) {
        Message.show('Profile updated...');
      }
      if (response.updateUserPassword && response.passwordUpdated) {
        AuthStore.setProfileCreated(true);
        AuthStore.setOnDuty(true);
        this.props.history.push('/dashboard');
      } else {
        Message.show('Error ');
      }
    } else {
      if (response.internetError) {
        Message.show('Network error, please check you internet connection and try again.');
      } else if (!response.okCredentials) {
        Message.show('Your user password is not correct.');
      } else {
        Message.show('There was a problem updating your profile');
      }
    }
  };

  handleGoToLogin() {
    AuthStore.logout(dispatch);
  }

  render() {
    const style = {
      height: window.innerHeight,
      background: '#008FFF',
      overflowY: 'auto',
    };

    return (
      <div className="nucleus-login" style={style}>
        <div className="alto valign-wrapper" style={{ height: 760 }}>
          <div className="row ancho">
            <div className="col s2 m3 l3 border"></div>
            <div className="col s8 m6 l6 border center-align no-padding">
              <img className="responsive-img" src="/img/nucleus_2024_logo.png" />
              <br />
              <br />
              <p className="txt-white nucleus-page-subtitle">Complete your Profile</p>
              <br />
              <br />
              {/* <p className="login-info txt-white nucleus-font nucleus-font"> Please enter your password in order to complete your profile. </p> */}

              <CompleteProfileForm
                onSubmitForm={this.resetPassword}
                userData={{
                  firstName: AuthStore.getUserFirstName(),
                  lastName: AuthStore.getUserLastName(),
                  email: AuthStore.getUserEmail(),
                }}
              ></CompleteProfileForm>
              <br />
            </div>
            <div className="col s2 m3 l3 border"></div>
          </div>
        </div>
      </div>
    );
  }
}

//export default CompleteProfile;
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const CompleteProfileFC = props => {
  return useReactRouterProps(CompleteProfile, props);
};
export default connect(null, mapDispatchToProps)(CompleteProfileFC);

const IMPERSONATING_ACCOUNT_ID = 'impersonatingAccountId';

export const UserImpersonation = {
  impersonateUserAccount: (accountId: string) => {
    localStorage.setItem(IMPERSONATING_ACCOUNT_ID, accountId);
  },

  getImpersonatingAccount: () => {
    const account = localStorage.getItem(IMPERSONATING_ACCOUNT_ID);
    return account;
  },
  isImpersonating: () => {
    return !!localStorage.getItem(IMPERSONATING_ACCOUNT_ID);
  },

  stopImpersonating: () => {
    localStorage.removeItem(IMPERSONATING_ACCOUNT_ID);
  }
};

import { EventEmitter } from 'events';
import PicturesApi from '../apis/PicturesApi';
import { AuthStore } from '.';

class CarePicturesStore extends EventEmitter {
  private PicturesApi: PicturesApi;
  private PicturesArray: any[] = [];
  private PicturesArraySize: number = 0;
  private PicturesCount: number = 0;
  private retrievingPictures: boolean = false;

  constructor() {
    super();

    this.PicturesApi = new PicturesApi(this);

    try {
      this.PicturesArray = localStorage.PicturesArray ? JSON.parse(localStorage.PicturesArray) : [];
      this.PicturesArraySize = this.PicturesArray.length;
    } catch (e) {
      console.error('Error parsing PicturesArray from localStorage', e);
    }

    this.PicturesCount = localStorage.PicturesCount || 0;
    this.retrievingPictures = false;
  }

  getPicturesArray() {
    return this.PicturesArray;
  }

  getPicturesArraySize() {
    return this.PicturesArraySize;
  }

  getPicturesCount() {
    return this.PicturesCount;
  }

  getPicturesByAccount({ AccountID }) {

    this.retrievingPictures = true;

    this.PicturesApi.GetPicturesByAccount({ AccountID }, rsp => {
      this.retrievingPictures = false;

      if (rsp.ok) {
        this.PicturesArray = rsp.media;
        this.PicturesArraySize = rsp.media.length;
        this.PicturesCount = rsp.media.length;
        this.saveToLocalStorage();
      }

      this.emit('onGetPicturesByAccountComplete', rsp.ok);
    });
  }

  getPicturePatients({ MediaID, UserID }) {

    this.PicturesApi.GetPicturePatients({ MediaID, UserID }, rsp => {
      this.emit('onGetPicturePatients', rsp);
    });
  }

  assignPatient({ MediaID, PatientID }) {

    this.PicturesApi.AssignPicturePatient({ MediaID, PatientID }, rsp => {
      this.emit('onAssignPicturePatient', rsp);
    });
  }

  unassignPatient({ MediaID, PatientID }) {
    this.PicturesApi.UnAssignPicturePatient({ MediaID, PatientID }, rsp => {
      this.emit('onUnAssignPicturePatient', rsp);
    });
  }

  setForAllPatients({ MediaID, Enabled }) {

    this.PicturesApi.SetPictureForAllPatient({ MediaID, Enabled }, rsp => {
      this.emit('onSetPictureForAllPatient', rsp);
    });
  }

  updateImagePatients({ MediaID, PatientsIDs }) {

    this.PicturesApi.UpdateImagePatients({ MediaID, PatientsIDs }, rsp => {
      this.emit('onPicturePatientsUpdated', rsp);
    });
  }

  getAutocompletePatientsList({ UserID }) {
    this.PicturesApi.GetAutocompletePatientsList({ UserID }, rsp => {
      this.emit('onGetAutocompletePatientsListComplete', rsp);
    });
  }

  UploadMedia({ UserID, Caption, MediaType, ForAllPatient, PatientIDs, File }) {
    const data = new FormData();
    data.append('UserID', UserID);
    data.append('Caption', Caption);
    data.append('MediaType', MediaType);
    data.append('AccountID', AuthStore.getUserAccountID());
    data.append('ForAllPatient', ForAllPatient);
    data.append('PatientIDs', PatientIDs);
    data.append('File', File);

    return new Promise(resolve => {
      this.PicturesApi.UploadMedia(data, rsp => {
        this.emit('onUploadMediaComplete', rsp);
        resolve(rsp);
      });
    });
  }

  UpdateMediaCaption({ MediaID, Caption }) {
    console.log('UpdateMediaCaption ', MediaID, Caption);

    this.PicturesApi.UpdateMediaCaption({ MediaID, Caption }, rsp => {
      this.emit('onUpdateMediaCaptionComplete', rsp);
    });
  }

  DeleteMedia({ MediaID, UserID }) {
    this.PicturesApi.DeleteMedia({ AccountID: AuthStore.getUserAccountID(), MediaID, UserID }, rsp => {
      this.emit('onDeleteMediaComplete', rsp);
    });
  }

  saveToLocalStorage() {
    localStorage.PicturesArray = JSON.stringify(this.PicturesArray);
    localStorage.PicturesCount = JSON.stringify(this.PicturesCount);
  }
}

const carePicturesStore = new CarePicturesStore();

export default carePicturesStore;

import querystring from 'querystring';
import { startCall, endCall, validateCallUrlPromise } from '@nucleus-care/nucleuscare-backend-client';
import { NucleusSignaling, NucleusCommunicationSignaling, SignalingEvent, CallType } from '@nucleus-care/nucleuscare-connect-signaling';
import classnames from 'classnames';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AuthStore from '../../stores/AuthStore';
import CareCommunicationStore from '../../stores/CareCommunicationStore';
import Message from '../../utils/Message';
import { WebRTCCallView } from '../WebRTCCallView';
import css from './CallSession.module.css';
import { CallSessionDone } from './Done';
import { CallSessionError } from './Error';

const communicationSingleton = NucleusCommunicationSignaling.getInstance();
const signaling = NucleusSignaling.getInstance();

const ERROR_CODES = {
  MISSING_PARAMETERS: 'MISSING_PARAMETERS',
  UNAUTHORIZED: 'UNAUTHORIZED',
  UNEXPECTED: 'UNEXPECTED',
  NETWORK: 'NETWORK',
};

export default function CallSession() {
  const [errorCode, setErrorCode] = useState(null);
  const [isSignalingConnected, setIsSignalingConnected] = useState(false);
  const call = useSelector(state => (state.call ? state.call.call : null));
  const [customCall, setCustomCall] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const callLogID = useRef<string>();
  const callEstablished = useRef(false);
  const callAnswered = useRef(false);
  const callTimeouted = useRef(false);
  const localTimeout = useRef(null);
  console.log('CallSession mounted');

  const clearCallTimeout = () => {
    if (localTimeout.current != null) {
      clearTimeout(localTimeout.current);
    }
  };

  const hideCall = () => {
    window.postMessage('NUCLEUSCARE_HIDE_CALL');
    setCustomCall(null);
  };

  const clearCallSelector = () => {
    dispatch(endCall());
  };

  const reportCallEndedWithStatus = Status => {
    // Listen to "onReportCallEndedAction"
    if (call) {
      // refactor this to handle multicall
      CareCommunicationStore.reportCallEndedStatus({
        CallLogID: callLogID.current,
        PatientID: call.PatientID,
        Status,
      });
    }
  };

  const onCallStartedReportedAction = response => {
    console.log('onReportCallStartedAction == >', response, call);

    if (!response.ok) {
      clearCallTimeout();
      localTimeout.current = setTimeout(() => {
        handleCallTimeoutAction('There was a problem reporting the call status');
      }, 10000);
    }
  };

  const onSignalingStateChanged = state => {
    console.log('IS CONNECTED: ', state);
    setIsSignalingConnected(state);
  };

  useEffect(() => {
    const cleanup = () => {
      handleCloseCall();
    };
    document.addEventListener('beforeunload', cleanup);
    window.addEventListener('beforeunload', cleanup);
    window.addEventListener('unload', cleanup);
    return () => {
      document.removeEventListener('beforeunload', cleanup);
      window.removeEventListener('beforeunload', cleanup);
      window.addEventListener('unload', cleanup);
    };
  }, []);

  useEffect(() => {
    CareCommunicationStore.on('onCallStartedReported', onCallStartedReportedAction);

    return () => {
      CareCommunicationStore.removeListener('onCallStartedReported', onCallStartedReportedAction);
    };
  }, []);

  useEffect(() => {
    const { deviceIds = '' } = querystring.parse(location.search.slice(1));

    if (!deviceIds.length) {
      setErrorCode(ERROR_CODES.MISSING_PARAMETERS);
    } else {
      // connect to signaling
      const environment = (process.env.REACT_APP_SIGNALING_ENV || 'PROD') as 'DEV' | 'TEST' | 'PROD';
      signaling.setEnvironment(environment);

      signaling.addListener(SignalingEvent.SIGNALING_CONNECTION_STATE_CHANGED, onSignalingStateChanged);

      // if (!signaling.isConnectedToSignalingServer()) {
      //   // signaling.startSignaling("", AuthStore.getUserID(), AuthStore.getUserID());
      //   signaling.startSignaling(new SignalingId(''), new SignalingId(AuthStore.getUserID()), new SignalingId(AuthStore.getUserID()));
      // }
    }

    return () => {
      signaling.removeListener(SignalingEvent.SIGNALING_CONNECTION_STATE_CHANGED, onSignalingStateChanged);
    };
  }, [location.search]);

  useEffect(() => {
    if (isSignalingConnected) {
      const { deviceIds = '', type = CallType.Video } = querystring.parse(location.search.slice(1));
      const deviceIdList = deviceIds instanceof Array ? deviceIds : deviceIds.split(',');
      validateCallUrlPromise(deviceIdList)
        .then(({ data: devices }) => {
          // TODO: Refactor this to support multiple call participants
          const [device] = devices;
          const { FirstName, LastName, RequestID } = device;

          const tempCall = {
            RequestID: RequestID || '',
            PatientID: device.PatientID.toLowerCase(),
            PatientName: `${FirstName} ${LastName}`,
            PatientFirstName: FirstName,
            PatientLastName: LastName,
            DeviceID: device.DeviceID.toLowerCase(),
            DeviceName: device.Name,
            PatientThumb: '',
            isDeviceLegacy: undefined,
            isConnectDevice: device.ConnectDevice,
            type,
          };

          dispatch(startCall(tempCall));
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 403) {
              setErrorCode(ERROR_CODES.UNAUTHORIZED);
            }
            return;
          }
          setErrorCode(ERROR_CODES.NETWORK);
        });
    }
  }, [location.search, isSignalingConnected]);

  useEffect(() => {
    if (call) {
      callLogID.current = uuidv4();
      const { PatientFirstName, PatientLastName } = call;
      CareCommunicationStore.updateCallInProgress(true);

      let initials = '';

      if (PatientFirstName.length > 1) {
        initials = PatientFirstName.substring(0, 1);
      }
      if (PatientLastName.length > 1) {
        initials = initials + PatientLastName.substring(0, 1);
      }

      const customCall = {
        ...call,
        entityId: AuthStore.getUserID(),
        deviceId: AuthStore.getUserID(),
        user: AuthStore.getUser(),
        CallLogID: callLogID.current,
        participants: [
          {
            entityId: call.PatientID,
            deviceId: call.DeviceID,
            ...call,
            status: 'connected',
            Initials: initials,
          },
        ],
        status: 'connected',
        multiCall: false,
        callTimeoutSeconds: 62,
      };
      setCustomCall(customCall);

      clearCallTimeout();

      localTimeout.current = setTimeout(() => {
        if (communicationSingleton.currentCall != null) communicationSingleton.timeoutCurrentCall();
      }, 30000);
    }
  }, [call]);

  const handleCloseCall = () => {
    if (callEstablished.current) {
      if (callAnswered.current) {
        CareCommunicationStore.reportCallEnded({
          CallLogID: callLogID.current,
          WasAnswered: callAnswered.current,
          PatientID: call.PatientID,
          AverageAvailableKbps: 0,
        });
        Message.show('Call ended');
        if (communicationSingleton.isCallEstablished()) {
          communicationSingleton.endCurrentCall();
        }
      } else {
        try {
          communicationSingleton.cancelCurrentCall();
          handleCallCanceledAction(true);
        } catch (ex) {
          console.log('handleCloseCall CLOSE 5', ex);
        }
      }

      callEstablished.current = false;
    } else {
      communicationSingleton.cancelCurrentCall();
      handleCallCanceledAction(true);
    }

    setTimeout(() => {
      CareCommunicationStore.updateCallInProgress(false);
      CareCommunicationStore.refreshIncomingCallBarStatus();

      callAnswered.current = false;
    }, 1000);

    setTimeout(() => {
      hideCall();
    }, 1200);
    clearCallSelector();
  };

  const handleCallRejectedAction = () => {
    console.log('ESTABLISHED REJECTED', callEstablished.current);
    if (callEstablished.current) {
      reportCallEndedWithStatus(CareCommunicationStore.Status.DECLINED);
    } else {
      setTimeout(() => {
        hideCall();
      }, 1200);
    }
    clearCallSelector();

    setTimeout(() => {
      hideCall();
    }, 1000);
    CareCommunicationStore.updateCallInProgress(false);
    CareCommunicationStore.refreshIncomingCallBarStatus();

    Message.show('The call was declined');
  };

  const handleCallDisconnectedAction = () => {
    if (!callTimeouted.current) {
      console.log('handleCallDisconnectedAction DISCONNECTED', callEstablished.current);
      callLogID.current = null;

      if (callEstablished.current) {
        reportCallEndedWithStatus(CareCommunicationStore.Status.DISCONNECTED);
        callEstablished.current = false;

        if (communicationSingleton.currentCall != null) {
          communicationSingleton.endCurrentCall();
        }

        Message.show('Call Disconnected');
      }
      clearCallSelector();
      setTimeout(() => {
        callAnswered.current = false;
        CareCommunicationStore.updateCallInProgress(false);
        CareCommunicationStore.refreshIncomingCallBarStatus();
      }, 1000);

      setTimeout(() => {
        hideCall();
      }, 1200);
    }
  };

  const handleCallCanceledAction = dontClose => {
    console.log('handleCallCanceledAction');

    reportCallEndedWithStatus(CareCommunicationStore.Status.CANCELED);

    !dontClose &&
      setTimeout(() => {
        console.log('handleCallCanceledAction modalNucleusCallCom');
        CareCommunicationStore.updateCallInProgress(false);
        CareCommunicationStore.refreshIncomingCallBarStatus();
      }, 500);

    !dontClose &&
      setTimeout(() => {
        hideCall();
      }, 1200);
    clearCallSelector();
  };

  const handleCallTimeoutAction = (msg = 'The call was not answered') => {
    console.log('CallSession ESTABLISHED TIMEOUT', callEstablished.current);

    if (callEstablished.current) {
      console.log('SET VAR>>3');

      reportCallEndedWithStatus(CareCommunicationStore.Status.NOT_ANSWERED);
    }

    clearCallSelector();

    setTimeout(() => {
      hideCall();
    }, 1200);
    CareCommunicationStore.updateCallInProgress(false);
    CareCommunicationStore.refreshIncomingCallBarStatus();

    ////CallEstablished = false;
    Message.show(msg);
    callTimeouted.current = true;
  };

  const handleCallBusyAction = () => {
    console.log('CALL BUSY', callEstablished.current, call);

    setTimeout(() => {
      //Preventive timeout so the reportCallStarted never goes after the call busy
      reportCallEndedWithStatus(CareCommunicationStore.Status.BUSY);
    }, 100);

    setTimeout(() => {
      hideCall();
    }, 1200);
    CareCommunicationStore.updateCallInProgress(false);
    CareCommunicationStore.refreshIncomingCallBarStatus();

    Message.show('Sorry, ' + (customCall.PatientFirstName + ' - ' + customCall.DeviceName) + ' is currently busy with another call.');
    clearCallSelector();
  };

  const handleCallDoNotDisturbAction = () => {
    console.log('CALL DO_NOT_DISTURB', callEstablished.current, call);

    setTimeout(() => {
      //Preventive timeout so the reportCallStarted never goes after the call busy
      reportCallEndedWithStatus(CareCommunicationStore.Status.DO_NOT_DISTURB);
    }, 100);

    setTimeout(() => {
      hideCall();
    }, 1200);
    CareCommunicationStore.updateCallInProgress(false);
    CareCommunicationStore.refreshIncomingCallBarStatus();

    Message.show('Sorry, ' + (customCall.PatientFirstName + ' - ' + customCall.DeviceName) + ' is currently in Do Not Disturb mode.');
    clearCallSelector();
  };

  const handleCallFailedAction = () => {
    console.log('CALL FAILED', callEstablished.current);

    if (communicationSingleton) {
      communicationSingleton.endCurrentCall();
    }

    clearCallTimeout();
    clearCallSelector();

    setTimeout(() => {
      hideCall();
    }, 1200);
    reportCallEndedWithStatus(CareCommunicationStore.Status.FAILED);
    CareCommunicationStore.updateCallInProgress(false);
    CareCommunicationStore.refreshIncomingCallBarStatus();

    Message.show('Call Failed');
  };

  const reportCallStarted = () => {
    console.log('reportCallStarted ', call);

    callEstablished.current = true;
    // Refactor this to handle multicall
    CareCommunicationStore.reportCallStarted({
      DeviceID: call.DeviceID,
      CallLogID: callLogID.current,
      PatientID: call.PatientID,
      UserID: AuthStore.getUserID(),
      RequestID: call.RequestID,
      CallType: call.type,
    });
  };

  const reportCallEnded = wasAnswered => {
    console.log('reportCallEnded', wasAnswered);
    CareCommunicationStore.reportCallEnded({
      CallLogID: callLogID.current,
      WasAnswered: wasAnswered,
      PatientID: call.PatientID,
      AverageAvailableKbps: 0,
    });
  };

  const reportCallEstablished = () => {
    console.log('reportCallEstablished');
    console.log('reportCallEstablished requestID', call.RequestID);

    if (call.RequestID.length >= 32) {
      CareCommunicationStore.reportCallEstablished({
        RequestID: call.RequestID,
        UserID: AuthStore.getUserID(),
      });
    }

    CareCommunicationStore.reportCallInProgress({
      CallLogID: callLogID.current,
      entityId: AuthStore.getUserID(),
    });
  };

  const handleCallEstablishedAction = () => {
    callAnswered.current = true;

    reportCallEstablished();
  };

  const handleEndCall = () => {
    console.log('handleEndCall END', callEstablished.current);

    if (callEstablished.current) {
      console.log('SET VAR>>99');

      reportCallEnded(callAnswered.current);
      try {
        if (communicationSingleton.isCallEstablished()) {
          communicationSingleton.endCurrentCall();
        }
      } catch (ex) {
        console.log('handleCloseCall CLOSE 5', ex);
      }
      callEstablished.current = false;

      Message.show('Call ended');
      clearCallSelector();
    }

    setTimeout(() => {
      CareCommunicationStore.updateCallInProgress(false);
      CareCommunicationStore.refreshIncomingCallBarStatus();

      callAnswered.current = false;
    }, 1000);

    setTimeout(() => {
      hideCall();
    }, 1200);
  };

  if (errorCode && errorCode.length) {
    return <CallSessionError errorCode={errorCode} />;
  }

  if (!customCall) {
    return <CallSessionDone />;
  }

  return (
    <div
      className={classnames(css.container, {
        'nucleus-modal-call': !!call,
      })}
    >
      {customCall && (
        <WebRTCCallView
          call={customCall}
          {...{
            onCallRejected: handleCallRejectedAction,
            onCallDisconnected: handleCallDisconnectedAction,
            onCallCanceled: handleCallCanceledAction,
            onCallTimeout: handleCallTimeoutAction,
            onCallBusy: handleCallBusyAction,
            onCallDoNotDisturb: handleCallDoNotDisturbAction,
            onCallFailed: handleCallFailedAction,
            onCallStarted: reportCallStarted,
            onCallEstablished: handleCallEstablishedAction,
            CallLogID: '',
            onCallInitiated: () => {
              console.log('onCallInitiated');
            },
            onCallEnded: handleEndCall,
            onCallHandled: clearCallTimeout,
          }}
        />
      )}
    </div>
  );
}

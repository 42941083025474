//@ts-nocheck
import MomentUtils from '@date-io/moment';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';

import { AuthStore, CarePatientStore, GeneralStore } from 'stores';
import Message from '../../../utils/Message';

import PatientScheduleItemRow from './PatientScheduleItemRow';

class ScheduleView extends React.Component<{
  patientID: string;
}> {
  constructor(props) {
    super(props);
    this.state = {
      accountID: AuthStore.getUserAccountID(),
      patientID: this.props.patientID,

      patientScheduleArray: [],
      patientDefaultSchedule: true,
      //patientScheduleMode : 'Account Default Schedule',

      newScheduleButtonsStyle: '',

      selectedScheduleID: '',
      selectedScheduleSource: '',
      editScheduleButtonsStyle: '',

      selectedScheduleData: '',
      deleteScheduleBtnStyle: 'nucleus-submit-btn',

      newScheduleItemError: ' hide',
      editScheduleItemError: ' hide',

      defaultScheduleItems: 0,

      selectedTimezone: '',
      //pendingScheduleItems : [],
      //updateDefaultScheduleBtnStyle : 'nucleus-submit-btn',

      newScheduleFromTime: null,
      newScheduleFromTimeValue: '',

      newScheduleToTime: null,
      newScheduleToTimeValue: '',

      editScheduleFromTime: null,
      editScheduleFromTimeValue: '',

      editScheduleToTime: null,
      editScheduleToTimeValue: '',
    };

    this.onGetPatientScheduleAction = this.onGetPatientScheduleAction.bind(this);

    this.handleNewScheduleItem = this.handleNewScheduleItem.bind(this);
    this.handleCloseNewScheduleModal = this.handleCloseNewScheduleModal.bind(this);
    this.handleSaveNewSchedule = this.handleSaveNewSchedule.bind(this);
    this.onNewPatientScheduleAddedAction = this.onNewPatientScheduleAddedAction.bind(this);

    this.onOpenEditScheduleModalAction = this.onOpenEditScheduleModalAction.bind(this);
    this.handleCloseEditScheduleModal = this.handleCloseEditScheduleModal.bind(this);
    this.handleSaveEditSchedule = this.handleSaveEditSchedule.bind(this);
    this.onPatientScheduleEditedAction = this.onPatientScheduleEditedAction.bind(this);

    this.onOpenRemoveScheduleModalAction = this.onOpenRemoveScheduleModalAction.bind(this);
    this.handleCancelDeleteSchedule = this.handleCancelDeleteSchedule.bind(this);
    this.handleDeleteSchedule = this.handleDeleteSchedule.bind(this);
    this.onPatientScheduleRemovedAction = this.onPatientScheduleRemovedAction.bind(this);

    this.createPatientScheduleFromDefault = this.createPatientScheduleFromDefault.bind(this);
    this.onDefaultScheduleCopiedAction = this.onDefaultScheduleCopiedAction.bind(this);

    this.copyPatientScheduleFromDefault = this.copyPatientScheduleFromDefault.bind(this);
    this.onModifiedDefaultScheduleCopiedAction = this.onModifiedDefaultScheduleCopiedAction.bind(this);
    this.onModifiedPatientScheduleAddedAction = this.onModifiedPatientScheduleAddedAction.bind(this);

    this.copyCleanPatientScheduleFromDefault = this.copyCleanPatientScheduleFromDefault.bind(this);
    this.onFilteredDefaultScheduleCopiedAction = this.onFilteredDefaultScheduleCopiedAction.bind(this);
    this.onResetPatientScheduleCompleteAction = this.onResetPatientScheduleCompleteAction.bind(this);

    this.pendingScheduleItems = [];

    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    //this.newScheduleFromTimeRef = React.createRef();
    //this.newScheduleToTimeRef = React.createRef();

    //this.editScheduleFromTimeRef = React.createRef();
    //this.editScheduleToTimeRef = React.createRef();
  }

  componentDidMount() {
    CarePatientStore.on('onGetPatientSchedule', this.onGetPatientScheduleAction);
    CarePatientStore.on('onNewPatientScheduleAdded', this.onNewPatientScheduleAddedAction);

    CarePatientStore.on('onOpenEditScheduleModal', this.onOpenEditScheduleModalAction);
    CarePatientStore.on('onPatientScheduleEdited', this.onPatientScheduleEditedAction);

    CarePatientStore.on('onOpenRemoveScheduleModal', this.onOpenRemoveScheduleModalAction);
    CarePatientStore.on('onPatientScheduleRemoved', this.onPatientScheduleRemovedAction);

    CarePatientStore.on('onDefaultScheduleCopied', this.onDefaultScheduleCopiedAction);
    CarePatientStore.on('onModifiedDefaultScheduleCopied', this.onModifiedDefaultScheduleCopiedAction);
    CarePatientStore.on('onModifiedPatientScheduleAdded', this.onModifiedPatientScheduleAddedAction);
    CarePatientStore.on('onFilteredDefaultScheduleCopied', this.onFilteredDefaultScheduleCopiedAction);
    CarePatientStore.on('onResetPatientScheduleComplete', this.onResetPatientScheduleCompleteAction);

    this.setState({
      patientID: this.props.patientID,
    });

    CarePatientStore.getPatientSchedule({
      PatientID: this.props.patientID,
    });
    // Listen: onGetPatientSchedule
    // $('#newScheduleFromTime').datetimepicker({
    //     datepicker:false,
    //     format:'h:i A',
    //     formatTime:'h:i A',
    //     step:30,
    //     timepickerScrollbar : false,
    //     scrollInput: true,
    //     closeOnWithoutClick :true
    // }).on("change", (e) => {

    //     if ( this.refs.newScheduleToTime.value != ""){

    //         let dateOk = true;
    //         //console.log("Date changed: scheduleTime ", e.target.value, this.refs.newScheduleToTime.value);

    //         let fromDateTime = new Date(Date.parse('2018/07/19 ' + e.target.value));
    //         let toDateTime = new Date(Date.parse('2018/07/19 ' + this.refs.newScheduleToTime.value));

    //         // let toDateTime = new Date('1970-01-01T' + this.props.To);
    //         // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
    //         if (fromDateTime > toDateTime){
    //             dateOk = false;
    //         }
    //         //console.log("Las Fechas estan : ", dateOk);
    //         if (!dateOk){
    //             this.setState({
    //                 newScheduleItemError : '',
    //                 newScheduleButtonsStyle : '-disabled'
    //             });
    //         } else {
    //             this.setState({
    //                 newScheduleItemError : ' hide',
    //                 newScheduleButtonsStyle : ''
    //             });
    //         }
    //     }
    //     return true;
    // });

    // $('#newScheduleToTime').datetimepicker({
    //     datepicker:false,
    //     format:'h:i A',
    //     formatTime:'h:i A',
    //     step:30,
    //     timepickerScrollbar : false,
    //     scrollInput: true,
    //     closeOnWithoutClick :true
    // }).on("change", (e) => {

    //     if ( this.state.newScheduleFromTimeValue != ""){

    //         let dateOk = true;
    //         //console.log("Date changed: scheduleTime ", e.target.value, this.refs.newScheduleFromTime.value);

    //         let fromDateTime = new Date(Date.parse('2018/07/19 ' + this.state.newScheduleFromTimeValue));
    //         let toDateTime = new Date(Date.parse('2018/07/19 ' +  e.target.value));

    //         // let toDateTime = new Date('1970-01-01T' + this.props.To);
    //         // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
    //         if (fromDateTime > toDateTime){
    //             dateOk = false;
    //         }
    //         //console.log("Las Fechas estan : ", dateOk);
    //         if (!dateOk){
    //             this.setState({
    //                 newScheduleItemError : '',
    //                 newScheduleButtonsStyle : '-disabled'
    //             });
    //         } else {
    //             this.setState({
    //                 newScheduleItemError : ' hide',
    //                 newScheduleButtonsStyle : ''
    //             });
    //         }
    //     }

    //     return true;
    // });

    // $('#editScheduleFromTime').datetimepicker({
    //     datepicker:false,
    //     format:'h:i A',
    //     formatTime:'h:i A',
    //     step:30,
    //     timepickerScrollbar : false,
    //     scrollInput: true,
    //     closeOnWithoutClick :true
    // }).on("change", (e) => {

    //     if ( this.refs.editScheduleToTime.value != ""){

    //         let dateOk = true;
    //         //console.log("Date changed: scheduleTime ", e.target.value, this.refs.editScheduleToTime.value);

    //         let fromDateTime = new Date(Date.parse('2018/07/19 ' + e.target.value));
    //         let toDateTime = new Date(Date.parse('2018/07/19 ' + this.refs.editScheduleToTime.value));

    //         // let toDateTime = new Date('1970-01-01T' + this.props.To);
    //         // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
    //         if (fromDateTime > toDateTime){
    //             dateOk = false;
    //         }
    //         //console.log("Las Fechas estan : ", dateOk);
    //         if (!dateOk){
    //             this.setState({
    //                 editScheduleItemError : '',
    //                 editScheduleButtonsStyle : '-disabled'
    //             });
    //         } else {
    //             this.setState({
    //                 editScheduleItemError : ' hide',
    //                 editScheduleButtonsStyle : ''
    //             });
    //         }
    //     }
    //     this.refs.btnSaveEditSchedule.focus();
    //     return true;
    // });

    // $('#editScheduleToTime').datetimepicker({
    //     datepicker:false,
    //     format:'h:i A',
    //     formatTime:'h:i A',
    //     step:30,
    //     timepickerScrollbar : false,
    //     scrollInput: true,
    //     closeOnWithoutClick :true
    // }).on("change", (e) => {

    //     if ( this.state.editScheduleFromTimeValue != ""){

    //         let dateOk = true;
    //         //console.log("Date changed: scheduleTime ", e.target.value, this.refs.newScheduleFromTime.value);

    //         let fromDateTime = new Date(Date.parse('2018/07/19 ' + this.state.editScheduleFromTimeValue));
    //         let toDateTime = new Date(Date.parse('2018/07/19 ' +  e.target.value));

    //         // let toDateTime = new Date('1970-01-01T' + this.props.To);
    //         // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
    //         if (fromDateTime > toDateTime){
    //             dateOk = false;
    //         }
    //         //console.log("Las Fechas estan : ", dateOk);
    //         if (!dateOk){
    //             this.setState({
    //                 editScheduleItemError : '',
    //                 editScheduleButtonsStyle : '-disabled'
    //             });
    //         } else {
    //             this.setState({
    //                 editScheduleItemError : ' hide',
    //                 editScheduleButtonsStyle : ''
    //             });
    //         }
    //     }
    //     this.refs.btnSaveEditSchedule.focus();
    //     return true;
    // });
  }

  componentWillUnmount() {
    CarePatientStore.removeListener('onGetPatientSchedule', this.onGetPatientScheduleAction);
    CarePatientStore.removeListener('onNewPatientScheduleAdded', this.onNewPatientScheduleAddedAction);

    CarePatientStore.removeListener('onOpenEditScheduleModal', this.onOpenEditScheduleModalAction);
    CarePatientStore.removeListener('onPatientScheduleEdited', this.onPatientScheduleEditedAction);

    CarePatientStore.removeListener('onOpenRemoveScheduleModal', this.onOpenRemoveScheduleModalAction);
    CarePatientStore.removeListener('onPatientScheduleRemoved', this.onPatientScheduleRemovedAction);

    CarePatientStore.removeListener('onDefaultScheduleCopied', this.onDefaultScheduleCopiedAction);
    CarePatientStore.removeListener('onModifiedDefaultScheduleCopied', this.onModifiedDefaultScheduleCopiedAction);
    CarePatientStore.removeListener('onModifiedPatientScheduleAdded', this.onModifiedPatientScheduleAddedAction);

    CarePatientStore.removeListener('onFilteredDefaultScheduleCopied', this.onFilteredDefaultScheduleCopiedAction);
    CarePatientStore.removeListener('onResetPatientScheduleComplete', this.onResetPatientScheduleCompleteAction);
    //$('.tooltipped').tooltip('remove');
  }

  onGetPatientScheduleAction(response) {
    console.log('onGetPatientScheduleAction', response);

    this.setState({
      patientScheduleArray: [],
    });

    if (response.ok) {
      this.setState({
        patientScheduleArray: response.Schedule,
        patientDefaultSchedule: response.useDefaultSchedule,
        //patientScheduleMode : response.useDefaultSchedule ? 'Account Default Schedule' : 'Patient Schedule'
      });

      if (response.ScheduleTimezone != null && response.ScheduleTimezone != '') {
        const timezoneDescription = GeneralStore.getTimeZoneDescription(response.ScheduleTimezone);
        setTimeout(() => {
          //this.refs.selectScheduleTimezone.value = response.ScheduleTimezone;
          this.setState({
            selectedTimezone: timezoneDescription,
          });
        }, 90);
      } else {
        //this.refs.selectScheduleTimezone.value = "";
        this.setState({
          selectedTimezone: '',
        });
      }
    } else {
      Message.show('Error getting patient schedule, please try again.');
    }
  }

  handleNewScheduleItem() {
    this.setState({
      selectedScheduleID: '',
      newScheduleButtonsStyle: '',
      newScheduleItemError: ' hide',

      newScheduleFromTime: null,
      newScheduleFromTimeValue: '',

      newScheduleToTime: null,
      newScheduleToTimeValue: '',
    });

    $('#modalNewPatientSchedule').modal('open');

    this.refs.selectNewScheduleDay.value = '0';
    //this.refs.newScheduleFromTime.value = "";
    //this.refs.newScheduleToTime.value = "";

    this.pendingScheduleItems = this.state.patientScheduleArray.slice(0);
  }

  handleCloseNewScheduleModal() {
    if (this.state.newScheduleButtonsStyle == '-disabled') {
      return;
    }
    $('#modalNewPatientSchedule').modal('close');
  }

  onNewScheduleFromTimeChanged = time => {
    console.log('onNewScheduleFromTimeChanged', time);
    const timeString = moment(time).format('hh:mm A');
    this.setState({
      newScheduleFromTime: time,
      newScheduleFromTimeValue: timeString,
    });
    if (this.state.newScheduleToTimeValue != '') {
      let dateOk = true;
      //console.log("Date changed: scheduleTime ", e.target.value, this.refs.newScheduleToTime.value);

      const fromDateTime = new Date(Date.parse('2018/07/19 ' + timeString));
      const toDateTime = new Date(Date.parse('2018/07/19 ' + this.state.newScheduleToTimeValue));

      // let toDateTime = new Date('1970-01-01T' + this.props.To);
      // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
      if (fromDateTime > toDateTime) {
        dateOk = false;
      }
      //console.log("Las Fechas estan : ", dateOk);
      if (!dateOk) {
        this.setState({
          newScheduleItemError: '',
          newScheduleButtonsStyle: '-disabled',
        });
      } else {
        this.setState({
          newScheduleItemError: ' hide',
          newScheduleButtonsStyle: '',
        });
      }
    }
  };

  onNewScheduleToTimeChanged = time => {
    console.log('onNewScheduleToTimeChanged', time);
    const timeString = moment(time).format('hh:mm A');
    this.setState({
      newScheduleToTime: time,
      newScheduleToTimeValue: timeString,
    });
    if (this.state.newScheduleFromTimeValue != '') {
      let dateOk = true;
      //console.log("Date changed: scheduleTime ", e.target.value, this.refs.newScheduleFromTime.value);

      const fromDateTime = new Date(Date.parse('2018/07/19 ' + this.state.newScheduleFromTimeValue));
      const toDateTime = new Date(Date.parse('2018/07/19 ' + timeString));

      // let toDateTime = new Date('1970-01-01T' + this.props.To);
      // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
      if (fromDateTime > toDateTime) {
        dateOk = false;
      }
      //console.log("Las Fechas estan : ", dateOk);
      if (!dateOk) {
        this.setState({
          newScheduleItemError: '',
          newScheduleButtonsStyle: '-disabled',
        });
      } else {
        this.setState({
          newScheduleItemError: ' hide',
          newScheduleButtonsStyle: '',
        });
      }
    }
  };

  onEditScheduleFromTimeChanged = time => {
    console.log('onEditScheduleFromTimeChanged', time);
    const timeString = moment(time).format('hh:mm A');
    this.setState({
      editScheduleFromTime: time,
      editScheduleFromTimeValue: timeString,
    });
    if (this.state.editScheduleToTimeValue != '') {
      let dateOk = true;
      //console.log("Date changed: scheduleTime ", e.target.value, this.refs.editScheduleToTime.value);

      const fromDateTime = new Date(Date.parse('2018/07/19 ' + timeString));
      const toDateTime = new Date(Date.parse('2018/07/19 ' + this.state.editScheduleToTimeValue));

      // let toDateTime = new Date('1970-01-01T' + this.props.To);
      // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
      if (fromDateTime > toDateTime) {
        dateOk = false;
      }
      //console.log("Las Fechas estan : ", dateOk);
      if (!dateOk) {
        this.setState({
          editScheduleItemError: '',
          editScheduleButtonsStyle: '-disabled',
        });
      } else {
        this.setState({
          editScheduleItemError: ' hide',
          editScheduleButtonsStyle: '',
        });
      }
    }
  };

  onEditScheduleToTimeChanged = time => {
    console.log('onEditScheduleToTimeChanged', time);
    const timeString = moment(time).format('hh:mm A');
    this.setState({
      editScheduleToTime: time,
      editScheduleToTimeValue: timeString,
    });

    if (this.state.editScheduleFromTimeValue != '') {
      let dateOk = true;
      //console.log("Date changed: scheduleTime ", e.target.value, this.refs.newScheduleFromTime.value);

      const fromDateTime = new Date(Date.parse('2018/07/19 ' + this.state.editScheduleFromTimeValue));
      const toDateTime = new Date(Date.parse('2018/07/19 ' + timeString));

      // let toDateTime = new Date('1970-01-01T' + this.props.To);
      // let toTime  =  toDateTime.toLocaleTimeString('en-US', this.timeOptions);
      if (fromDateTime > toDateTime) {
        dateOk = false;
      }
      //console.log("Las Fechas estan : ", dateOk);
      if (!dateOk) {
        this.setState({
          editScheduleItemError: '',
          editScheduleButtonsStyle: '-disabled',
        });
      } else {
        this.setState({
          editScheduleItemError: ' hide',
          editScheduleButtonsStyle: '',
        });
      }
    }
  };

  handleSaveNewSchedule() {
    if (this.state.newScheduleFromTimeValue == '') {
      Message.show('Please select From Time');
      //this.newScheduleFromTimeRef.current.focus = true;
      return;
    }
    if (this.state.newScheduleToTimeValue == '') {
      Message.show('Please select To Time');
      //this.newScheduleToTimeRef.current.focus();
      return;
    }

    this.setState({
      newScheduleButtonsStyle: '-disabled',
    });
    console.log('Is Default ', this.state.patientDefaultSchedule);

    if (this.state.patientDefaultSchedule) {
      // this.setState({
      //     pendingScheduleItems : this.state.patientScheduleArray.slice(0)
      // });

      this.createPatientScheduleFromDefault();
    } else {
      CarePatientStore.addNewSchedule({
        PatientID: this.props.patientID,
        Day: this.refs.selectNewScheduleDay.value,
        From: this.state.newScheduleFromTimeValue,
        To: this.state.newScheduleToTimeValue,
      });
      //Action: onNewPatientScheduleAdded
    }
  }
  onNewPatientScheduleAddedAction(response) {
    console.log('onNewPatientScheduleAddedAction', response);
    $('#modalNewPatientSchedule').modal('close');

    this.setState({
      newScheduleButtonsStyle: '',
    });

    if (response.ok) {
      Message.show('Schedule Item added.');

      CarePatientStore.getPatientSchedule({
        PatientID: this.props.patientID,
      });
    } else {
      Message.show('Error adding new Schedule Item ');
    }
  }

  // EDIT EXISTING SCHEDULE
  onOpenEditScheduleModalAction(scheduleInfo) {
    console.log('onOpenEditScheduleModalAction', scheduleInfo);

    this.refs.selectEditScheduleDay.value = scheduleInfo.ScheduleDay;
    this.state.editScheduleFromTime = new Date(Date.parse('2018/07/19 ' + scheduleInfo.ScheduleFrom));
    this.state.editScheduleToTime = new Date(Date.parse('2018/07/19 ' + scheduleInfo.ScheduleTo));
    this.state.editScheduleFromTimeValue = moment(this.state.editScheduleFromTime).format('hh:mm A');
    this.state.editScheduleToTimeValue = moment(this.state.editScheduleToTime).format('hh:mm A');

    $('#modalEditPatientSchedule').modal('open');

    this.setState({
      selectedScheduleID: scheduleInfo.ScheduleID,
      selectedScheduleSource: scheduleInfo.ScheduleSource,
      editScheduleItemError: ' hide',
      editScheduleButtonsStyle: '',
    });

    this.pendingScheduleItems = this.state.patientScheduleArray.slice(0);
  }

  handleCloseEditScheduleModal() {
    if (this.state.editScheduleButtonsStyle == '-disabled') {
      return;
    }
    $('#modalEditPatientSchedule').modal('close');
  }

  handleSaveEditSchedule() {
    if (this.state.editScheduleFromTimeValue == '') {
      Message.show('Please select From Time');
      //this.refs.editScheduleFromTime.focus();
      return;
    }
    if (this.state.editScheduleToTimeValue == '') {
      Message.show('Please select To Time');
      //this.refs.editScheduleToTime.focus();
      return;
    }

    this.setState({
      editScheduleButtonsStyle: '-disabled',
    });

    if (this.state.patientDefaultSchedule) {
      this.copyPatientScheduleFromDefault();
    } else {
      CarePatientStore.editSchedule({
        ID: this.state.selectedScheduleID,
        Day: this.refs.selectEditScheduleDay.value,
        From: this.state.editScheduleFromTimeValue,
        To: this.state.editScheduleToTimeValue,
      });
      // Action: onPatientScheduleEdited
    }
  }
  onPatientScheduleEditedAction(response) {
    console.log('onPatientScheduleEditedAction', response);

    $('#modalEditPatientSchedule').modal('close');

    this.setState({
      editScheduleButtonsStyle: '',
    });

    if (response.ok) {
      Message.show('Schedule Item updated.');

      CarePatientStore.getPatientSchedule({
        PatientID: this.props.patientID,
      });
    } else {
      Message.show('Error updating Schedule Item ');
    }
  }

  // REMOVE SCHEDULE ITEM
  onOpenRemoveScheduleModalAction(scheduleInfo) {
    console.log('onOpenRemoveScheduleModalAction', scheduleInfo);

    $('#modalDeletePatientSchedule').modal('open');

    let dayName = 'Sunday';
    switch (scheduleInfo.ScheduleDay) {
      case '0':
        dayName = 'Sunday';
        break;
      case '1':
        dayName = 'Monday';
        break;
      case '2':
        dayName = 'Tuesday';
        break;
      case '3':
        dayName = 'Wednesday';
        break;
      case '4':
        dayName = 'Thursday';
        break;
      case '5':
        dayName = 'Friday';
        break;
      case '6':
        dayName = 'Saturday';
        break;
    }

    this.setState({
      selectedScheduleID: scheduleInfo.ScheduleID,
      selectedScheduleData: dayName + ', ' + scheduleInfo.ScheduleFrom + ' - ' + scheduleInfo.ScheduleTo,
      deleteScheduleBtnStyle: 'nucleus-submit-btn',
    });

    this.pendingScheduleItems = this.state.patientScheduleArray.slice(0);
  }

  handleCancelDeleteSchedule() {
    $('#modalDeletePatientSchedule').modal('close');
  }

  handleDeleteSchedule() {
    this.setState({
      deleteScheduleBtnStyle: 'nucleus-submit-btn-disabled',
    });

    if (this.state.patientDefaultSchedule) {
      this.copyCleanPatientScheduleFromDefault();
    } else {
      CarePatientStore.removeSchedule({
        ID: this.state.selectedScheduleID,
      });
      // Action: onPatientScheduleRemoved
    }
  }
  onPatientScheduleRemovedAction(response) {
    console.log('onPatientScheduleRemovedAction', response);

    $('#modalDeletePatientSchedule').modal('close');

    this.setState({
      deleteScheduleBtnStyle: 'nucleus-submit-btn',
    });

    if (response.ok) {
      Message.show('Schedule Item removed.');

      CarePatientStore.getPatientSchedule({
        PatientID: this.props.patientID,
      });
    } else {
      Message.show('Error removing Schedule Item ');
    }
  }

  // Methods
  createPatientScheduleFromDefault() {
    console.log('createPatientScheduleFromDefault ', this.pendingScheduleItems.length);

    if (this.pendingScheduleItems.length > 0) {
      const pendingSchedule = this.pendingScheduleItems[0];
      console.log('pendingSchedule ', pendingSchedule);

      this.pendingScheduleItems = this.pendingScheduleItems.slice(1);

      CarePatientStore.copyDefaultSchedule({
        PatientID: this.props.patientID,
        Day: pendingSchedule.DayOfWeek,
        From: pendingSchedule.FromTime,
        To: pendingSchedule.ToTime,
      });
    } else {
      CarePatientStore.addNewSchedule({
        PatientID: this.props.patientID,
        Day: this.refs.selectNewScheduleDay.value,
        From: this.state.newScheduleFromTimeValue,
        To: this.state.newScheduleToTimeValue,
      });
      //console.log("ORIGINAL ", this.state.patientScheduleArray);
    }
  }
  onDefaultScheduleCopiedAction(response) {
    console.log('onDefaultScheduleCopiedAction ', response);

    if (response.ok) {
      this.createPatientScheduleFromDefault();
    } else {
      this.setState({
        newScheduleButtonsStyle: '',
      });
      Message.show('Error adding new Schedule Item ');
    }
  }

  copyPatientScheduleFromDefault() {
    console.log('copyPatientScheduleFromDefault ', this.pendingScheduleItems.length);

    if (this.pendingScheduleItems.length > 0) {
      const pendingSchedule = this.pendingScheduleItems[0];
      console.log('pendingSchedule ', pendingSchedule);

      this.pendingScheduleItems = this.pendingScheduleItems.slice(1);

      if (pendingSchedule.ID == this.state.selectedScheduleID) {
        this.copyPatientScheduleFromDefault();
      } else {
        CarePatientStore.copyModifiedDefaultSchedule({
          PatientID: this.props.patientID,
          Day: pendingSchedule.DayOfWeek,
          From: pendingSchedule.FromTime,
          To: pendingSchedule.ToTime,
        });
      }
    } else {
      CarePatientStore.addModifiedSchedule({
        PatientID: this.props.patientID,
        Day: this.refs.selectEditScheduleDay.value,
        From: this.state.editScheduleFromTimeValue,
        To: this.state.editScheduleToTimeValue,
      });
    }
  }

  onModifiedDefaultScheduleCopiedAction(response) {
    console.log('onModifiedDefaultScheduleCopiedAction ', response);

    if (response.ok) {
      this.copyPatientScheduleFromDefault();
    } else {
      this.setState({
        editScheduleButtonsStyle: '',
      });
      Message.show('Error updating Schedule Item ');
    }
  }

  onModifiedPatientScheduleAddedAction(response) {
    console.log('onModifiedPatientScheduleAddedAction', response);

    $('#modalEditPatientSchedule').modal('close');

    this.setState({
      editScheduleButtonsStyle: '',
    });

    if (response.ok) {
      Message.show('Schedule Item updated.');

      CarePatientStore.getPatientSchedule({
        PatientID: this.props.patientID,
      });
    } else {
      Message.show('Error updating Schedule Item ');
    }
  }

  copyCleanPatientScheduleFromDefault() {
    console.log('copyCleanPatientScheduleFromDefault ', this.pendingScheduleItems.length);

    if (this.pendingScheduleItems.length > 0) {
      const pendingSchedule = this.pendingScheduleItems[0];

      this.pendingScheduleItems = this.pendingScheduleItems.slice(1);

      if (pendingSchedule.ID == this.state.selectedScheduleID) {
        if (this.pendingScheduleItems.length == 0) {
          CarePatientStore.resetPatientUseSchedule({
            PatientID: this.props.patientID,
          });
        } else {
          this.copyCleanPatientScheduleFromDefault();
        }
      } else {
        CarePatientStore.copyFilteredDefaultSchedule({
          PatientID: this.props.patientID,
          Day: pendingSchedule.DayOfWeek,
          From: pendingSchedule.FromTime,
          To: pendingSchedule.ToTime,
        });
        //onFilteredDefaultScheduleCopied
      }
    } else {
      $('#modalDeletePatientSchedule').modal('close');

      this.setState({
        deleteScheduleBtnStyle: 'nucleus-submit-btn',
      });
      Message.show('Schedule Item removed.');

      CarePatientStore.getPatientSchedule({
        PatientID: this.props.patientID,
      });
    }
  }

  onFilteredDefaultScheduleCopiedAction(response) {
    console.log('onFilteredDefaultScheduleCopiedAction ', response);

    if (response.ok) {
      this.copyCleanPatientScheduleFromDefault();
    } else {
      this.setState({
        deleteScheduleBtnStyle: '',
      });
      Message.show('Error removing Schedule Item ');
    }
  }

  onResetPatientScheduleCompleteAction() {
    this.copyCleanPatientScheduleFromDefault();
  }

  render() {
    const patientScheduleList = [];
    if (this.state.patientScheduleArray != null && this.state.patientScheduleArray.length > 0) {
      this.state.patientScheduleArray.map((schedule, key) => {
        patientScheduleList.push(
          <PatientScheduleItemRow
            key={key}
            ID={schedule.ID}
            Timezone={schedule.Timezone}
            Day={schedule.DayOfWeek}
            From={schedule.FromTime}
            To={schedule.ToTime}
            Source={schedule.Source}
          />,
        );
      });
    }
    const patientScheduleMode = this.state.patientDefaultSchedule ? 'Account Default Schedule' : 'Patient Schedule';

    return (
      <div className="">
        <div className="row">
          <div className="col s12 m6 left-align">
            <div className="row vbottom-align no-margin">
              <div className="col s12 no-margin">
                <span className="nucleus-labels-small span-text-bottom">Schedule Timezone: {this.state.selectedTimezone} </span>
              </div>
              <div className="col s0 no-margin control-panels-container">
                {/*
                                <select ref="selectScheduleTimezone" className="browser-default accountSelect100 height30" disabled="disabled" onChange={this.handleChangeScheduleTimezone}>
                                    <option value="" disabled selected> { "Select a Timezone" } </option>
                                    <option value="Morocco Standard Time">(GMT) Casablanca</option>
                                    <option value="GMT Standard Time">(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                                    <option value="Greenwich Standard Time">(GMT) Monrovia, Reykjavik</option>
                                    <option value="W. Europe Standard Time">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                                    <option value="Central Europe Standard Time">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                                    <option value="Romance Standard Time">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                    <option value="Central European Standard Time">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                                    <option value="W. Central Africa Standard Time">(GMT+01:00) West Central Africa</option>
                                    <option value="Jordan Standard Time">(GMT+02:00) Amman</option>
                                    <option value="GTB Standard Time">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                                    <option value="Middle East Standard Time">(GMT+02:00) Beirut</option>
                                    <option value="Egypt Standard Time">(GMT+02:00) Cairo</option>
                                    <option value="South Africa Standard Time">(GMT+02:00) Harare, Pretoria</option>
                                    <option value="FLE Standard Time">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                                    <option value="Israel Standard Time">(GMT+02:00) Jerusalem</option>
                                    <option value="E. Europe Standard Time">(GMT+02:00) Minsk</option>
                                    <option value="Namibia Standard Time">(GMT+02:00) Windhoek</option>
                                    <option value="Arabic Standard Time">(GMT+03:00) Baghdad</option>
                                    <option value="Arab Standard Time">(GMT+03:00) Kuwait, Riyadh</option>
                                    <option value="Russian Standard Time">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                    <option value="E. Africa Standard Time">(GMT+03:00) Nairobi</option>
                                    <option value="Georgian Standard Time">(GMT+03:00) Tbilisi</option>
                                    <option value="Iran Standard Time">(GMT+03:30) Tehran</option>
                                    <option value="Arabian Standard Time">(GMT+04:00) Abu Dhabi, Muscat</option>
                                    <option value="Azerbaijan Standard Time">(GMT+04:00) Baku</option>
                                    <option value="Mauritius Standard Time">(GMT+04:00) Port Louis</option>
                                    <option value="Caucasus Standard Time">(GMT+04:00) Yerevan</option>
                                    <option value="Afghanistan Standard Time">(GMT+04:30) Kabul</option>
                                    <option value="Ekaterinburg Standard Time">(GMT+05:00) Ekaterinburg</option>
                                    <option value="Pakistan Standard Time">(GMT+05:00) Islamabad, Karachi</option>
                                    <option value="West Asia Standard Time">(GMT+05:00) Tashkent</option>
                                    <option value="India Standard Time">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                    <option value="Sri Lanka Standard Time">(GMT+05:30) Sri Jayawardenepura</option>
                                    <option value="Nepal Standard Time">(GMT+05:45) Kathmandu</option>
                                    <option value="N. Central Asia Standard Time">(GMT+06:00) Almaty, Novosibirsk</option>
                                    <option value="Central Asia Standard Time">(GMT+06:00) Astana, Dhaka</option>
                                    <option value="Myanmar Standard Time">(GMT+06:30) Yangon (Rangoon)</option>
                                    <option value="SE Asia Standard Time">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                    <option value="North Asia Standard Time">(GMT+07:00) Krasnoyarsk</option>
                                    <option value="China Standard Time">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                    <option value="North Asia East Standard Time">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                    <option value="Singapore Standard Time">(GMT+08:00) Kuala Lumpur, Singapore</option>
                                    <option value="W. Australia Standard Time">(GMT+08:00) Perth</option>
                                    <option value="Taipei Standard Time">(GMT+08:00) Taipei</option>
                                    <option value="Tokyo Standard Time">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                    <option value="Korea Standard Time">(GMT+09:00) Seoul</option>
                                    <option value="Yakutsk Standard Time">(GMT+09:00) Yakutsk</option>
                                    <option value="Cen. Australia Standard Time">(GMT+09:30) Adelaide</option>
                                    <option value="AUS Central Standard Time">(GMT+09:30) Darwin</option>
                                    <option value="E. Australia Standard Time">(GMT+10:00) Brisbane</option>
                                    <option value="AUS Eastern Standard Time">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                                    <option value="West Pacific Standard Time">(GMT+10:00) Guam, Port Moresby</option>
                                    <option value="Tasmania Standard Time">(GMT+10:00) Hobart</option>
                                    <option value="Vladivostok Standard Time">(GMT+10:00) Vladivostok</option>
                                    <option value="Central Pacific Standard Time">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                                    <option value="New Zealand Standard Time">(GMT+12:00) Auckland, Wellington</option>
                                    <option value="Fiji Standard Time">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                                    <option value="Tonga Standard Time">(GMT+13:00) Nuku'alofa</option>
                                    <option value="Azores Standard Time">(GMT-01:00) Azores</option>
                                    <option value="Cape Verde Standard Time">(GMT-01:00) Cape Verde Is.</option>
                                    <option value="Mid-Atlantic Standard Time">(GMT-02:00) Mid-Atlantic</option>
                                    <option value="E. South America Standard Time">(GMT-03:00) Brasilia</option>
                                    <option value="Argentina Standard Time">(GMT-03:00) Buenos Aires</option>
                                    <option value="SA Eastern Standard Time">(GMT-03:00) Georgetown</option>
                                    <option value="Greenland Standard Time">(GMT-03:00) Greenland</option>
                                    <option value="Montevideo Standard Time">(GMT-03:00) Montevideo</option>
                                    <option value="Newfoundland Standard Time">(GMT-03:30) Newfoundland</option>
                                    <option value="Atlantic Standard Time">(GMT-04:00) Atlantic Time (Canada)</option>
                                    <option value="SA Western Standard Time">(GMT-04:00) La Paz</option>
                                    <option value="Central Brazilian Standard Time">(GMT-04:00) Manaus</option>
                                    <option value="Pacific SA Standard Time">(GMT-04:00) Santiago</option>
                                    <option value="Venezuela Standard Time">(GMT-04:30) Caracas</option>
                                    <option value="SA Pacific Standard Time">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                                    <option value="Eastern Standard Time">(GMT-05:00) Eastern Time (US & Canada)</option>
                                    <option value="US Eastern Standard Time">(GMT-05:00) Indiana (East)</option>
                                    <option value="Central America Standard Time">(GMT-06:00) Central America</option>
                                    <option value="Central Standard Time">(GMT-06:00) Central Time (US & Canada)</option>
                                    <option value="Central Standard Time (Mexico)">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                                    <option value="Canada Central Standard Time">(GMT-06:00) Saskatchewan</option>
                                    <option value="US Mountain Standard Time">(GMT-07:00) Arizona</option>
                                    <option value="Mountain Standard Time (Mexico)">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                                    <option value="Mountain Standard Time">(GMT-07:00) Mountain Time (US & Canada)</option>
                                    <option value="Pacific Standard Time">(GMT-08:00) Pacific Time (US & Canada)</option>
                                    <option value="Pacific Standard Time (Mexico)">(GMT-08:00) Tijuana, Baja California</option>
                                    <option value="Alaskan Standard Time">(GMT-09:00) Alaska</option>
                                    <option value="Hawaiian Standard Time">(GMT-10:00) Hawaii</option>
                                    <option value="Samoa Standard Time">(GMT-11:00) Midway Island, Samoa</option>
                                    <option value="Dateline Standard Time">(GMT-12:00) International Date Line West</option>
                                    
                                </select>
                                &nbsp;&nbsp;&nbsp;*/}
              </div>
            </div>
          </div>
          <div className="col s12 m6 right-align">
            <label className=" nucleus-labels-bold">{patientScheduleMode}</label>
          </div>
        </div>

        <div className="row">
          <div className="col s12 m6">
            <table className="bordered highlight nucleus-table">
              <thead>
                <tr>
                  <th className="table-col-25">
                    <span className="nucleus-table-header">Day</span>
                  </th>
                  <th className="table-col-25">
                    <span className="nucleus-table-header">From</span>
                  </th>
                  <th className="table-col-25">
                    <span className="nucleus-table-header">To</span>
                  </th>
                  <th className="table-col-25 center-align">
                    <span className="nucleus-table-header">
                      {/* 
                                        <span className="nucleus-table-icon nucleus-link tooltipped" data-position="top" data-tooltip="Edit">
                                            <i className="Tiny material-icons">edit</i>
                                        </span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className="nucleus-table-icon nucleus-link tooltipped" data-position="top" data-tooltip="Remove">
                                            <i className="Tiny material-icons">not_interested</i>
                                        </span>
                                        */}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>{patientScheduleList}</tbody>
            </table>
          </div>
          <div className="col s12 m6"></div>
        </div>

        <div className="fixed-action-btn btn-add-new">
          <a
            className="btn-floating btn-large waves-effect waves-light orange tooltipped nucleus-floating-button"
            data-position="left"
            data-delay="50"
            data-tooltip="New Schedule"
            onClick={this.handleNewScheduleItem}
          >
            <i className="material-icons nucleus-floating-icon">add</i>
          </a>
          &nbsp;
        </div>

        <div id="modalNewPatientSchedule" className="modal modalPatientProfile modalScrollableNotWide">
          <div className="modal-content modal-content-delete center-align">
            <h3 className="nucleus-page-subtitle">Add Schedule Item</h3>
            <br />
            <br />
            <br />

            <div className="row center-align">
              <div className="col s2"></div>
              <div className="col s3 left-align">
                <span className="nucleus-labels">
                  <b>Day:</b>
                </span>
              </div>
              <div className="col s6 left-align">
                {/* <input ref="selectNewAccountButtonsType" type="text" className="validate nucleus-search"/><br/> */}
                <select defaultValue={'0'} ref="selectNewScheduleDay" className="browser-default height30">
                  <option value="0">Sunday</option>
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                </select>
              </div>
              <div className="col s1"></div>
            </div>

            <div className="row center-align">
              <div className="col s2"></div>
              <div className="col s3 left-align">
                <span className="nucleus-labels">
                  <b>From:</b>
                </span>
              </div>
              <div className="col s6 left-align">
                {/* <input 
                                    id="newScheduleFromTime" 
                                    ref="newScheduleFromTime" 
                                    type="text" 
                                    className="validate nucleus-date-picker" 
                                    placeholder="Pick a Time"/> */}
                <div style={{ width: '100%', display: 'inline-flex', backgroundColor: 'white', paddingLeft: 10 }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      //variant="dialog"
                      //ref={this.newScheduleFromTimeRef}
                      className="browser-default"
                      value={this.state.newScheduleFromTime}
                      onChange={date => {
                        this.onNewScheduleFromTimeChanged(date);
                      }}
                      emptyLabel="Pick a Time"
                      onError={console.log}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="col s1"></div>
            </div>

            <div className="row center-align">
              <div className="col s2"></div>
              <div className="col s3 left-align">
                <span className="nucleus-labels">
                  <b>To:</b>
                </span>
              </div>
              <div className="col s6 left-align">
                {/* <input 
                                    id="newScheduleToTime" 
                                    ref="newScheduleToTime" 
                                    type="text" 
                                    className="validate nucleus-date-picker" 
                                    placeholder="Pick a Time"/> */}

                <div style={{ width: '100%', display: 'inline-flex', backgroundColor: 'white', paddingLeft: 10 }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      //variant="dialog"
                      //ref={this.newScheduleToTimeRef}
                      className="browser-default"
                      value={this.state.newScheduleToTime}
                      onChange={date => {
                        this.onNewScheduleToTimeChanged(date);
                      }}
                      emptyLabel="Pick a Time"
                      onError={console.log}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="col s1"></div>
            </div>

            <span class={'nucleus-form-error center-align ' + this.state.newScheduleItemError}>Please select a 'To Time' greater than 'From Time'.</span>

            <br />
            <br />
            <div className="row ">
              <div className="col s12 center-align ">
                <div className="col s6 right-align">
                  <a className="txt-white nucleus-font-small nucleus-link " onClick={this.handleCloseNewScheduleModal}>
                    Cancel
                  </a>
                </div>
                <div className="col s6 left-align">
                  <a class={'txt-white nucleus-font-small nucleus-submit-btn-small' + this.state.newScheduleButtonsStyle} onClick={this.handleSaveNewSchedule}>
                    Confirm
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* id="modalPatientEditButton" className="modal modalPatientProfile modalScrollableNotWide */}

        <div id="modalEditPatientSchedule" className="modal  modalPatientProfile modalScrollableNotWide">
          <div className="modal-content modal-content-delete center-align">
            <h3 className="nucleus-page-subtitle">Edit Schedule Item</h3>
            <br />
            <br />
            <br />

            <div className="row center-align">
              <div className="col s2"></div>
              <div className="col s3 left-align">
                <span className="nucleus-labels">
                  <b>Day:</b>
                </span>
              </div>
              <div className="col s6 left-align">
                {/* <input ref="selectNewAccountButtonsType" type="text" className="validate nucleus-search"/><br/> */}
                <select defaultValue={'0'} ref="selectEditScheduleDay" className="browser-default height30">
                  <option value="0">Sunday</option>
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                </select>
              </div>
              <div className="col s1"></div>
            </div>

            <div className="row center-align">
              <div className="col s2"></div>
              <div className="col s3 left-align">
                <span className="nucleus-labels">
                  <b>From:</b>
                </span>
              </div>
              <div className="col s6 left-align">
                {/* <input 
                                    id="editScheduleFromTime" 
                                    ref="editScheduleFromTime" 
                                    type="text" 
                                    className="validate nucleus-date-picker" 
                                    placeholder="Pick a Time"/> */}
                <div style={{ width: '100%', display: 'inline-flex', backgroundColor: 'white', paddingLeft: 10 }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      //variant="dialog"
                      //ref={this.editScheduleFromTimeRef}
                      className="browser-default"
                      value={this.state.editScheduleFromTime}
                      onChange={date => {
                        this.onEditScheduleFromTimeChanged(date);
                      }}
                      emptyLabel="Pick a Time"
                      onError={console.log}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="col s1"></div>
            </div>

            <div className="row center-align">
              <div className="col s2"></div>
              <div className="col s3 left-align">
                <span className="nucleus-labels">
                  <b>To:</b>
                </span>
              </div>
              <div className="col s6 left-align">
                {/* <input 
                                    id="editScheduleToTime" 
                                    ref="editScheduleToTime" 
                                    type="text" 
                                    className="validate nucleus-date-picker" 
                                    placeholder="Pick a Time"/> */}
                <div style={{ width: '100%', display: 'inline-flex', backgroundColor: 'white', paddingLeft: 10 }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      //variant="dialog"
                      //ref={this.editScheduleToTimeRef}
                      className="browser-default"
                      value={this.state.editScheduleToTime}
                      onChange={date => {
                        this.onEditScheduleToTimeChanged(date);
                      }}
                      emptyLabel="Pick a Time"
                      onError={console.log}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="col s1"></div>
            </div>

            <span class={'nucleus-form-error center-align ' + this.state.editScheduleItemError}>Please select a 'To Time' greater than 'From Time'.</span>

            <br />
            <br />
            <div className="row ">
              <div className="col s12 center-align ">
                <div className="col s6 right-align">
                  <a className="txt-white nucleus-font-small nucleus-link " onClick={this.handleCloseEditScheduleModal}>
                    Cancel
                  </a>
                </div>
                <div className="col s6 left-align">
                  <a
                    ref="btnSaveEditSchedule"
                    class={'txt-white nucleus-font-small nucleus-submit-btn-small' + this.state.editScheduleButtonsStyle}
                    onClick={this.handleSaveEditSchedule}
                  >
                    Save
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalDeletePatientSchedule" className="modal modalPatientProfile modalScrollableNotWide">
          <div className="modal-content nucleus-modal-wrapper-scrollable2">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCancelDeleteSchedule} />
            <h3 className="nucleus-page-subtitle"> &nbsp;&nbsp;Delete Schedule Item </h3>
            <br />
            <br />
            <div className="row">
              <div className="col s12">
                <span className="nucleus-labels"> Are you sure you want to remove this schedule item from the {AuthStore.getPatientLabel()} ? </span>
                <br />
                <span className="nucleus-labels"> &nbsp;&nbsp;&nbsp;&nbsp; · {this.state.selectedScheduleData} </span>
              </div>
            </div>
            <div className="input-field col s12 modalCallLogContent2">
              <br />
              <div className="row no-margin">
                <div className="col s12 center-align no-margin">
                  <p></p>
                  <span className=" modalSeparator">
                    {' '}
                    <label> &nbsp;</label>
                  </span>
                  <a class={'txt-white nucleus-font-small ' + this.state.deleteScheduleBtnStyle} onClick={this.handleDeleteSchedule}>
                    Delete
                  </a>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ScheduleView;

import { EventEmitter } from 'events';
//import Dispatcher from "../Dispatcher";
//import Config from "../Config";
import UserApi from '../apis/UserApi';
import { getInstance } from '../utils/auth-client';

class CareUserStore extends EventEmitter {
  private UserApi: UserApi;
  private UsersList: any;
  private UserName: string;
  private UserAllPatientsAccess: string;
  private CareUserID: string;

  constructor() {
    super();

    this.UserApi = new UserApi(this, getInstance());
    this.UsersList = localStorage.UsersList;
    this.UserName = localStorage.UserName || 'Coordinator';
    this.UserAllPatientsAccess = localStorage.UserAllPatientsAccess || false;
    //console.warn(this);
  }

  getAllUsers() {
    return this.UsersList;
  }

  setCurrentUserName(userName) {
    this.UserName = userName;
    this.saveToLocalStorage();
  }
  getCurrentUserName() {
    return this.UserName;
  }

  setUserAllPatientsAccess(access) {
    this.UserAllPatientsAccess = access;
    this.saveToLocalStorage();
  }
  getUserAllPatientsAccess() {
    return this.UserAllPatientsAccess === 'true';
  }

  // Not used anymore in the UserProfile, now its used the method getUserData with "getUserConfigPromise" from backend-client
  // This is used in the Settings page
  getUserData({ UserID }) {
    console.log('getUserData ', UserID);

    this.UserApi.GetCareUserData({ UserID }, rsp => {
      //console.log("Nucleus Response", rsp);
      if (rsp.ok) {
        this.CareUserID = UserID;
      }
      this.emit('onUserGetData', rsp);
    });
  }

  shareUserData(response) {
    console.log('shareUserData', response);
    this.emit('onUserGetData', response);
  }

  UpdateUserData({ UserID, FirstName, LastName, Email, Telephone, Address, Password, IsAdmin, IsOnDuty }) {
    console.log('UpdateUserData ', UserID, FirstName, LastName, Email, Telephone, Address, Password, IsAdmin, IsOnDuty);

    this.UserApi.UpdateCareUserData({ UserID, FirstName, LastName, Email, Telephone, Address, Password, IsAdmin, IsOnDuty }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onUserUpdateData', rsp);
    });
  }

  UpdateUserBroadcastMessage({ UserID, CanSendMessageToAllPatients }) {
    console.log('UpdateUserBroadcastMessage ', UserID, CanSendMessageToAllPatients);

    this.UserApi.UpdateUserBroadcastMessage({ UserID, CanSendMessageToAllPatients }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['canSend'] = CanSendMessageToAllPatients;

      this.emit('onUpdateUserBroadcastMessage', rsp);
    });
  }

  UpdateReceiveEscalation({ UserID, ReceiveEscalationCallRequest }) {
    console.log('UpdateReceiveEscalation ', UserID, ReceiveEscalationCallRequest);

    this.UserApi.UpdateReceiveEscalation({ UserID, ReceiveEscalationCallRequest }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['canReceive'] = ReceiveEscalationCallRequest;

      this.emit('onUpdateReceiveEscalation', rsp);
    });
  }

  setStaffAccess({ UserID, CanSeeStaffTab }) {
    console.log('SetStaffAccess ', UserID, CanSeeStaffTab);

    this.UserApi.SetStaffAccess({ UserID, CanSeeStaffTab }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = CanSeeStaffTab;

      this.emit('onSetStaffAccess', rsp);
    });
  }

  setClientsAccess({ UserID, CanSeeClientsTab }) {
    console.log('SetClientsAccess ', UserID, CanSeeClientsTab);

    this.UserApi.SetClientsAccess({ UserID, CanSeeClientsTab }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = CanSeeClientsTab;

      this.emit('onSetClientsAccess', rsp);
    });
  }

  setQueueAccess({ UserID, CanSeeQueueTab }) {
    console.log('SetQueueAccess ', UserID, CanSeeQueueTab);

    this.UserApi.SetQueueAccess({ UserID, CanSeeQueueTab }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = CanSeeQueueTab;

      this.emit('onSetQueueAccess', rsp);
    });
  }

  setMessagesAccess({ UserID, CanSeeMessageCenterTab }) {
    console.log('SetMessagesAccess ', UserID, CanSeeMessageCenterTab);

    this.UserApi.SetMessagesAccess({ UserID, CanSeeMessageCenterTab }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = CanSeeMessageCenterTab;

      this.emit('onSetMessagesAccess', rsp);
    });
  }

  setAlertsAccess({ UserID, CanSeeAlertsTab }) {
    console.log('setAlertsAccess ', UserID, CanSeeAlertsTab);

    this.UserApi.SetAlertsAccess({ UserID, CanSeeAlertsTab }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = CanSeeAlertsTab;

      this.emit('onSetAlertsAccess', rsp);
    });
  }

  setMemoryBoxAccess({ UserID, CanSeeMemoryBox }) {
    console.log('setMemoryBoxAccess ', UserID, CanSeeMemoryBox);

    this.UserApi.SetMemoryBoxAccess({ UserID, CanSeeMemoryBox }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = CanSeeMemoryBox;

      this.emit('onSetMemoryBoxAccess', rsp);
    });
  }

  setBulletinBoardAccess({ UserID, CanSeeBulletinBoard }) {
    console.log('setBulletinBoardAccess ', UserID, CanSeeBulletinBoard);

    this.UserApi.SetBulletinBoardAccess({ UserID, CanSeeBulletinBoard }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = CanSeeBulletinBoard;

      this.emit('onSetBulletinBoardAccess', rsp);
    });
  }

  setDeletePatientsEnabled({ UserID, CanDeletePatients }) {
    console.log('setDeletePatientsEnabled ', UserID, CanDeletePatients);

    this.UserApi.SetDeletePatientsEnabled({ UserID, CanDeletePatients }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enabled'] = CanDeletePatients;

      this.emit('onDeletePatientsEnabled', rsp);
    });
  }

  setDeleteDevicesEnabled({ UserID, CanDeleteDevices }) {
    console.log('setDeleteDevicesEnabled ', UserID, CanDeleteDevices);

    this.UserApi.SetDeleteDevicesEnabled({ UserID, CanDeleteDevices }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enabled'] = CanDeleteDevices;

      this.emit('onDeleteDevicesEnabled', rsp);
    });
  }

  setBargeInCallsEnabled({ UserID, Enabled }) {
    console.log('setBargeInCallsEnabled ', UserID, Enabled);

    this.UserApi.SetBargeInCallsEnabled({ UserID, Enabled }, rsp => {
      //console.log("Nucleus Response", rsp);
      rsp['enabled'] = Enabled;
      this.emit('onBargeInCallsEnabled', rsp);
    });
  }

  setDevicesAccess({ UserID, CanSeeDevicesTab }) {
    console.log('setDevicesAccess ', UserID, CanSeeDevicesTab);

    this.UserApi.SetDevicesAccess({ UserID, CanSeeDevicesTab }, rsp => {
      //console.log("Nucleus Response", rsp);
      rsp['enabled'] = CanSeeDevicesTab;
      this.emit('onSetDevicesAccessUpdated', rsp);
    });
  }

  setRestartDevicesAccess({ UserID, CanRestartDevices }) {
    console.log('setRestartDevicesAccess ', UserID, CanRestartDevices);

    this.UserApi.SetRestartDevicesAccess({ UserID, CanRestartDevices }, rsp => {
      //console.log("Nucleus Response", rsp);
      rsp['enabled'] = CanRestartDevices;
      this.emit('onRestartDevicesAccessUpdated', rsp);
    });
  }

  setUserOnDuty({ UserID, IsOnDuty }) {
    console.log('setUserOnDuty ', UserID, IsOnDuty);

    this.UserApi.setUserOnDuty({ UserID, IsOnDuty }, rsp => {
      //console.log("setUserOnDuty Response", rsp);

      //*-
      this.emit('onSetUserOnDuty', {
        ok: rsp.ok,
        onDuty: rsp.IsOnDuty,
        userId: UserID,
      });
    });
  }

  setUserOnlyUrgent({ UserID, OnlyUrgent }) {
    console.log('setUserOnlyUrgent', UserID, OnlyUrgent);

    this.UserApi.SetUserOnlyUrgent({ UserID, OnlyUrgent }, rsp => {
      //console.log("setUserOnlyUrgent", rsp);

      this.emit('onSetUserOnlyUrgent', {
        ok: rsp.ok,
        onlyUrgent: rsp.OnlyUrgent,
        userId: UserID,
      });
    });
  }

  setUserIsAdmin({ UserID, IsAdmin }) {
    console.log('setUserIsAdmin ', UserID, IsAdmin);

    this.UserApi.setUserIsAdmin({ UserID, IsAdmin }, rsp => {
      //console.log("setUserIsAdmin Response", rsp);

      this.emit('onSetUserIsAdminAction', {
        ok: rsp.ok,
        isAdmin: rsp.IsAdmin,
        userId: UserID,
      });
    });
  }

  getAllUsersData({ UserID }) {
    console.log('getAllUsersData ', UserID);

    this.UserApi.getAllUsersData({ UserID }, rsp => {
      console.log('getAllUsersData Response', rsp);

      if (rsp.ok) {
        this.UsersList = rsp.users;
        this.saveToLocalStorage();
      }
      //*-
      this.emit('onGetAllUsersData', rsp.ok);
    });
  }

  
  CreateNewUser({
    AccountID,
    FirstName,
    LastName,
    Email,
    Telephone,
    Address,
    CanSendMessageToAllPatients,
    IsAdmin,
    ReceiveEscalationCallRequest,
    CanSeeStaffTab,
    CanSeeClientsTab,
    CanAccessGroups,
    CanSeeQueueTab,
    CanSeeMessageCenterTab,
    CanSeeAlertsTab,
    CanSeeMemoryBox,
    CanSeeBulletinBoard,
    CanSeeDevicesTab,
    CanAccessReports,
    CanDeletePatients,
    CanDeleteDevices,
    BargeInCall,
    CanRestartDevices,
    CanImportClients
  }) {
    console.log(
      'CreateNewUser ',
      AccountID,
      FirstName,
      LastName,
      Email,
      Telephone,
      Address,
      CanSendMessageToAllPatients,
      IsAdmin,
      ReceiveEscalationCallRequest,
      CanSeeStaffTab,
      CanSeeClientsTab,
      CanAccessGroups,
      CanSeeQueueTab,
      CanSeeMessageCenterTab,
      CanSeeAlertsTab,
      CanSeeMemoryBox,
      CanSeeBulletinBoard,
      CanSeeDevicesTab,
      CanAccessReports,
      CanDeletePatients,
      CanDeleteDevices,
      BargeInCall,
      CanRestartDevices,
      CanImportClients
    );
  
    this.UserApi.CreateNewUser(
      {
        AccountID,
        FirstName,
        LastName,
        Email,
        Telephone,
        Address,
        CanSendMessageToAllPatients,
        IsAdmin,
        ReceiveEscalationCallRequest,
        CanSeeStaffTab,
        CanSeeClientsTab,
        CanAccessGroups,
        CanSeeQueueTab,
        CanSeeMessageCenterTab,
        CanSeeAlertsTab,
        CanSeeMemoryBox,
        CanSeeBulletinBoard,
        CanSeeDevicesTab,
        CanAccessReports,
        CanDeletePatients,
        CanDeleteDevices,
        BargeInCall,
        CanRestartDevices,
        CanImportClients
      },
      rsp => {
        // console.log("Nucleus Response", rsp);
        this.emit('onCreateUserAction', rsp);
      },
    );
  }

  DeleteUser({ UserID }) {
    console.log('DeleteUser ', UserID);

    this.UserApi.DeleteUser({ UserID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onDeleteUserAction', rsp);
    });
  }

  getPossiblePatients({ UserID }) {
    console.log('getPossiblePatients ', UserID);

    this.UserApi.GetPossiblePatients({ UserID }, rsp => {
      //console.log("getPossiblePatients Response", rsp);

      this.emit('onGetPossiblePatients', rsp);
    });
  }

  assignPatient({ UserID, PatientID }) {
    console.log('assignPatient ', UserID, PatientID);

    this.UserApi.AssignPatient({ UserID, PatientID }, rsp => {
      //console.log("assignPatient Response", rsp);

      this.emit('onAssignPatient', rsp);
    });
  }
  assignPatientsGroup({ UserID, PatientID }) {
    console.log('assignPatientsGroup ', UserID, PatientID);

    this.UserApi.AssignPatient({ UserID, PatientID }, rsp => {
      //console.log("assignPatientsGroup Response", rsp);

      this.emit('onAssignPatientsGroup', rsp);
    });
  }

  handleUnassignPatientModal({ PatientID, Name }) {
    console.log('handleUnassignPatientModal ', PatientID, Name);

    this.emit('onUnassignPatientModal', {
      PatientID: PatientID,
      Name: Name,
    });
  }

  unassignPatient({ UserID, PatientID }) {
    console.log('unassignPatient ', UserID, PatientID);

    this.UserApi.UnassignPatient({ UserID, PatientID }, rsp => {
      //console.log("unassignPatient Response", rsp);

      this.emit('onUnassignPatient', rsp);
    });
  }
  unassignPatientsGroup({ UserID, PatientID }) {
    console.log('unassignPatientsGroup ', UserID, PatientID);

    this.UserApi.UnassignPatient({ UserID, PatientID }, rsp => {
      //console.log("unassignPatientsGroup Response", rsp);

      this.emit('onUnassignPatientsGroup', rsp);
    });
  }

  getAllPatientsForUser({ UserID }) {
    console.log('getAllPatientsForUser ', UserID);

    this.UserApi.getAllPatientDataForUser({ UserID }, rsp => {
      //console.log("getAllPatientsForUser Response", rsp);

      this.emit('onGetAllPatientsForUser', rsp);
    });
  }

  setSeeAllPatients({ UserID, CanSee }) {
    console.log('setSeeAllPatients ', UserID, CanSee);

    this.UserApi.SetSeeAllPatients({ UserID, CanSee }, rsp => {
      //console.log("setSeeAllPatients Response", rsp);

      //*-
      this.emit('onSetSeeAllPatients', {
        ok: rsp.ok,
        canSee: rsp.canSee,
        userId: UserID,
      });
    });
  }

  updateSeeAllPatients({ UserID, CanSee, callback }) {

    this.UserApi.SetSeeAllPatients({ UserID, CanSee }, rsp => {
      callback && callback({
        ok: rsp.ok,
        access: rsp.canSee,
        userId: UserID,
      });
    });
  }

  reSendEmail({ UserID }) {
    this.UserApi.RequestResendEmail({ UserID }, rsp => {
      this.emit('onResendEmail', rsp);
    });
  }

  saveToLocalStorage() {
    localStorage.UsersList = this.UsersList;
    localStorage.UserName = this.UserName;
    localStorage.UserAllPatientsAccess = this.UserAllPatientsAccess;
  }
}

const careUserStore = new CareUserStore();

export default careUserStore;

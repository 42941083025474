import { EventEmitter } from 'events';
//import Dispatcher from "../Dispatcher";
//import Config from "../Config";
// import querystring from 'querystring';
// import axios from 'axios';
import SalesforceApi from '../apis/SalesforceApi';

class SalesforceStore extends EventEmitter {
  private SalesforceApi: SalesforceApi;
  private PatientsList: any[];
  private IntegratedPatientsHash: any;
  private IntegratedPatientsArray: any;
  private IntegratedPatientsCounter: number;
  private AvailablePatientsHash: any;
  private AvailablePatientsArray: any;
  private accountID: string;
  private userID: string;
  private integratePatientsIndexesArray: any;
  private integratePatientsTotal: number;
  private integratePatientCounter: number;

  constructor(props) {
    super(props);

    this.SalesforceApi = new SalesforceApi(this);

    // try {
    //     this.PatientsList = JSON.parse(localStorage.PatientsList) || [];

    // } catch (e) {}

    // this.salesforceCredentials = {
    //     grant_type : null,
    //     client_id :null,
    //     client_secret :null,
    //     username:null,
    //     password:null,
    //     redirect_uri:null
    // }

    this.IntegratedPatientsHash = [];
    this.IntegratedPatientsArray = [];
    this.IntegratedPatientsCounter = 0;

    this.AvailablePatientsHash = [];
    this.AvailablePatientsArray = [];

    this.accountID = '';
    this.userID = '';
    this.integratePatientsIndexesArray = [];
    this.integratePatientsTotal = 0;
    this.integratePatientCounter = 0;
  }

  getSalesforceData({ AccountID }) {
    console.log('getSalesforceData ', AccountID);

    this.accountID = AccountID;

    //let data = JSON.parse(jsonData2);
    //console.log("JsonData2", data);

    this.SalesforceApi.getSalesforceData({ AccountID }, rsp => {
      console.log('getSalesforceData Response');
      console.log(rsp);

      if (rsp.ok) {
        //this.saveToLocalStorage();
        const patientsArray = [];
        for (let i = 0; i < rsp.IntegratedPatients.length; i++) {
          patientsArray[rsp.IntegratedPatients[i].SalesforceID] = rsp.IntegratedPatients[i];
        }
        this.IntegratedPatientsHash = patientsArray;
        console.log('We have the Hash');
        console.log(patientsArray);

        const salesforceData = JSON.parse(rsp.SalesforceData);
        console.log('Salesforce Patients');
        console.log(salesforceData);
        this.emit('onSyncSalesforceStarted', { ok: true });

        let dataRow = null;
        if (salesforceData.done && salesforceData.records && salesforceData.records.length > 0) {
          //console.log("this.IntegratedPatientsHash", this.IntegratedPatientsHash);
          const patientsIdsHash = [];
          const availablePatientsArray = [];
          const integratedPatientsArray = [];
          for (let i = 0; i < salesforceData.records.length; i++) {
            dataRow = salesforceData.records[i];
            //console.log("dataRow", dataRow);
            //console.log(this.IntegratedPatientsHash[dataRow.Id]);
            if (this.IntegratedPatientsHash[dataRow.Id] == undefined) {
              patientsIdsHash[dataRow.Id] = { checked: false };
              availablePatientsArray.push(dataRow);
            } else {
              integratedPatientsArray.push(dataRow);
            }
          }

          console.log('patientsIdsHash', patientsIdsHash);
          console.log('availablePatientsArray', availablePatientsArray);
          console.log('integratedPatientsArray', integratedPatientsArray);
          this.AvailablePatientsHash = patientsIdsHash;
          this.AvailablePatientsArray = availablePatientsArray;
          this.IntegratedPatientsArray = integratedPatientsArray;
          this.IntegratedPatientsCounter = 0;

          this.updateIntegratedData();
        } else {
          console.log('>3');
          this.emit('onSyncSalesforceEnded', {
            ok: false,
            info: 'There is no data on the Connected Salesforce Account',
          });
        }
      } else {
        console.log('>2');
        this.emit('onSyncSalesforceEnded', {
          ok: false,
          info: rsp.error,
        });
      }
    });
  }

  updateIntegratedData() {
    console.log('this.IntegratedPatientsCounter', this.IntegratedPatientsCounter);

    console.log('Length', this.IntegratedPatientsArray.length);
    if (this.IntegratedPatientsArray && this.IntegratedPatientsArray.length > 0) {
      const salesforcePatient = this.IntegratedPatientsArray[this.IntegratedPatientsCounter];
      //console.log("salesforcePatient", salesforcePatient);

      const integratedSalesforcePatient = this.IntegratedPatientsHash[salesforcePatient.Id];
      //console.log("integratedSalesforcePatient", integratedSalesforcePatient);

      try {
        const nucleusLastDate = new Date(Date.parse(integratedSalesforcePatient.LastSynched));
        const salesforceLastDate = new Date(Date.parse(salesforcePatient.LastModifiedDate));

        //console.log("nucleusLastDate", nucleusLastDate);
        //console.log("salesforceLastDate", salesforceLastDate);

        if (salesforceLastDate > nucleusLastDate) {
          const params = {
            UserID: this.userID,
            PatientID: integratedSalesforcePatient.PatientID,
            Name: salesforcePatient.Name,
            Address:
              salesforcePatient.BillingStreet +
              ' ' +
              salesforcePatient.BillingCity +
              ' ' +
              salesforcePatient.BillingPostalCode +
              ', ' +
              salesforcePatient.BillingState +
              ', ' +
              salesforcePatient.BillingCountry,
            Phone: salesforcePatient.Phone,
            LastModified: salesforcePatient.LastModifiedDate,
          };

          console.log('Params', params);
          this.emit('onSyncSalesforceProcess', {
            ok: true,
            info: 'Updating ' + salesforcePatient.Name + ' ...',
          });

          this.SalesforceApi.UpdateSalesforcePatient(params, rsp => {
            console.log('Nucleus Response', rsp);
            if (rsp.ok) {
              this.continueSalesforceSync();
            } else {
              this.emit('onSyncSalesforceProcess', {
                ok: false,
                info: 'Error updating ' + salesforcePatient.Name + ' ...',
              });
              this.continueSalesforceSync();
            }
          });
        } else {
          this.continueSalesforceSync();
        }
      } catch (error) {
        this.continueSalesforceSync();
      }
    } else {
      console.log('>1');
      this.continueSalesforceSync();
    }
  }

  continueSalesforceSync() {
    if (this.IntegratedPatientsCounter < this.IntegratedPatientsArray.length - 1) {
      this.IntegratedPatientsCounter++;
      this.updateIntegratedData();
    } else {
      this.emit('onSyncSalesforceEnded', {
        ok: true,
        patientsIdsHash: this.AvailablePatientsHash,
        availablePatientsArray: this.AvailablePatientsArray,
        requireRefresh: this.IntegratedPatientsCounter > 0,
      });
    }
  }

  integratePatientData() {
    console.log('integratePatientData');
    console.log('this.integratePatientsIndexesArray', this.integratePatientsIndexesArray);
    console.log('this.integratePatientsTotal', this.integratePatientsTotal);
    console.log('this.integratePatientCounter', this.integratePatientCounter);

    const patientToAdd = this.AvailablePatientsArray[this.integratePatientsIndexesArray[this.integratePatientCounter]];
    console.log('patientToAdd', patientToAdd);

    const params = {
      UserID: this.userID,
      AccountID: this.accountID,
      SalesforceID: patientToAdd.Id,
      Name: patientToAdd.Name,
      Address:
        patientToAdd.BillingStreet + ' ' + patientToAdd.BillingCity + ' ' + patientToAdd.BillingPostalCode + ', ' + patientToAdd.BillingState + ', ' + patientToAdd.BillingCountry,
      Phone: patientToAdd.Phone,
      LastModified: patientToAdd.LastModifiedDate,
    };

    console.log('Params', params);
    this.emit('onSalesforcePatientsIntegrating', {
      ok: true,
      info: 'Integrating (' + (this.integratePatientCounter + 1) + ' de ' + this.integratePatientsTotal + ') ' + patientToAdd.Name + ' ...',
    });

    this.SalesforceApi.IntegrateSalesforcePatient(params, rsp => {
      console.log('Nucleus Response', rsp);
      if (rsp.ok) {
        if (this.integratePatientCounter < this.integratePatientsTotal - 1) {
          this.integratePatientCounter++;
          this.integratePatientData();
        } else {
          this.emit('onSalesforcePatientsIntegrated', {
            ok: true,
          });
        }
      } else {
        this.emit('onSalesforcePatientsIntegrated', {
          ok: false,
        });
      }
    });
  }

  integrateSalesforcePatients({ UserID, AccountID, PatientIds }) {
    console.log('integrateSalesforcePatients ', UserID, AccountID, PatientIds);

    this.integratePatientsIndexesArray = PatientIds;
    this.integratePatientsTotal = PatientIds.length;
    this.integratePatientCounter = 0;
    this.emit('onSalesforcePatientsIntegrating', {
      ok: true,
      info: 'Integrating ' + this.integratePatientsTotal,
    });
    this.userID = UserID;
    this.integratePatientData();

    // this.SalesforceApi.IntegrateSalesforcePatients({ UserID, AccountID, PatientIds }, (rsp) => {
    //     //console.log("Nucleus Response", rsp);
    //     this.emit("onSalesforcePatientsIntegrated", rsp);
    // });
  }

  // DEPRECATED
  getSalesforceToken() {
    console.log('getSalesforceToken');

    // $.ajax({
    //     url : "https://resourceful-wolf-wyr9d4-dev-ed.my.salesforce.com/services/data/v51.0/query?q=SELECT+Id,+AccountNumber,+Name+FROM+Account+ORDER+BY+Name+ASC",
    //     type: "GET",
    //     //crossOrigin: true,

    //     //xhrFields: { withCredentials: true },
    //     beforeSend: function (request) {
    //         request.setRequestHeader("Authorization", 'Baerer ' + "00D5Y000001bL10!AQsAQDvIysbX2Nxzwlk_txYp_dqCgWPu6hXjxvCvjyQ1ARTNflR2EZA0FWvVXGjvmjm8eIb59jTBHrEQIxaI2YiUxofMAbZl");
    //       },
    //     success: (data)=>{
    //         console.log(":D ");
    //         console.log(data)
    //         //your code to process output value from data
    //     },
    //     error: (data) =>{
    //         console.log("there was an error!", data);
    //     }
    // });
    // return;

    // $.ajax({
    //     url : "https://datafactory.my.salesforce.com/services/data",
    //     type: "GET",
    //     //crossOrigin: true,
    //     headers: {
    //         //'Access-Control-Allow-Origin': 'https://care-dev.nucleuslife.io',
    //         //'Access-Control-Allow-Methods':'GET, PUT, POST, OPTIONS',
    //     },
    //     //xhrFields: { withCredentials: true },
    //     //dataType: 'json',
    //     success: (data)=>{
    //         console.log(":D ");
    //         console.log(data)
    //         //your code to process output value from data
    //     },
    //     error: (data) =>{
    //         console.log("there was an error!", data);
    //     }
    // });
    // return;

    // const params = {
    //     grant_type: this.salesforceCredentials.grant_type,
    //     client_id: this.salesforceCredentials.client_id,
    //     client_secret: this.salesforceCredentials.client_secret,
    //     username: this.salesforceCredentials.username,
    //     password: this.salesforceCredentials.password,
    //     redirect_uri: this.salesforceCredentials.redirect_uri,
    // };

    // REQUEST WITH XMLHttpRequest
    // Working with Chrome Extension
    /*
            
            let paramsString = "grant_type="+this.salesforceCredentials.grant_type;
            paramsString += "&client_id="+this.salesforceCredentials.client_id;
            paramsString += "&client_secret="+this.salesforceCredentials.client_secret;
            paramsString += "&username="+this.salesforceCredentials.username;
            paramsString += "&password="+this.salesforceCredentials.password;
            paramsString += "&redirect_uri="+this.salesforceCredentials.redirect_uri;
            
            console.log(paramsString);

            var xhr = new XMLHttpRequest();
            xhr.open('POST', 'https://login.salesforce.com/services/oauth2/token', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
            xhr.onload = function () {
                // do something to response
                console.log(this.responseText);
            };
            xhr.send(paramsString);
        */

    // const requestData = querystring.stringify(params);
    // const axiosConfig = {
    //   headers: {
    //     //"Content-Type": "application/x-www-form-urlencoded",
    //     //'Content-Type': 'application/json;charset=UTF-8',
    //     //"Accept": "application/json, text/plain, */*",
    //     'Access-Control-Allow-Origin': 'https://care-dev.nucleuslife.io/',
    //     //"Access-Control-Allow-Origin": "https://login.salesforce.com",
    //     'Access-Control-Allow-Methods': 'POST',
    //   },
    // };
    //axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
    //axios.defaults.headers.post['Access-Control-Allow-Origin'] ='*';
    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'https://care-dev.nucleuslife.io';

    // axios
    //   .post('https://login.salesforce.com/services/oauth2/token', requestData, axiosConfig)
    //   .then(res => {
    //     console.log('RESPONSE RECEIVED: ', res);
    //   })
    //   .catch(err => {
    //     console.log('AXIOS ERROR: ', err);
    //   });

    // axios.interceptors.request.use(
    //   config => {
    //     // Do something before request is sent
    //     console.log('axios.interceptors.request', config);
    //     return config;
    //   },
    //   error => {
    //     // Do something with request error
    //     console.log('axios.interceptors.request error ', error);
    //     return Promise.reject(error);
    //   },
    // );

    // axios.interceptors.response.use( (response) =>{
    //     // Any status code that lie within the range of 2xx cause this function to trigger
    //     // Do something with response data
    //     console.log("axios.interceptors.response", response);
    //     //return response;
    // }, (error)=> {
    //     // Any status codes that falls outside the range of 2xx cause this function to trigger
    //     // Do something with response error
    //     console.log("axios.interceptors.response error", error);
    //     //return Promise.reject(error);
    // });
    return;
  }

  saveToLocalStorage() {

  }

  /*
    $.ajax({
            url : "https://care-dev.nucleuslife.io/ws/Salesforce.asmx/GetPatientsSalesforceData",
            type: "POST",
            //crossOrigin: "*",
            headers: {
            //     'Access-Control-Allow-Origin': 'https://care-dev.nucleuslife.io',
            //     'Access-Control-Allow-Methods':'GET, PUT, POST, OPTIONS',
            //     'Access-Control-Allow-Host':'https://care-dev.nucleuslife.io',
                'Content-Type' : 'application/x-www-form-urlencoded'
            },
            //xhrFields: { withCredentials: true },
            dataType: 'json',
            data: {
                "AccountID" :"31df0a14-25ef-4654-b7ae-d188b1c4bd8b"
            },
            success: (data)=>{
                console.log(":D ");
                console.log(data)
                //your code to process output value from data
            },
            error: (data) =>{ 
                console.log("there was an error!", data);
            } 
        });
        return;
    */
}

const salesforceStore = new SalesforceStore(undefined);

export default salesforceStore;
